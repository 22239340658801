@use '@angular/material' as mat;
@import "variables";
@import "mat-palette";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@import "@angular/material/theming";
@include mat.core();
// Color themes
$eatec-themify: (
    blue-theme: (
        base-color: $blue-themify-base-color,
        primary-base: $theme-blue,
        accent-base: $theme-blue,
        warn-base: mat.$red-palette,
    ),
    yellow-theme: (
        base-color: $green-themify-base-color,
        primary-base: $theme-yellow,
        accent-base: $theme-yellow,
        warn-base: mat.$red-palette,
    ),
    green-theme: (
        base-color: $green-themify-base-color,
        primary-base: $theme-green,
        accent-base: mat.$blue-gray-palette,
        warn-base: mat.$red-palette,
    ),
);

@mixin themify($theme-name, $theme-base-color, $app-theme) {
    $primary: map-get($app-theme, primary);
    $accent: map-get($app-theme, accent);
    $warn: map-get($app-theme, warn);
    $background: map-get($app-theme, background);
    $foreground: map-get($app-theme, foreground);
    .#{$theme-name} {
        @include mat.all-component-themes($app-theme);
        .product-base-theme-bg-color {
            background-color: mat.get-color-from-palette($primary, A700-contrast);
        }
        .product-base-theme-border-color {
            background-color: mat.get-color-from-palette($primary);
        }
        .product-theme-element-color {
            color: mat.get-color-from-palette($primary);
        }
        .navigation-menu-themy {
            background-color: $theme-base-color;
        }
        .navigation-selected-color {
            i::before,
            .menu-displayname {
                color: $theme-base-color !important;
            }
        }
        .angular-theme-button-bg {
            background-color: $theme-base-color !important;
        }
        .mat-button-toggle-checked {
            background-color: mat.get-color-from-palette($primary);
        }
        // .mat-select{
        //     .mat-select-value{
        //         color: mat-color($primary);
        //     }
        // }
        .mat-button-toggle-group {
            &.mat-button-toggle-group-appearance-standard {
                border: 2px solid mat.get-color-from-palette($primary);
                .mat-button-toggle-label-content {
                    color: mat.get-color-from-palette($primary);
                }
            }
        }
        .table-cards {
            .card-details {
                .hightlight-card {
                    background-color: mat.get-color-from-palette($primary, 100);
                }
            }
        }
        .agilysys-button{
            border-color: mat.get-color-from-palette($primary);
        }
        .lib-agilysys-button{
            &:not([disabled]) {
                border-color: mat.get-color-from-palette($primary);
            }
        }
        .agilysys-button-primary {
            &:not([disabled]){
                background-color: mat.get-color-from-palette($primary);
            }
            &:hover:not([disabled]) {
                background-color: mat.get-color-from-palette($primary, 600);
            }
            &:active:not([disabled]) {
                background-color: mat.get-color-from-palette($primary, 700);
            }
            &:focus:not([disabled]) {
                background-color: mat.get-color-from-palette($primary);
                box-shadow: 0 0 0 3px mat.get-color-from-palette($primary, 600) 0.5;
            }
        }
        .agilysys-button-secondary {
            background-color: none;
            color: mat.get-color-from-palette($primary);
            &:disabled {
                color: #e5e6ea;
                border-color: #bcbec2;
            }
            &:hover:not([disabled]) {
                border-color: mat.get-color-from-palette($primary);
                color: mat.get-color-from-palette($primary);
            }
            &:active:not([disabled]) {
                border-color: mat.get-color-from-palette($primary, 700);
                color: mat.get-color-from-palette($primary);
            }
            &:focus:not([disabled]) {
                color: mat.get-color-from-palette($primary);
                box-shadow: 0 0 0 3px mat.get-color-from-palette($primary, 600) 0.5;
            }
        }

        .icon-more-eatec {
            &::before {
                color: mat.get-color-from-palette($primary);
            }
        }

        .initials {
            color: mat.get-color-from-palette($primary);
        }
        .circle{
            color: mat.get-color-from-palette($primary);
        }
        .redefine{
            color: mat.get-color-from-palette($primary) !important;
        }
        .master-linked-items {
            .master-linkitems-header {
                .autocomplete-search {
                    .search-addnew {
                        color: mat.get-color-from-palette($primary) !important;
                    }
                }
            }
        }

        .timepicker {

            .timepicker__header {
            background-color: mat.get-color-from-palette($primary) !important;
            }

            .clock-face__clock-hand {
            background-color: mat.get-color-from-palette($primary) !important;

            &::after {
                background-color: mat.get-color-from-palette($primary) !important;
            }

            &::before {
                border-color: mat.get-color-from-palette($primary) !important;
            }
            }

            .clock-face__number {
            > span.active {
                background-color: mat.get-color-from-palette($primary) !important;
            }
            }

            button:not(.timepicker-dial__item) {
            color: mat.get-color-from-palette($primary) !important;
            }
        }

        .select-all-checkbox{
            &.mat-checkbox-checked {
                .mat-checkbox-label{
                    color: mat.get-color-from-palette($primary) !important;
                }
            }
        }

        .title-dropdown{
            &.dropdown-withtext{
                .mat-select-value-text {
                    color: mat.get-color-from-palette($primary) !important;
                }
                .mat-select-arrow {
                    color: mat.get-color-from-palette($primary) !important;
                }
            }
        }
        .transaction-grid {
            .ag-cell-value {
                span {
                    i:not(.icon-High-Importance-Icon-eatec)::before {
                        color: mat.get-color-from-palette($primary) !important;
                    }
                    // .icon-High-Importance-Icon::before {
                    //     color: inherit !important;
                    // }
                }
            }
        }

       .mat-menu-item {
          &:hover:not([disabled]) {
            border-color: mat.get-color-from-palette($primary, 400);
            color: mat.get-color-from-palette($primary, 400);
        }
        &:hover:not([disabled]) i:before
        {
          color: mat.get-color-from-palette($primary, 400);
        }
      }
          .message-view-list {
              .resize-rows {
                  .resize-list {
                      .row-list {
                          .icon-class {
                              border: 1px solid mat.get-color-from-palette($primary);
    
                              &:before {
                                  color: mat.get-color-from-palette($primary);
                              }
                          }
    
                          .selected-icon {
                              background-color: mat.get-color-from-palette($primary) !important;
                          }
                      }
                  }
              }
          }
        .view-messages-section {
            
        }
        .product-popup-content {    
            .detail-expand-content {
                .hightlight-text {
                    color: $theme-base-color !important;
                    font-weight: bold;
                }
            }
        }
    }
}

@each $theme-key, $theme-value in $eatec-themify {
    $base-color: null;
    $app-theme: null;

    $primary: if(
        map-has-key(map-get($theme-value, primary-base), primary),
        map-get($theme-value, primary),
        mat.define-palette(map-get($theme-value, primary-base))
    );

    $accent: if(
        map-has-key(map-get($theme-value, accent-base), accent),
        map-get($theme-value, accent),
        mat.define-palette(map-get($theme-value, accent-base))
    );

    $warn: if(
        map-has-key(map-get($theme-value, warn-base), warn),
        map-get($theme-value, warn),
        mat.define-palette(
            if(map-has-key(map-get($theme-value, warn-base), warn-base), map-get($theme-value, warn-base), mat.$red-palette)
        )
    );
    $app-theme: mat.define-light-theme($primary, $accent, $warn);
    @if $theme-key == "blue-theme" {
        $base-color: map-get($theme-value, base-color);
    } @else if $theme-key == "green-theme" {
        $base-color: map-get($theme-value, base-color);
    }
    @include themify($theme-key, $base-color, $app-theme);
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$eatec-modernization-typography: mat.define-typography-config(
    // $font-family: $roboto-regular,
    $button: mat.define-typography-level(14px, 14px, none),
    $input: mat.define-typography-level(14px, 1.125, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
);
