@import '../base/color';

/***Eatec-product base variable****/
/**Important if change the base variable affect the global from the product***/
$body-bg-color: $color-semi-grey;



/**Theme colors***/
$blue-themify-base-color: $color-blue;
$green-themify-base-color: $color-green;
/* Header color variables changes when theme changes */
$product-header-icon-color: $color-white;
$product-header-border-color: $color-white-opacity-2;
$product-header-text-color: $color-white;
/* Navigation color changes */
$navigation-text-color: $color-black-opacity-7;
$navigation-hightlight-color: $color-blue;
$navigation-bg-color: $color-white;
$navigation-hover-color: $ghost;
$navigation-menu-hightlight-color: $ghost-grey-light;
$navigation-submenu-bg-color: $color-light-white;
/* Login */
$product-login-highlight-color: $color-blue;
$product-primary-button-color: $color-blue;
$product-error-message: $color-red;
$product-secondary-header-icon-color: $color-blue;
$product-inventory-bg-color: $color-white;
$product-master-filter-color: $solitude-light-grey;
$product-master-expansion-panel-color: $solitude-light-grey;
$product-master-expansion-panel-hover-color: $solitude;
$product-master-expansion-panel-header-color: $strom-grey;
$product-master-filter-icon-color: $color-blue;
$setup-bg-color: $alabaster;
$setup-border-color: $ghost;
$product-master-border-color: $ghost;
$product-grid-header-color: $A200-neon-blue;
$product-mat-table-row-select-color: $A200-neon-blue;
$product-master-icon-color: $royal-blue;
$setup-icon-color: $slate-grey;
$file-upload-hightlight-color: $color-blue;
$file-upload-height: 235px;
$product-system-prefernce: $slate-grey;
/***container heights***/
$product-header-height: 64px;
$product-header-logo-width: 150px;
$product-aside-width: 244px;
$product-aside-width-unexpand: 64px;
$product-aside-icon-width-unexpand: 64px;
$product-aside-icon-width: 61px;
$product-primary-menu-height: 40px;
$product-sub-menu-height: 25px;
$product-tretiary-navigation-height: 50px;
$product-login-element-width: 327px;
$product-login-container-height: 564px;
$product-login-container-width: 920px;
$product-login-action-width: 400px;
$product-login-form-height: 300px;
$product-login-form-width: 340px;
$product-logo-container-width: 324px;
$product-inventory-list-width: 482px;
$product-inventory-search-width: 360px;
$product-card-control-height: 60px;
$product-card-button-toggle-color: $color-blue;
$product-master-detail-header-height: 40px;
$product-master-grid-header-height: 46px;
$product-master-detail-header-Fillter-height: 60px;
$product-inventory-observation-width: 120px;
$product-inventory-headerlabel-width: 490px;
$product-master-filter-header-height: 50px;
$product-master-total-items-header-height: 40px;
$product-mat-tab-header-height: 35px;
$product-mat-tab-header-width: 120px;
$product-mat-tab-header-max-width: 185px;
$product-inventory-property-details-height: 120px;
$product-mat-form-field-font-size: 14px;
$product-padding-flex-config: 0.4em;
$product-active-icon-color: $light-green;
$product-not-active-icon-color: $light-red;
$product-login-azure-button-color: $black-rock;
$product-card-height: 110px;
$product-inventory-card-height: 90px;
$product-grouping-close-bg-color: $persian-white;
$product-grid-search-bg: $persian-white;
$product-navigation-bg: $solitude-light;
$product-gridcard-hightlight-color: $lightblue;
$product-mat-table-border-color: $solitude-dark;
$product-mat-table-icon-hover-color: $color-blue;
$product-approval-status-hold-color: $light-orange;
$product-approval-status-pending-color: $light-yellow;
$product-approval-status-approved-color: $light-greens;
$product-approval-status-deny-color:  $dark-orange;
$product-approval-status-denied-color: $dark-orange;
//*****Font-family-variable*************//
$roboto-regular: 'Roboto-Regular';
$roboto-bold: 'Roboto-Bold';
$roboto-medium: 'Roboto-Medium';
$roboto-light: 'Roboto-Light';



//*******Font - Size ********//

$font-none: "none";
$font-8: 8px;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;//12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-17: 17px;
$font-18: 18px;
$font-19: 19px;
$font-23: 23px;
$font-24: 1.5rem;//24px;
$font-26: 26px;
$font-20: 20px;
$font-23: 23px;
$font-42: 42px;
$font-60: 60px;
$font-28: 28px;
/***Modernization-Font-Weight*****/
$font-weight-regular: 'regular';
$font-weight-medium: 'medium';
$font-weight-bold: 'bold';
/****Modernization-line-height****/
$font-line-height-32: 32px;
$font-line-height-28: 28px;
$font-line-height-24: 24px;
$font-line-height-20: 20px;
$font-line-height-16: 16px;

/*****Modernization-line-spacing****/
$font-letter-spacing-0: 0;
$font-letter-spacing-4: 0.4px;
$font-letter-spacing-10: 0.10px;
$font-letter-spacing-18: 0.18px;
$font-letter-spacing-36: 0.36px;
$font-letter-spacing-28: 0.28px;
$font-letter-spacing-24: 0.24px;
$font-letter-spacing-16: 0.16px;
$font-letter-spacing-52: 0.52px;
$font-letter-spacing-56: 0.56px;
/****Modernization-text-transform***/
$font-text-transform: 'initial';
$font-text-transform-all-caps: 'uppercase';
/***Modernization-font-color-***/
$product-deafult-color-header-1: $strom-grey;
$product-deafult-color-header-2: $strom-grey;
$product-deafult-color-header-3: $strom-grey;
$product-deafult-color-header-4: $strom-grey;
$product-deafult-color-header-5: $black-russian;
$product-deafult-color-body-1: $strom-grey;
$product-deafult-color-body-2: $black-russian;
$product-deafult-color-caption: $strom-grey-strong;
$product-deafult-color-label: $strom-grey;
$product-deafult-color-overline: $strom-grey;


/**Font color standard Updated***/
$header-color: $black-pearl;
$subheading-color: $black-pearl;
$subheading-medium-color: $black-pearl;
$subheading-regular-color: $black-pearl;
$body-medium-color: $black-pearl;
$body-regular-color: $black-pearl;
$body-tiny-light-color: $black-pearl;
$body-tiny-medium-upper-color: $black-pearl;


/**Product Spinner bg color****/
$product-spinner-bg-color: $ghost;
$body-tiny-medium-color: $black-pearl;
$body-tiny-regular-color: $black-pearl;

$secondary-header-height: 60px;

/****Master Module Variables****/
$master-accordian-header-color: $neon-blue;
$master-accordian-border-color: $neon-blue;
$master-form-container-bg-color: $white;
$master-accordian-expand-panel-height: 50px;
$master-create-form-primary-height: 250px;
$master-picture-icon-size: 35px;
$master-picture-icon-size: 35px;
$master-picture-icon-bg-color: $color-black-opacity-10;
$master-popup-max-width: 132px;
$master-popup-height: 99.18px;

/***Commom variables***/
$product-mat-menu-height: 40px;
$product-mat-min-bar-color: $neon-blue;
$product-icon-container-height: 24px;
$product-icon-container-width: 24px;
$product-icon-sites-container-width: 12px;
$product-mat-label: $strong-grey-light;

/****Navigation aside menu**********/
$product-primary-menu-icon-size: 24px;
$product-primary-menu-icon-font-size: 20px;
$product-icon-search-container: 16px;


/***Angular Material Mat Form Field *****/
$product-mat-form-outline-color: $ghost;
$product-mat-form-bg-color: $persian-white;
$product-mat-form-font-color: $strom-grey-strong;
$product-mat-form-icon-search-color: $strom-grey-strong;
$product-mat-form-icon-search-active-color: $neon-blue;
$product-mat-form-search-border-color: $ghost;
$product-mat-form-search-border-active-color: $neon-blue;

/***Product-popup**********/
$product-popup-header-height: 52px;
$product-popup-footer-height: 52px;
$product-observation-bg-color: $light-semi-blue;
$product-observation-bg-second-color: $strom-grey-strong;
$product-observation-color: $royal-blue;
$product-pop-list-grouping-search-con-height: 52px;
$product-pop-up-picture-sub-layout: 350px;
$product-picture-con-bg-color: $ghost-grey;
$picture-circle-container: 59.51px;
$product-obeservation-bg-color: $ghost-grey-one;
$product-observation-circle-color: $white;
$product-upload-bg-color: $white;
$product-upload-circle-color: $ghost-grey;
$product-pop-up-color: $white;

/***Custom-color-varriables***/
$product-custom-strom-grey-strong: $strom-grey-strong;
$product-custom-royal-blue: $royal-blue;
$product-custom-solitude: $solitude;
$product-custom-solitude-dark: $solitude-dark;
/****links****/
$product-link-color: $neon-blue;
$product-link-hover-color:$royal-blue;
$product-link-active-color: $persian-blue;
$product-link-focus-color: $royal-blue;

/***** Events ****/
$product-event-header-height: 50px;

/***Transaction****/
$product-transaction-header-height: 50px;
$product-quickorder-header-height: 300px;
$product-transaction-tab-height: 50px;
$product-containers-tables-collapsed-width: 300px;
$product-details-card-header-height: 70px;
$product-details-card-height: 60px;
$product-form-header-height: 70px;
$product-transaction-form-background-color: $white;
$product-transaction-form-esttotal-color: $light-green;
$product-item-list-name-width: 175px;
$product-mat-option-height: 35px;
$product-mat-option-height-expand: 120px;
$product-copynew-detail-card-header-height: 44px;
$product-copy-new-bg-color: $light-semi-blue;
$product-copy-new-container-bg-color: $white;
$product-copy-new-container-width: 300px;
$product-copynew-list-header: 50px;
$product-transcation-mat-tab-bg-color: $white;
$product-api-credits-height: 235px;

/***Tiles Configuration****/
$product-tiles-height: 85px;
$product-tiles-width: 280px;
$product-tiles-background-color: $white;
$product-tiles-header-color: $black-russian;
$product-transaction-header-border-color: $ghost;
$product-tiles-icon-height: 55px;
$product-tiles-icon-width: 55px;

//*****Attached files*******//
$product-attache-file-header: 80px;
$product-attache-border-bottom-color: $ghost-grey;

/*******Expense Management***********/
$product-detail-container-height: 330px;

//**No Math ***//
$product-nomath-added: $color-green;
$product-nomath-subtract: $color-red;
$product-nomath-text: $color-blue;
$product-no-math-dot:  #2653e075;
$product-no-math-light: $persian-blue;

//****Icon Pixel*******//
$icon-pixel: 20px;
$icon-color: $persian-blue;
$product-expand-popup-header-height: 70px;

/***Form Agy Lib*****/

$agilysys-form-field-max-width: 380px;
$agilysys-form-field-min-width: 250px;


/****DashBoard*****/
$dashboard-widget-grid-4-min-width: 336px;
$dashboard-widget-grid-4-max-width: 536px;
$dashboard-widget-grid-height: 424px;
$dashboard-widget-backgroundcolor: $white;
$dashboard-widget-card-height: 132px;
$dashboard-widget-card-default-height: 132px;
$dashboard-widget-card-width: 242px;
$dashboard-widget-card-min-width: (242px, 2);
$dashboard-widget-card-default-width: 242px;
$dashboard-widget-secondary-card-width: 246px;
$dashboard-widget-secondary-card-heigt: 74px;
$dashboard-widget-primary-header-height: 53px;
$dashboard-widget-header-height: 48px;
$dashboard-widget-filter-height: 60px;
$dashboard-secondary-card-icon-width: 50px;

/***WorkFlow****/
$workflow-details-container-height: 80px;
$workflow-flow-chart-container-height: 60px;
$workflow-flow-chart-action-container-width: 373px;
$workflow-chart-height: 122px;
$workflow-flow-side-containers: 373px;

/***Expand Grid Constant Variable***/
$expand-grid-action-height: 65px;
$expand-grid-header-height: 50px;
$expand-grid-header-height-1: 60px;
$expand-diamond-color: $deep-sky-blue;
$expand-circle-color: $fire-brick;
$expand-more-icon-color: $neon-blue;
$expand-detail-card-view: 359px;
$expand-detail-padding-right: 5px;

/*****Link Bids**********/
$link-bids-table-width: 450px;
$link-bids-table-bg-color: $color-white;
$link-bids-table-action-height: 60px;
$link-bids-table-horizantal-action-height: 150px;

/***WebTemplae***************/
$agilysys-web-template-height: 150px;
$agilysys-web-template-footer-height: 75px;
$negative-value-color: $light-brown;
$total-profit-positive-color: $green;
$profit-group-high: $light-green-color;
$profit-group-low: $light-pink;

/***MessagesTemplate*************/
$view-messages-header-height: 200px;