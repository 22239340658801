@import './ag-color';
@import 'src/assets/sass/abstracts/color';
/****************************************ont variables**********************************************************/
// $ag_lato-regular: "LatoRegular";
// $ag_lato-medium: "LatoWebMedium";
// $ag_lato-bold: "LatoWebBold";

$ag_lato-regular: "SourceSansProRegular";
$ag_lato-medium: "SourceSansProSemiBold";
$ag_lato-bold: "SourceSansProBold";
// New Fonts 2.0
$ag_sourcesans-regular: "SourceSansProRegular";
$ag_sourcesans-semibold: "SourceSansProSemiBold";
$ag_sourcesans-bold: "SourceSansProBold";

$ag_poppins-regular: "PoppinsRegular";
$ag_poppins-medium: "PoppinsMedium";
$ag_poppins-semibold: "PoppinsSemiBold";
$ag_poppins-bold: "PoppinsBold";

$lato-web-web: "SourceSansProRegular";
$lato-web-medium: $ag_lato-medium;
$lato-web-bold: $ag_lato-bold;
/*********************************Media query breakpoint values************************************************/
$medium-min-width: 480px;
$medium-max-width: 768px;
$small-max-width: 480px;
$large-min-width: 1024px;
$large-max-width: 1350px;

/*********************************Reset Declarations***********************************************************/
$ag_base-height: 100%;
$ag_base-font-size: 14px;

/****************************************Font-Size*************************************************************/
$font-size2: 2px;
$font-size4: 4px;
$font-size6: 6px;
$font-size8: 8px;
$font-size10: 10px;
$font-size11: 11px;
$font-size12: 12px;
$font-size14: 14px;
$font-size16: 16px;
$font-size18: 18px;
$font-size20: 20px;
$font-size22: 22px;
$font-size24: 24px;
$font-size26: 26px;
$font-size28: 28px;
$font-size28: 30px;
$font-size40: 40px;

/************** common variables **********************/
$default-container-width: 460px;
$default-container-padding: 1.25rem;
$mat-toggle-button-height: 40px;
$radio-size: 16px;
$default-side-panel-width-sm: 200px;
$default-side-panel-width-md: 250px;
$default-side-panel-width-lg: 280px;
$default-control-padding: 3rem;
$default-icon-size: 16px;
$tertiary-header-height: 65px;
$header-height: 75px;
$dialog-header-height: 4rem;
$dialog-footer-height: 4rem;
$toggle-height: 50px;
$phone-wrapper-width: 460px;

$theme-popup-header-bg: $white;
$theme-popup-header-fg: $black;
$theme-popup-header-close-bg: $white;

/*** Agilysys base color variables ***/


$ag_base-text-black: $black;
$ag_base-text-white: $white;

$ag_border-color: $gray-light;
$ag_button-bg: $gray-lighter;

//alert-popup colors
$ag_warning-color: $orange;
$ag_error-color: $red;
$ag_success-color: $green;

//alert-popup colors
$ag_warning-bg: $orange-lightest;
$ag_error-bg: $red-lightest;
$ag_success-bg: $green-lightest;

// Primary header Level1 Header
$ag_primary-header-bc: $black;
$ag_primary-header-fc: $gray-light;

// Secondary header Level2 Header
$ag_secondary-header-bc: $white;
$ag_secondary-header-fc: $gray-darker;

$ag_black-lighter: $black-lighter;

/******** POP Over Variables ************/
$popover-bg: $white !default;
$popover-inner-padding: 10px !default;
$zindex-popover: 1060 !default;
$popover-arrow-width: 20px !default;
$popover-arrow-height: 10px !default;
$popover-arrow-outer-width: ($popover-arrow-width + 1px) !default;
$popover-arrow-outer-color: $gray !default;
$popover-arrow-color: $popover-bg !default;
$popover-header-bg: $popover-bg !default;
$popover-border-width: 1px !default;
$popover-border-radius: 6px !default;
$popover-box-shadow: 0 0.25rem 0.5rem $black-lightest !default;
$popover-body-color: $white !default;
$popover-header-color: $white !default;
$border_bottom_color_1: $gray-dark;
$border_bottom_color_2: $black-lightest;
$border_bottom_color_3: $white;
$arrow-border-width: calc($popover-arrow-width / 2);
/****************************************/
$secondary-menu-header-height: 49px;