/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import './assets/icons/style.css';

@import './assets/sass/base/_reset';
@import './assets/sass/base/_typography';
@import './assets/sass/layout/_header';
@import './assets/sass/helpers/_mixins';
@import './assets/sass/components/_buttons';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@angular/material/theming';
@import './assets/sass/helpers/_variables.scss';
@import './assets/sass/abstracts/_variables.scss';
@import './assets/sass/abstracts/newmaterialstyle.scss';
@import './assets/sass/abstracts/versionTwo';


@import "./app/common/styles/ag-styles.scss";
$custom-typography: mat.define-typography-config($font-family: 'SourceSansProRegular');

html {
  overflow-x: hidden;
}


$md-goldpalte: (
    50 : #f8f1e2,
    100 : #eeddb6,
    200 : #e2c685,
    300 : #d6af54,
    400 : #ce9e30,
    500 : #c58d0b,
    600 : #bf850a,
    700 : #b87a08,
    800 : #b07006,
    900 : #a35d03,
    A100 : #ffe7ce,
    A200 : #ffce9b,
    A400 : #ffb468,
    A700 : #ffa84e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// Override the typography in the core CSS.
@include mat.core($custom-typography);
$candy-app-primary: mat.define-palette($md-goldpalte);
$candy-app-accent:  mat.define-palette($md-goldpalte, A200, A100, A400);
$candy-app-theme:   mat.define-light-theme($candy-app-primary, $candy-app-accent);

// Include the default theme styles.
@include mat.all-component-themes($candy-app-theme);


.ag_form-control {
  padding-right: $default-control-padding;
}

.ag_display--flex {
  display: flex !important;
}

.ag_align-items--center {
  align-items: center;
}

.ag_text--center {
  text-align: center;
}

.ag_cursor--not-allowed {
  cursor: not-allowed;
}

.ag_pointer--none {
  pointer-events: none;
}

.ag_ml--auto {
  margin-left: auto !important;
}

.ag_height--100,
.h-100 {
  height: 100% !important;
}

.ag_display--block {
  display: block !important;
}

.table-container {
  flex-grow: 1;
  padding: 0.75rem 1.25rem 1.25rem 1.25rem;
  user-select: none;
}

.ag_form--label {
  font-size: $font-size12;
  @extend .ag_display--block;
  margin-bottom: 10px;
  white-space: nowrap;
  color: $black;
}

.ag_w--20 {
  width: 20%;
}

.ag_w--25 {
  width: 25%;
  padding-left: 2%;
}

// Disables complete section
.ag_section--disable {
  @extend .ag_cursor--not-allowed;
  @extend .ag_pointer--none;
  opacity: 0.5;
}

.blur-background{
  .container-spa, .container-login{
  filter:blur(5px);
  }
}
.toast-container .toast {
  padding: 15px;
  margin: 0;
  box-shadow: none;
  width: 600px;
  cursor: default !important;
}

.toast-container .toast:hover {
  box-shadow: none;
}

.toast {
  background-image: none;
}

.toast-close-button:focus,
.toast-close-button:hover {
  color: inherit;
  opacity: 1;
}

.toast-close-button {
  right: 0;
  top: 0;
  margin-left: 20px;
  font-family: $ag_lato-regular;
  font-weight: unset;
  text-shadow: none;
}

.swiper-button-next,.swiper-button-prev{
  background-image: none !important;
  color: $color-tabText-gray !important;
  height:25px !important;
  width:24px !important;
  top:5px !important;
  margin-top: 0px !important;
}

.swiper-button-next:after,.swiper-button-prev:after{
  display: none;
}

.toast-error {
  background-color: #fc634a;
}

.toast-warning {
  background-color: #faac40;
}

.toast-info {
  background-color: #3c85b4;
}

.toast-success {
  background-color: #51bf7e;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.3;
  background: #ffffff;
  cursor: progress;
}

.loading span {
  position: fixed;
  left: 50%;
  top: 40%;
  display: block;
  padding: 70px 80px;
  margin-left: -40px;
  z-index: 99;
  background: url(https://media.giphy.com/media/y1ZBcOGOOtlpC/giphy.gif);
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.mat-horizontal-stepper,
.mat-horizontal-content-container {
  height: calc(100% - 72px);
}

.horizontal-stepper-class {

  .mat-horizontal-stepper,
  .mat-horizontal-content-container {
    height: calc(100% - 50px);
  }
}

.mat-horizontal-stepper-content,
.mat-dialog-content {
  height: 100%;
}

.scrollbar {
  overflow-y: auto;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: $BodyBgColour;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-address;
    border-radius: 4px;
    height: 40px;
  }
}

.action-btn {
  height: 40px;
}

.datepicker-form-field {
  width: calc(100% - 33px);
}

.timepicker-form-field {
  width: calc(100% - 33px);
}

.icon-calendar,
.icon-clock,
.icon-clock {
  color: $gold !important;
}

.mat-raised-button {
  line-height: 15px !important;
  box-shadow: none !important;
  text-transform: capitalize;
  font-family: $ag_lato-regular;
  border-radius: 5px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
  white-space: nowrap !important;


}

.text-capital {
  text-transform: uppercase !important;
}

.mat-checkbox-frame {
  border-radius: 1px !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1 !important;
}

.mat-slide-toggle-content {
  font-family: $ag_lato-regular;
  font-size: 14px;
}

.mat-horizontal-stepper-header .mat-step-icon,
.mat-horizontal-stepper-header .mat-step-icon-not-touched {
  height: 40px;
  width: 40px;
}

.horizontal-stepper-class {
  .mat-horizontal-stepper-header-container {
    background-color: $color-steeper-bg;
    height: 50px !important;

    .mat-horizontal-stepper-header {
      height: 100% !important;

      .mat-step-icon,
      .mat-step-icon-not-touched {
        height: 28px;
        width: 28px;
        background-color: $color-tabText-gray;
        color: $PureWhite;

        .icon-done {
          font-size: 10px !important;
        }

        span {
          font-size: 14px;
          font-family: $ag_sourcesans-regular;
        }
      }

      .mat-step-text-label {
        color: $color-tabText-gray;
        font-size: 14px;
        font-family: $ag_sourcesans-regular;
      }

    }

    .mat-stepper-horizontal-line {
      border-top-width: 3px;
      border-top-color: $color-tabText-gray;
    }
  }
}
.mat-tab-label {
  font-size: 14px !important;
  font-family: $ag_lato-bold;
  color: $color-header !important;
  opacity:1 !important;
}
.mat-tab-label-content {
  text-transform: uppercase !important;
  font-family: $ag_sourcesans-bold !important;
  font-size: 14px !important;
}

.mat-menu-item {
  height: 45px !important;
  line-height: 30px !important;
}

.mat-menu-content {
  padding: 0 !important;
}

label {
  font-family: $ag_lato-regular;
  margin-bottom: .7rem;
  .blackcolor {
    color: $color-base;
  }
}

label.mat-button-toggle-label {
  margin: 0px;
}

.mat-tab-labels {
  background: #f2f2f2;
}

.mat-expansion-indicator {
  transition-duration: 0.5s;
}

textarea {
  color: $color-input-holder;
  max-height: 200px;
}

.whitecolor {
  color: #fff !important;
}

.cursor {
  cursor: pointer !important;
}

.cursordefault{
  cursor: default !important;
}

.dragcursor {
  cursor: move !important;
}

.actionButtons {
  border-radius: 4px !important;
  height: 4rem;
}

[class^="icon-"], [class*=" icon-"] {
  color: $color-blac-opac8;
}
.blckClr {
  color: $color-base;
}

.unselected-icon {
  color: #DFD9D9;
}

.select_button_width {
  width: 150px;
}

.mat-menu-panel {
  max-height: 190px !important;
  min-height: 45px !important;
}




i {
  color: #888888;
}

.mat-dialog-container {
  padding: 0px !important;
  border-radius: 3px !important;
}

.mat-tab-body-wrapper {
  height: 100%;
}

.mat-dialog-actions {
  button {
    height: 40px;
  }
}


.button_invalid {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}

.button_invalidbgcolor{
  cursor: default;
  background-color: $color-disabled !important;
  border-color: $color-disabled !important;
}

.pointerevents-none{
  pointer-events: none !important;
}

.button_valid {
  opacity: 1;
  cursor: pointer !important;
}

mat-calendar {
  .mat-calendar-header {
    background: #C58D0B;
    color: white;

    .mat-calendar-arrow {
      border-top-color: white;
    }
  }

  button:hover {
    background: unset !important;
  }
}

.textellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


.tooltipClass{
    color: #fff !important;
    font-size:14px !important;
    line-height: 1.5 !important;
    padding:10px;
    width:auto;
    word-break: break-all;
  }

.mataccordin_noshadow {
  box-shadow: 0 0 0 transparent !important;
  border-bottom: 1px solid lightgray;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.6);
}


.spa-searchbox {
  .mat-form-field-label.mat-input-placeholder {
    color: $label-color !important;
    padding-left: 10px !important;
    width: 90%;
  }
  input {
    color: #000;
    padding-left: 10px !important;
    width: 82%;
    font-size: 16px;
    &.mat-chip-input {
      margin: 0;
    }
  }
  .mat-form-field-label {
    color: $label-color !important;
    padding-left: 10px !important;
    width: 90%;
  }

  .icon-Search,
  .icon-Cancel {
    @extend .IC12;
    right: 6px;
    position: absolute;
    bottom: 8px;
  }

  .icon-Cancel {
    font-size: 10px;
  }

  &.mat-form-field {
    font-size: 14px !important;
  }

  .mat-form-field-infix {
    border-radius: 3px;
    box-shadow: none;
    background-color: #FFFFFF;
    border: 1px solid #D6D9D6;
  }



  .mat-form-field-underline {
    display: none;
  }
}

.hide-underline{
  .mat-form-field-underline {
    display: none;
  }
}

.spa-input {
  input {
    color: #000;
    padding-left: 10px;
    width: 90%;

    &.mat-chip-input {
      margin: 0;
    }
  }

  .icon-Search,
  .icon-Cancel {
    font-size: 14px;
    color: #000;
    right: 6px;
    position: absolute;
    bottom: 6px;
  }

  &.mat-form-field {
    font-size: 14px !important;
  }

  .mat-form-field-infix {
    border-radius: 6px;
    box-shadow: none;
    background-color: #FFFFFF;
    border: 1px solid #D6D9D6;
  }



  .mat-form-field-underline {
    display: none;
  }
}

.LW30 {
  font-family: $ag_lato-regular;
  font-size: 30px !important;
}

.LW22 {
  font-family: $ag_lato-regular;
  font-size: 22px !important;
}

.LW20 {
  font-family: $ag_lato-regular;
  font-size: 20px !important;
}

.LW18 {
  font-family: $ag_lato-regular;
  font-size: 18px !important;
}

.LW16 {
  font-family: $ag_sourcesans-semibold;
  font-size: 16px !important;
}

.LW14 {
  font-family: $ag_lato-regular;
  font-size: 14px !important;
}

.LW12 {
  font-family: $ag_lato-regular;
  font-size: 12px !important;
}

.LW11 {
  font-family: $ag_lato-regular;
  font-size: 11px !important;
}

.LWB11 {
  font-family: $ag_lato-bold;
  font-size: 11px !important;
}

.LWB12 {
  font-family: $ag_lato-bold;
  font-size: 12px !important;
}

.LWB14 {
  font-family: $ag_lato-bold;
  font-size: 14px !important;
}

.LWB16 {
  font-family: $ag_lato-bold;
  font-size: 16px !important;
}

.LWB18 {
  font-family: $ag_lato-bold;
  font-size: 18px !important;
}

.LWB20 {
  font-family: $ag_lato-bold;
  font-size: 20px !important;
}

.LW6 {
  font-family: $ag_lato-regular;
  font-size: 6px !important;
}
//status message colors
.icon-success-icon{
    color: #72d60e;
}
.icon-error-icon{
    color: #fd3e39;
}
.icon-info-icon{
    color: $ag_base-color;
}
.icon-warning-icon{
    color: #e2701d;
}
// Legend Colors
.checkedinclr {
  background: #2ecc71 !important;
}

.checkedoutclr {
  background: #f27935 !important;
}

.cancelledclr {
  background: #6c7a89 !important;
}

.noshowclr {
  background: #cf000f !important;
}

.scheduledclr {
  background: #4ECDC4 !important;
}

.checkinBDRclr {
  border: 2px solid #2ecc71 !important;
}

.checkoutBDRclr {
  border: 2px solid #f27935 !important;
}

.closeBDRclr {
  border: 2px solid #6c7a89 !important;
}

.nsBDRclr {
  border: 2px solid #cf000f !important;
}

.sBDRclr {
  border: 2px solid #4ecdc4 !important;
}

.checkedinTextclr {
  color: #2ecc71;
}

.checkedoutTextclr {
  color: #f27935;
}

.closedTextclr {
  color: #6c7a89;
}

.noshowTextclr {
  color: #cf000f;

}

.scheduledTextclr {
  color: #b4faf5;
}

.time-picker {

  .time-picker-header {
    background: #C58D0B !important;

    .time-picker-selected-time,
    .time-picker-hour,
    .time-picker-minute,
    .time-picker-pm,
    .time-picker-am {
      color: #E8DABC !important;
    }

    .time-picker-hour.selected,
    .time-picker-minute.selected,
    .time-picker-pm.selected,
    .time-picker-am.selected {
      color: white !important;
    }

  }

  .time-picker-footer {
    button {
      background: white !important;
      color: #C58D0B;
    }
  }
}



.checkinBGclrLight {
  background: #f4fcf7 !important;
}

.checkoutBGclrLight {
  background: #fef8f4 !important;
}

.closeBGclrLight {
  background: #f7f8f8 !important;
}

.nsBGclrLight {
  background: #fcf2f2 !important;
}

.sBGclrLight {
  background: #f5fcfb !important;
}

.checkinTXTclrLight {
  color: #f4fcf7 !important;
}

.checkoutTXTclrLight {
  color: #fef8f4 !important;
}

.closeTXTclrLight {
  color: #f7f8f8 !important;
}

.nsTXTclrLightLight {
  color: #fcf2f2 !important;
}

.sTXTclrLight {
  color: #f5fcfb !important;
}


.icon-calendar,
.icon-clock {
  font-size: 18px;
   color: $color-blac-opac8 !important;
    padding-bottom: 9px;
    vertical-align: initial;
    cursor:pointer;
}
.icon-clock {
  font-size: 17px;
}



/* POP OVER*/
$background_color_2: #f7f7f7;
$border_color_1: transparent;
$border_top_color_1: #999999;
$border_top_color_2: rgba(0, 0, 0, 0.25);
$border_top_color_3: #fff;
$border_right_color_1: #999999;
$border_right_color_2: rgba(0, 0, 0, 0.25);
$border_right_color_3: #fff;
$border_bottom_color_1: #999999;
$border_bottom_color_2: rgba(0, 0, 0, 0.25);
$border_bottom_color_3: #fff;
$border_left_color_1: #999999;
$border_left_color_2: rgba(0, 0, 0, 0.25);
$border_left_color_3: #fff;

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 470px;
  padding: 8px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 14px;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  >.arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: $border_color_1;
    border-style: solid;
    border-width: 11px;

    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: $border_color_1;
      border-style: solid;
      border-width: 10px;
      content: "";
    }
    &:before {
      border-bottom-color: transparent;
    }
  }
}

.bs-popover-top {
  margin-top: -10px;

  >.arrow {
    left: 50%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: $border_top_color_1;
    border-top-color: $border_top_color_2;
    bottom: -11px;

    &:after {
      content: " ";
      bottom: 1px;
      margin-left: -10px;
      border-bottom-width: 0;
      border-top-color: $border_top_color_3;
    }
    &:before {
      border-top-color: transparent;
    }
  }
}

.bs-popover-right {
  margin-left: 10px;

  >.arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: $border_right_color_1;
    border-right-color: $border_right_color_2;

    &:after {
      content: " ";
      left: 1px;
      bottom: -10px;
      border-left-width: 0;
      border-right-color: $border_right_color_3;
    }
    &:before {
      border-right-color: transparent;
    }
  }
}

.bs-popover-bottom {
  margin-top: 10px;

  >.arrow {
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: $border_bottom_color_1;
    border-bottom-color: $border_bottom_color_2;
    top: -11px;

    &:after {
      content: " ";
      top: 1px;
      margin-left: -10px;
      border-top-width: 0;
      border-bottom-color: $border_bottom_color_3;
    }
    &:before {
      border-bottom-color: transparent;
    }
  }
}

.bs-popover-left {
  margin-left: -10px;

  >.arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: $border_left_color_1;
    border-left-color: $border_left_color_2;

    &:after {
      content: " ";
      right: 1px;
      border-right-width: 0;
      border-left-color: $border_left_color_3;
      bottom: -10px;
    }
    &:before {
      border-left-color: transparent;
    }
  }
}

.popover-header {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  background-color: $background_color_2;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.popover-body {
  padding: 9px 14px;
  overflow: unset !important;
}

.toogle-switch {
  .switch {

    font-family: $ag_lato-regular;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 72px;
    height: 26px;
    padding: 3px;
    margin: 0 10px 10px 0;
    border-radius: 18px;
    cursor: pointer;
    box-sizing: content-box;

    .switch-input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      box-sizing: content-box;
    }

    .switch-label {
      position: relative;
      display: block;
      height: inherit;
      font-size: 12px;
      text-transform: capitalize;
      background: #C6C6C6;
      border-radius: inherit;
      box-sizing: content-box;
    }

    .switch-label:before,
    .switch-label:after {
      position: absolute;
      top: 50%;
      margin-top: -.5em;
      line-height: 1;
      -webkit-transition: inherit;
      -moz-transition: inherit;
      -o-transition: inherit;
      transition: inherit;
      box-sizing: content-box;
    }

    .switch-label:before {
      content: attr(data-off);
      right: 11px;
      color: #fff;
    }

    .switch-label:after {
      content: attr(data-on);
      left: 11px;
      color: #fff;
      opacity: 0;
    }

    .switch-input:checked~.switch-label {
      background: $gold;
    }

    .switch-input:checked~.switch-label:before {
      opacity: 0;
    }

    .switch-input:checked~.switch-label:after {
      opacity: 1;
    }

    .switch-handle {
      position: absolute;
      top: 7px;
      left: 8px;
      width: 18px;
      height: 18px;
      background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
      border-radius: 100%;
    }

    .switch-handle:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
      background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
      border-radius: 6px;
    }

    .switch-input:checked~.switch-handle {
      left: 49px;
    }

    /* Transition
    ========================== */
    .switch-label,
    .switch-handle {
      transition: All 0.3s ease;
      -webkit-transition: All 0.3s ease;
      -moz-transition: All 0.3s ease;
      -o-transition: All 0.3s ease;
    }
  }

}

//
// obsolete
//
.toggle-switch-obsolete {
  .switch {
    font-family: $ag_lato-regular;
    position: relative;
    display: block;
    vertical-align: top;
    width: 70px;
    height: 30px;
    padding: 3px;
    margin: 0 10px 10px 0;
    border-radius: 18px;
    cursor: pointer;
    box-sizing: content-box;

    .switch-input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      box-sizing: content-box;
    }

    .switch-label {
      position: relative;
      display: block;
      height: inherit;
      font-size: 16px;
      text-transform: capitalize;
      background: #C6C6C6;
      border-radius: inherit;
      box-sizing: content-box;
    }

    .switch-label:before,
    .switch-label:after {
      position: absolute;
      top: 50%;
      margin-top: -.5em;
      line-height: 1;
      -webkit-transition: inherit;
      -moz-transition: inherit;
      -o-transition: inherit;
      transition: inherit;
      box-sizing: content-box;
    }

    .switch-label:before {
      content: attr(data-off);
      right: 11px;
      color: #fff;
      font-family: $ag_lato-regular;
    }

    .switch-label:after {
      content: attr(data-on);
      left: 11px;
      color: #fff;
      opacity: 0;
      font-family: $ag_lato-regular;
    }

    .switch-input:checked~.switch-label {
      background: $gold;
    }

    .switch-input:checked~.switch-label:before {
      opacity: 0;
    }

    .switch-input:checked~.switch-label:after {
      opacity: 1;
    }

    .switch-handle {
      position: absolute;
      top: 7px;
      left: 9px;
      width: 22px;
      height: 22px;
      background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
      border-radius: 100%;
    }

    .switch-handle:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
      background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
      border-radius: 6px;
    }

    .switch-input:checked~.switch-handle {
      left: 46px;
      background-color: $gold;

    }

    /* Transition
    ========================== */
    .switch-label,
    .switch-handle {
      transition: All 0.3s ease;
      -webkit-transition: All 0.3s ease;
      -moz-transition: All 0.3s ease;
      -o-transition: All 0.3s ease;
    }
  }
}

.toogle-switch-big {
  label {
    white-space: nowrap;
  }

  .switch {
    font-family: $ag_lato-regular;
    position: relative;
    display: block;
    vertical-align: top;
    width: 43.15px;
    height: 21.05px;
    padding: 3px 0px;
    margin: 0 10px 10px 0;
    border-radius: 18px;
    cursor: pointer;
    box-sizing: content-box;

    .switch-input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      box-sizing: content-box;
    }

    .switch-label {
      position: relative;
      display: block;
      height: inherit;
      font-family: $ag_lato-bold;
      font-size: 11px !important;
      text-transform: capitalize;
      background: #C6C6C6;
      border-radius: inherit;
      box-sizing: content-box;
    }

    .switch-label:before,
    .switch-label:after {
      position: absolute;
      top: 50%;
      margin-top: -.5em;
      line-height: 1;
      -webkit-transition: inherit;
      -moz-transition: inherit;
      -o-transition: inherit;
      transition: inherit;
      box-sizing: content-box;
    }

    .switch-label:before {
      content: attr(data-off);
      right: 6px;
      color: #fff;
      font-family: $ag_lato-regular;
    }

    .switch-label:after {
      content: attr(data-on);
      left: 6px;
      color: #fff;
      opacity: 0;
      font-family: $ag_lato-regular;
    }

    .switch-input:checked~.switch-label {
      background: $gold;
    }

    .switch-input:checked~.switch-label:before {
      opacity: 0;
    }

    .switch-input:checked~.switch-label:after {
      opacity: 1;
    }

    .switch-handle {
      position: absolute;
      top: 8px;
      left: 6px;
      width: 11px;
      height: 11px;
      background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
      border-radius: 100%;
    }

    .switch-handle:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
      background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
      border-radius: 6px;
    }

    .switch-input:checked~.switch-handle {
      left: 26px;
      background-color: $gold;
    }

    /* Transition
    ========================== */
    .switch-label,
    .switch-handle {
      transition: All 0.3s ease;
      -webkit-transition: All 0.3s ease;
      -moz-transition: All 0.3s ease;
      -o-transition: All 0.3s ease;
    }
  }
}

@media only screen and (min-width : 1400px) {
  .cdk-overlay-pane {
    .small-popup {
      width: 1000px !important;
    }

    .medium {
      width: 1500px !important;
      height: 1000px !important;
    }

    .large {
      width: 1500px !important;
      height: 1400px !important;
    }

  }

}


.hide-mat-scroll {
  .mat-tab-body-content {
    overflow: hidden;
  }

  .mat-tab-group {
    .mat-tab-body-wrapper {
      .mat-tab-body-active {
        overflow-x: hidden;
        overflow-y: hidden;
      }
    }
  }
}

.tab-view {
  .mat-tab-header {
    height: 50px;
    border-bottom: 1px solid #CCD3D7;
    .mat-tab-labels {
      height: 100%;
      background-color: $color-container-bg !important;

      .mat-tab-label {
        font-size: 14px;
        font-family: $ag_lato-bold;
        color: $color-header;
        opacity:1;
      }

      .mat-tab-label-active {
        color: #C58D0B !important;
      }
    }

    mat-ink-bar {
      height: 3px;
    }
  }
}

.mat-tab-group {
  .mat-tab-labels {
    .mat-tab-label-active {
      color: #C58D0B !important;
    }
  }
}

mat-checkbox {
  .mat-checkbox-inner-container {
    height: 16px;
    width: 16px;
    background: $color-container-bg;

    .mat-checkbox-frame {
      border-radius: 2px !important;
      border-width: 1px !important;
      border-color: $theme-base-color;
    }
  }
  label{
    &.mat-checkbox-layout{
      margin-bottom: 0 !important;
    }
  }
}

.checkbox-class {
  .mat-checkbox-inner-container {
    height: 16px;
    width: 16px;

    .mat-checkbox-frame {
      border-radius: 2px !important;
      border-width: 1px !important;
      border-color: $theme-base-color;;
    }
  }

}

.radio-class {
  color: $color-form-field;

  .mat-radio-button {
    .mat-radio-container{
      height: 16px;
      width: 16px;
    }
    .mat-radio-outer-circle {
      height: 16px;
      width: 16px;
      border-width: 1px;
      border-color: $color-radio-gray;
      background-color: $color-logo;
    }

    &.mat-radio-checked {
      color: $color-base;

      .mat-radio-outer-circle {
        height: 16px;
        width: 16px;
        border-width: 1px;
      }

      .mat-radio-inner-circle {
        border-radius: 50%;
        box-sizing: border-box;
        height: 16px;
        position: absolute;
        width: 16px;
        transform: scale(.6);
      }
    }
  }
}

.ag-p-4 {
  padding: 1rem;
}
.ag-mr-8 {
  margin-right: 2rem;
}
button {
  &.disabledClass {
    border: 1px solid $color-tabText-gray !important;
    color: $color-tabText-gray !important;

  }
}



.grid-table {


  table {

    thead th {
      color: $color-base;
      font-size: 14px;
      height: 43px;
      font-family: $ag_lato-regular;
      font-weight: 700;
      z-index: 9;
    }




    tbody {

      span {

        font-family: $ag_lato-regular;
      }

      tr {
        height: 40px;

        td {
          vertical-align: middle;
          color: $color-form-field;
          font-size: 14px;
          font-family: $ag_lato-regular;
        }
      }
      tr:not(:last-child) {
        border-bottom: 1px solid $color-form-bgColor;
      }
    }
  }
}

.opacity-spa {
  opacity: 0.6
}


.toggle-class {
  .mat-button-toggle {
    width: 120px;
    text-align: center;

    &.mat-button-toggle-checked {
      color: $color-container-bg;
    }
  }
}

.select-class {
  .mat-input-underline {
    display: none;

  }

  .mat-select-arrow {
    margin-left: 0.5rem;
    color: $color-tabText-gray !important;
  }

  .mat-form-field {
    width: auto !important;
  }

  .mat-select-value {
    max-width: 100%;
    width: auto;
  }

}

.mat-tab-body-wrapper
//If hide the material third party timepicker icon background
.ngx-material-timepicker-toggle {
  background: none !important;
}


i {
  &.customCalendar {
    color: $formFieldGray !important;
    padding-bottom: 9px;
    vertical-align: initial;
  }

  &.icon-clock {
    color: $formFieldGray !important;
    vertical-align: initial;
    @extend .IC20;
  }
}

.d-datepicker {
  border-bottom: 1px solid $color-shop-border;
  margin-bottom: 1.25em;
}

.d-datepicker {
  >.form-group {
    margin-bottom: 0px;
  }
}

.d-datepicker {
  >.mat-form-field {
    >.mat-form-field-wrapper {
      padding-bottom: 0px;
    }
  }

  .mat-error {
    position: absolute;
    margin-top: 5px;
  }
}

.d-datepicker {
  >.mat-focused {
    visibility: hidden;
  }
}
.clientBlock
{
  margin: 2px;
  border-radius: 20px;
  background-color: #da1414;
  width: 14px;
  height: 14px;

}
.d-datepicker {
  >.mat-form-field {
    >.mat-form-field-wrapper {
      >.mat-form-field-underline {
        visibility: hidden;
        bottom: 0px;
      }
    }
  }
}

.d-datepicker {
  >.mat-form-field {
    >.mat-form-field-wrapper {
      >.mat-form-field-underline {
        >.mat-form-field-ripple {
          visibility: hidden;
        }
      }
    }
  }
}

.d-datepicker {
  >.mat-form-field {
    >.mat-form-field-wrapper {
      >.mat-form-field-subscript-wrapper {
        min-height: 0px;
        margin-top: 0px;
      }
    }
  }
}
.d-datepicker {
  >.matTimeWidth_79 {
    width: 79%;
  }
}

.d-datepicker {
  .ngx-material-timepicker-toggle {
    top: 12px;
    position: absolute;
    right: 0;
    display: inline-block;
    z-index: 5;
  }
}

.m_datepicker {
  margin-right: 2%;
}

.m_b_datepicker {
  margin-bottom: 2.25em;
}

.datepickerWidth50 {
  width: 50%;
}

/***CONST LABEL*****/
.form-label-normal {
  font-size: $font-size12 !important;
  font-family: $lato-web-web !important;
  color:$label-color;
}

.form-label-primary {
  font-size: $font-size14 !important;
  font-family: $lato-web-web !important;
  color: $color-base;
  text-transform: uppercase;

}

/*******SPA-CONST-BUTTONS*******/

.spa-primary-default, .ag_button--primary {
  @extend .spa-primary-btn-default;
}

.primary-diabled {
  @extend .primary-btn-disbaled;
}

.secondary-default-btn {
  @extend .secondary-btn-default;
}
.secondary-disabled {
  @extend .secondary-btn-disabled;
}
.spa-circle-default {
  @extend .spa-plus-circle-default;
}

.spa-circle-disabled {
  @extend .spa-plus-circle-disbled;
}

.spa-sm-btn-primary {
  @extend .spa-btn-smallhg-primary;
}

.spa-sm-btn-secondary {
  @extend .spa-btn-smallhg-secondary;
}

.spa-insidetb-btn {
  @extend .spa-insidetb-btn;
}

.spa-sm-filter-secondary {
  @extend .spa-btn-filter-secondary;
}

.spa-sm-filter-primary {
  @extend .spa-btn-smallhg-primary;
}

.spa-btnlg-primary {
  @extend .spa-btn-lg-primary;
}

.spa-btnlg-secondary {
  @extend .spa-btn-lg-secondary;
}


.spa-primary-default-save {
  @extend .spa-primary-btn-default;
}

.spa-primary-disabled, .ag_button--primary.mat-button-disabled {
  @extend .primary-btn-disbaled;
}

.secondary-disabled {
  @extend .secondary-btn-disabled;
 }

.spa-button-cancel {
  @extend .spa-primary-btn-cancel;
  @include btn-min-width($wd: 66px);
  .mat-button-focus-overlay{
    opacity: 0 !important;
  }

}

.spa-sm-filter-button {
  @extend .spa-btn-filter-secondary;
  @include btn-min-width($wd: 120px);
}



.spa-form-field-underline-color {
  .mat-input-underline {
    background-color: $color-tabText-gray;
  }
}

label.switch{
  border: none;
}
.textColorGray{
  color:$color-form-field;
}
.transform3dnone{
  .ng-scrollbar-view{
    transform:none;
    overflow: hidden;
  }
  &.mat-dialog-content {
    overflow: hidden;
  }
}

.cancel-button {
  padding-left: 15px;
  padding-top: 10px;
}

.noellipsis{
  text-overflow: unset !important;
  overflow: unset !important;
}

.mat-select-panel,.popover-body{
  overflow-y: auto;
  &::-webkit-scrollbar{
      width: 4px;
      background-color: $BodyBgColour; //Scroll Alter With Browser Support
  }
      &::-webkit-scrollbar-thumb{
      background-color: $color-address;
      border-radius: 4px;
      height: 40px;
  }
}

// **** Added for multiline mat-error overlapping the next line*****//
.overlappingissue{
mat-form-field {
  .mat-form-field-wrapper {

    .mat-form-field-underline {
      position: initial !important;
      display: block;
      margin-top: -1px;
    }

    .mat-form-field-subscript-wrapper,
    .mat-form-field-ripple {
      position: initial !important;
      display: table;
    }

    .mat-form-field-subscript-wrapper {
      min-height: calc(1em + 1px);
    }
  }
}
}

.icon-Minus, .icon-Plus {
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
  color:$icon-color;
}

.vertical-align-middle{
  vertical-align: middle;
}


.form-field-bottom{
  margin-bottom: 10px;
}

/*************Mat Form Control******************/

.cdk-overlay-pane{
  &.small{
    font-size: 100% !important;
    font-weight: none !important;
  }
}
/** Filter styles*/
.spa-filter-title{
  font-family: $ag_lato-bold;
  text-transform: uppercase;
  font-size: 14px;
}
.spa-filter-label{
  font-size: 12px;
}
.spa-filter-link{
  font-size: 12px;
  text-transform: uppercase;
  white-space: nowrap;
}
.spa-filter-button{
  font-size: 14px;
  height: 30px;
  line-height: 15px;
  margin-bottom: 8px !important;
  width: 150px;
}

/* Icon Style */
i{
  &.IC6{
    font-size:6px;
  }
  &.IC7{
    font-size:7px;
  }
  &.IC8{
    font-size:8px;
  }
  &.IC9{
    font-size:9px;
  }
  &.IC10{
    font-size:10px;
  }
  &.IC11{
    font-size:11px;
  }
  &.IC12{
    font-size:12px;
  }
  &.IC13{
    font-size:13px;
  }
  &.IC14{
    font-size:14px;
  }
  &.IC15{
    font-size:15px;
  }
  &.IC16{
    font-size:16px;
  }
  &.IC17{
    font-size:17px;
  }
  &.IC18{
    font-size:18px;
  }
  &.IC19{
    font-size:19px;
  }
  &.IC20{
    font-size:20px;
  }
  &.IC21{
    font-size:21px;
  }
  &.IC22{
    font-size:22px;
  }
  &.IC23{
    font-size:23px;
  }
  &.IC24{
    font-size:24px;
  }
  &.IC25{
    font-size:25px;
  }
  &.IC26{
    font-size:26px;
  }
  &.IC27{
    font-size:27px;
  }
  &.IC28{
    font-size:28px;
  }
  &.IC29{
    font-size:29px;
  }
  &.IC30{
    font-size:30px;
  }
  &.IC35{
    font-size:35px;
  }
}


/* Custom Common override material related Css*/
.custSearchbox{
  .mat-form-field-label.mat-input-placeholder {
    color: $label-color !important;
    padding-left: 10px !important;
    width: 90%;
  }
}

.DDRemoveUnderline{
  .mat-form-field-underline{
    display: none !important;
  }
}
.label-color{
  color:$label-color;
}

.value-color{
  color: $value-color;
}

.prevent-action{
  pointer-events: none;
  opacity: 0.4;
}
.prevent-action-one{
  pointer-events: none;
}

.mat-error{
  font-size: $font-size12;
}

.custMB8{
  margin-bottom: 8px !important;
}


/****** Loader Cover Spinner for Application Loader******************/
#cover-spin, #custom-cover-spin, #setting-cover-spin, #cover-spin-retail,#imageUpload-cover-spin {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(0,0,0,0.5);
  z-index:9999;
  display:none;
}
#cover-spin-retail{
  display: block;
}

@-webkit-keyframes spin {
  from {-webkit-transform:rotate(0deg);}
  to {-webkit-transform:rotate(360deg);}
  }

  @keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
  }

.theme-loader {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  z-index:9999;
  display:none;
}

@-webkit-keyframes loader {
  from {-webkit-transform:rotate(0deg);}
  to {-webkit-transform:rotate(360deg);}
  }

  @keyframes loader {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
  }

.theme-loader::after {
  content:'';
  display:block;
  width:60px;height:60px;
  border-style:solid;
  border-color:$color-border-gold;
  border-top-color:transparent;
  border-width: 4px;
  border-radius:50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

#cover-spin::after, #custom-cover-spin::after,#imageUpload-cover-spin::after, #setting-cover-spin::after,#cover-spin-retail::after {
  content:'';
  display:block;
  position:absolute;
  left:50%;top:50%;
  width:60px;height:60px;
  border-style:solid;
  border-color:$color-border-gold;
  border-top-color:transparent;
  border-width: 4px;
  border-radius:50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

#custom-cover-message, #default-message {
  position: absolute;
    top: calc(50% + 80px);
    left: calc(50% + 30px);
    color: #FFF;
    transform: translate(-50%, -50%);
}
.dynamic-scrollbar {
  .ng-scrollbar-thumb {
    display: none;
  }
  .ng-scrollbar-view {
    overflow: hidden;
  }
}

/** time Out css */
.timepickerCustBtn{
  display: inline-block;
    height: 36px;
    min-width: 88px;
    line-height: 36px;
    border: 12px;
    border-radius: 2px;
    background-color: transparent;
    text-align: center;
    transition: all 450ms cubic-bezier(.23,1,.32,1);
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    cursor: pointer;
    outline: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 16px;
    padding-right: 16px;
    font-family: Roboto,sans-serif;
}

.timepickerCustBtn:hover{
  background-color: rgba(153,153,153,.2);
}
.mat-form-field-underline{
  height: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  background-color: unset !important;
}


.button {
  height: 40px !important;
  line-height: 40px !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
}

.button--primary{
  @extend .button;
  background-color: $theme-base-color !important;
  color: $ContainerBgColor !important;
  border: 1px solid $theme-base-color !important;
  &[disabled] {
    cursor: default !important;
    pointer-events: none !important;
    opacity: 0.5;
  }
}

.button--primary-save {
  @extend .button;
  background-color: $theme-base-color !important;
  color: $color-container-bg !important;
  border: 1px solid $theme-base-color !important;
  &[disabled] {
    cursor: default !important;
    pointer-events: none !important;
    opacity: 0.5;
  }
}

.button--disabled{
  @extend .button;
  background-color: $button_background !important;
  color: $disabled-color !important;
  cursor: default !important;
  pointer-events: none !important;
  opacity: 0.5;
  &::before{
    color: $disabled-color !important;
  }
}


.mat-form-field {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      height: 100%;
    }
  }
}

.golf__link {
  color: $theme-base-color;
}

.select-container-bg {
  .select-container {
    .mat-form-field {
      font-size: 14px;
    }
  }
}

//Scrollbar css for virtual table
// Custom Scroll Bar Styles
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $golf-grey-text-color;
  border-radius: 5px;
}
.mat-tooltip {
  font-size: 10px;
}


// ---menu icon align--
$default-control-padding: 15px;
.ag_pl--3 {
  padding-left: 5px !important;
}
.ag_float--right{
  float:right !important;
}
.mat-form-field-required-marker {
  color: red!important;
}
.golf-form-control {
  padding-right: $default-control-padding;
}

.golf-form-control--lg {
  @extend .golf-form-control;
  width: 100%;
}

.golf-form-control--md {
  @extend .golf-form-control;
  width: 75%;
}

.golf-form-control--sm {
  @extend .golf-form-control;
  width: 50%;
}

.golf-form-control--xs {
  @extend .golf-form-control;
  width: 25%;
}

.golf-form--label {
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
  white-space: nowrap;
}
.golf-form--text {
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
}
.golf-form-control {
  padding-right: $default-control-padding;
}
// Width CSS
.w-0 {
  width: 0;
}
.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33.33%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}
.w-66 {
  width: 66%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-87 {
  width: 87%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.in-block {
  display: inline-block;
}

.font-bold {
  font-weight: bold;
}

a, a:hover {
  color: $theme-base-color;
}

.theme__link {
  color: $theme-base-color;
}

// Radio Button Common CSS
.golf--form-radio-button {
  clear: both;
  padding-right: 0.625rem;

  .mat-radio-label {
    font-size: 14px;
  }
  .mat-radio-label {
    .mat-radio-container {
      width: $radio-size;
      height: $radio-size;
      .mat-radio-outer-circle {
        width: $radio-size;
        height: $radio-size;
      }
    }
  }
  &.mat-radio-checked {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: $theme-base-color;
          border-width: 1px;
        }

        .mat-radio-inner-circle {
          background-color: $theme-base-color;
          transform: scale(0.8);
          width: $radio-size;
          height: $radio-size;
        }
      }
    }
  }
}

//ngx-material-timepicker latest version fix

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.ag-form-label {
  font-size: $font-size12 !important;
  font-family: $lato-web-web !important;
  color:$label-color;
  margin-bottom: 10px;
  white-space: nowrap;
  display: block;
}
.timepicker__actions {
    .button--primary:focus, .ag_button--primary:focus, .picker-ok:focus {
        opacity: 0.8;
    }

    .button--tertiary:focus, .ag_button--secondary:focus, .picker-cancel:focus {
        background-color: $golf-button-bg !important;
    }

    .timepicker-button.picker-ok:focus {
        background-color: $golf-button-bg !important;
    }
}
// Removing Border for disabled button
body{
  .ag_button--primary.mat-button-disabled{
    border:none !important;
  }
}

.hidden-submit {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
}

body .cdk-custom-wrapper .ag_table .table th {
   font-size: 14px
}

.ag_requiredy-lbl {
  &::after {
    content: " *";
    color: $ag_error-color;
  }
}

.ag_display--inflex {
  display: inline-flex !important;
}

.footer-button--actions {
  width: 100%;
  padding: $default-control-padding;
  background-color: $golf-base-white-bg;
  border-top: 1px solid $border-grey;
}
.retail-slider-toggler{
  .icon-left-arrow{
    color: $PureWhite !important;
  }
}
.customspinnerimg,.imageUploadspinnerimg{
  position: absolute;
  left: calc(50% + 9px);
  top:calc(50% + 9px);
  width: 42px;
  height: 46px;
  img{
    width: 100%;
  }
}
// Upgrade to 12 Issues
mat-dialog-actions{
  box-sizing: border-box !important;
}
.mat-horizontal-stepper-wrapper{
  height: 100% !important;
}
.custom-color,.custom-ack-link{
  color: #C58D0B !important
}
.custom-count{
  background: #C58D0B !important;
}


//menu new style

.new-mat-view{
    .container-spa{
      .theme-color-wrapper{
        background: $menu-header-bg-black !important;
        }
      }
  .menu-wrapper-container {
    .header-background{
      background: $menu-bg-black !important;
      .nav-section{
        .navItem ul li a{
        &.active-link{
          color: $menu-primary-gold !important;
          border-radius: 4px;
          background-color: $menu-primary-light-gold;
          i {
            color: $menu-primary-gold !important;
          }
        }
      }
      }
    }
    .header{
      background-color: $menu-secondary-gold;
      nav{
        .mat-tab-label, .mat-tab-link{
          font-family: $ag_poppins-medium !important;
        }
      }
    }
    .mat-tab-link{
      color: $menu-black;
      opacity: 1;
      font-weight: 500;
      &.mat-tab-link.mat-tab-label-active{
        color: $menu-black !important;
        opacity: 1;
        background-color: $menu-primary-light-yellow;
      }
    }
    .subnav-wrapper{
      background: $menu-light-blue;
       .mat-select {
        font-family: $ag_poppins-semibold;
        font-size: 18px !important;
        }
    }
    .vertical-header{
      .nav-section {
        .navItem ul li.text a{
          font-family: $ag_sourcesans-semibold !important;
          font-size: 16px !important;
            color: $menu-black !important;
            opacity: 1;
            &.active-link{
              background-color:$menu-ligter-gold;
              color: $menu-dark-gold !important;
            }
        }
      }

    }
    .master-setup .master-grid .inventory-details .inventory-sub-form-groups .mat-tab-group{
      .mat-tab-header{
          background: unset !important;
          .mat-tab-label.mat-tab-label-active{
            color: unset !important;
          }
          .mat-tab-labels{
            background: unset !important;
          }
          .mat-ink-bar{
            background: #2e67b7 !important;
          }
          .mat-tab-label-content{
            text-transform: unset !important
          }
      }
    }
    .create-transaction .create-transaction-tab .mat-tab-header{
      background-color: unset !important;
      .mat-tab-label.mat-tab-label-active{
        color: unset !important;
      }
      .mat-tab-labels{
        background: unset !important;
      }
      .mat-ink-bar{
        background: #2e67b7 !important;
      }
    }

  }
  .mat-option{
    font-family: $ag_sourcesans-regular;
    height: auto !important;
    min-height: 48px !important;
  }
  .agilysys-dynamic-form .agilysys-form-fields .mat-form-field.mat-form-field-appearance-fill:not(.mat-form-field-invalid) .mat-form-field-label-wrapper .mat-form-field-label{
    font-size: 12px !important;
    color: #747885 !important;
  }
  .agilysys-dynamic-form .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label{
    transform: translateY(-0.59375em) scale(0.75) !important;
    width: 133.3333333333% !important;
  }
  .agilysys-dynamic-form .agilysys-form-fields .mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex .mat-form-field-infix{
    .mat-form-field-label-wrapper{
      top: -0.84375em !important;
    }
  }
  .agilysys-dropdown-search-field .mat-form-field,.agilysys-multiselectdropdown-search-field,.agilysys-dynamic-form .agilysys-form-fields .mat-form-field{
    margin-top: 0px !important;
  }
  .actions-section .no-filter-action .action-item-list .action-buttons .grid-search{
    margin-top: 0px !important;
  }
  .agilysys-post-po-popup .post-po-popup .supplier-header .grouping-search{
    margin-top: 0px !important;
  }
}

#custom-cover-message,
#default-custom-cover-message,
#imageUpload-cover-message{
  position: absolute;
  top: calc(50% + 80px);
  left: calc(50% + 30px);
  color: #fff;
  transform: translate(-50%, -50%);
}


.cdk-overlay-container .cdk-overlay-pane .fontChange{
  font-family: $ag_poppins-semibold !important;
  font-size: 18px !important;
}
.cdk-overlay-container .cdk-overlay-pane .fontChange.mat-option.mat-selected{
    background-color: $menu-tertiary-bg-color!important;
    .mat-option-text{
      span{
      color: $menu-tertiary-color !important;
      }
    }
}


.w-432px{
  width: 432px !important
}

.w-252px{
  width: 252px !important;
}

.w-350px{
  width: 350px !important;
}
.mat-tooltip {
  word-break: break-all;
}
#signature-loader {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(0,0,0,0.5);
  z-index:9999;
  #loaderImg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      color: $ag_white;
      font-size: 18px;
  }
}

#signature-cover-message {
  position: absolute;
  top: 70%;
  width: 100%;
  color: $ag_white;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.multi-search {
  .mat-chip.mat-standard-chip{
    background-color: $theme-base-color !important;
  }
}
.table-cell-style {
  padding: 15px;
  border-bottom: 1px solid $theme-base-color;
}
.account-custom-table {
  width: 100%;
  background-color: $ag_white;

  thead {
    tr {
      th {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: $ag_white;

        >div {
          @extend .table-cell-style;
        }
      }
    }
  }

  tr {
    td {
      @extend .table-cell-style;
      border-color: $ag_border-color;

    }

    &.group-title {
      td {
        border: 0;
        color: $theme-base-color;
      }
    }

    &.highlighted-row {
      background-color: $table-row-highlight;
    }
  }
}
.menu-wrapper-container .appointment-earnings-details--wrapper .mat-tab-label, .menu-wrapper-container .appointment-earnings-details--wrapper .mat-tab-link{
  width: 100% !important;
}
.icon-eye:before{
  color: $theme-base-color !important;
}
.mat-select-arrow{
  color: $theme-base-color !important;
}
.grey-checkbox-container {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;

  &.three-column {
    grid-template-columns: 33% 33% 33%;
  }

  .mat-checkbox-inner-container {
    margin-top: 5px;
  }

  .mat-checkbox-label {
    overflow: hidden;
    white-space: break-spaces;
  }
}

.light-grey-bg {
  background-color: $ag_grey-lightest;
}
.ag_table{
  background-color: rgb(250 245 232);
}
.boxed-icon {
  border: 1px solid $theme-base-color;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 10px 10px;
  margin-right: 10px;
  border-radius: 5px;

  i {
    &::before {
      color: $theme-base-color !important;
      font-size: 20px;
    }
  }
}
// Pagination Content Justification
.mat-paginator-container{
  justify-content: center !important;
}
.mat-button-toggle-group,
.mat-button-toggle-standalone {
  margin-bottom: 20px;
}
.mat-dialog-title {
  background-color: $spa_primary_40;
}
.post-batch {
  border: 1px solid $post-icon-green !important;

  i {
    &::before {
      color: $post-icon-green !important;
    }
  }
}
.link-disabled-post {
  border-color: $gray !important;
  pointer-events: none !important;

  i {
    &::before {
      color: $gray !important;
    }
  }
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 38px !important;
  height: 40px;
}
.mat-dialog-actions {
  box-sizing: border-box !important;
}
.cdk-custom-wrapper .mat-row td [class*=icon-]:before {
  color: rgba(0, 0, 0, 0.8);
}
app-transaction{
  .transaction-container .trascation-header-container{
    height: 60px !important;
  }
  .transaction-container .transaction-grid {
    padding: 0px !important;
  }
  app-view-transaction{
    .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple){
      background-color: unset !important;
    }
    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
      color: #2e67b7 !important;
    }
  }
}
.mat-tab-group .mat-tab-label{
  height: 48px !important;
  max-width: unset !important;
}
.agilysys-post-po-popup .post-po-popup .table {
.mat-checkbox{
  top: 0px !important;
  .mat-checkbox-frame{
      border-color: #000000 !important;
  }
}
  .mat-checkbox.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background{
      background-color: #2e67b7 !important;
  }
}
lib-checkbox-single{
  .mat-checkbox.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background{
    background-color: #2e67b7 !important;
} 
}
lib-grid-table{
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
    color: #2e67b7 !important;
}
}
lib-radio{
  .mat-radio-outer-circle{
    border-color: unset !important;
  }
}
lib-reorder-panel{
  .reorder-box .mat-checkbox{
          top: 0 !important;
          label{
            margin-bottom: 0px !important;
      }
    }
}
lib-agilysys-dynamicform{
  .mat-select-arrow {
    color: #0000008a !important;
  }
}
app-agilysys-detail-expand-popup,app-agilysys-attached-files-popup,app-agilysys-confirmation-popup,app-agilysys-nomath-popup,app-agilysys-suggestqty-popup,app-agilysys-error,app-agilysys-confirm-post-popup,app-agilysys-post-po,app-agilysys-posted-po{
  .mat-dialog-title{
    background-color: unset !important;
  }
}
.copy-table-detail-card .table-detail-header .detail-estimation .estimation-value{
  margin-top: 0.1rem !important;
}


.cgps-logging-profile-sync-wrapper{
  .cdk-custom-wrapper .mat-row td [class*=icon-]:before {
    color: rgba(0, 0, 0, 0.8) !important;
  }
}

.alignRight{margin-left: unset;}

.cdk-overlay-container:has(.Overlay-Alerts) {
  z-index: 1150;
  .Overlay-Alerts {
      opacity: 0.5 !important;
      background-color: rgb(6, 6, 6);
   }
}
app-guest-room-lookup{
  .rain-check-search{
    .mat-form-field-appearance-legacy .mat-form-field-label{
      font-size: unset !important;
    }
  }
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
.errorpop-container{
  line-height: 20px !important;
}

// .CrudEmailSec{
//   .CrudEmailNav{
//     background: $theme-modal-header-color;
//     i{
//       height: 25px;
//       width: 25px;
//       background: $theme-mat-ink-bar !important;
//       color: $ag_white !important;
//       padding: 5px;
//       border-radius: 17px;
//       margin-right: 10px;
//     }
//     .icon-left-arrow{
//       &::before{
//         font-size: 12px !important;
//         position: relative;
//         bottom: 6px;
//       }
//     }
//   }
//   .CRUD-email-Template-wrapper {
//     background: $theme-background-color;
//   }

//   .email-template-section{
//     background: $ag_white;
//     border: 1px solid $theme-border;
//     border-radius: 6px;
//     box-shadow: 0 1px 5px $theme-box-shadow !important;
//   }
//   .editorsec{
//     height: calc(100% - 26px) !important;
//   }
 
//   }   

.cdk-overlay-container:has(.errorpop-container) {
  z-index: 1200 !important;
}
.retailDiscountWrapper{
  .popover.popover-content{
    z-index: 10 !important;
}
}

.mat-input-element {
  height: auto;
}
.retail-items-wrapper .shopping-items .shopping-lists .products .search-div{
  .toggle-label{
    margin-bottom: 0.25rem;
    .toggle-switch{
      margin-bottom: 0.25rem;
    }
  } 
}
.capture-card-container button{
  margin-top: 0.9rem !important;
}
.order-summary{
  .golf--form-radio-button .mat-radio-label .mat-radio-container{
    height: 17px !important;
  }
  .mat-radio-label-content{
    top: 2px !important;
  }
} 
.assignCommision .gratuityDetails{
  .mat-button-toggle-group{
    vertical-align: bottom;
  }
  .therapist-input{
    margin-bottom: 0.25rem;
  }
}

app-cdkvirtual .cdk-custom-wrapper .mat-row td [class*=icon-]:before{
  color: initial;
}
#extract-monitor-wrapper{
  .header-extract-monitor{
    .close .icon-Cancel{
      color: black !important;
      opacity: unset !important;
    }
    .ag_state--headers{
      color: unset !important;
      opacity: unset !important;
    }
  }
}

.count-label{
  padding-top: 4px !important;
}

.ag_toggle, .cdk-custom-wrapper, .cdk-drag{
  .mat-slide-toggle-bar{
    height: 20px !important;
    border-radius: 10px;
    background-color: #00000061;
    .mat-slide-toggle-thumb-container{
      top: 1px !important;
      left: 1px !important;
      .mat-slide-toggle-thumb{
        height: 18px !important;
        width: 18px !important;
      }
    }
  }
  .mat-slide-toggle.mat-checked{
    .mat-slide-toggle-bar{
      background: #c58d0b !important;
    }
    .mat-slide-toggle-thumb{
      background: #fff !important;
    }
  } 
}
mat-option {
  .mat-pseudo-checkbox{
    &.mat-option-pseudo-checkbox{
      color: $theme-base-color !important;
      border-width: 1px !important;
      &::after{
        top: 3px;
        left: 1px;
        width: 9.5px;
        height: 4px;
    }
    }
    &.mat-pseudo-checkbox-disabled{
      color: unset !important;
    }
  }
}
.ck .ck-button__label{
  line-height: 18px !important;
}
.custom-theme-table-wrapper
{
  td{
    background-color: $white-bg!important;
  }
}
.swiper-item {
  width: 100%; /* Automatically adapts */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}




