@import "./abstracts/ag-mixin";
@import "../../../assets/sass/abstracts/variables";
@import "./abstracts/ag-telflag";
.ag_transition5s {
  transition: 1s;
}
.ag_display--none {
  display: none !important;
}
.ag_display--block {
  display: block !important;
}
.ag_display--flex {
  display: flex !important;
}
.ag_display--inflex {
  display: inline-flex !important;
}
.ag_display--inblock {
  display: inline-block !important;
}
.ag_display--inline {
  display: inline !important;
}
.ag_display--flex-wrap {
  @extend .ag_display--flex;
  width: 100%;
  flex-wrap: wrap;
}
.ag_align-items--center {
  align-items: center;
}

// Float Properties
.ag_float--left {
  float: left;
}
.ag_float--right {
  float: right;
}

// Clear Properties
.ag_clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.ag_clear--both {
  clear: both;
}

// Position Properties
.ag_position--relative {
  position: relative;
}
.ag_position--absolute {
  position: absolute;
}
.ag_position--fixed {
  position: fixed;
}
.ag_position--static {
  position: static;
}
.ag_position--sticky {
  position: sticky;
}

// Text Alignment Properties
.ag_text--left {
  text-align: left;
}
.ag_text-right {
  text-align: right;
}
.ag_text--center {
  text-align: center;
}
// Text Transform Properties
.ag_text--uppercase {
  text-transform: uppercase;
}
.ag_text--capitalize {
  text-transform: capitalize;
}
.ag_text--lowercase {
  text-transform: lowercase;
}

// Font Properties
.ag_font--normal {
  font-weight: normal;
}
.ag_font--bold {
  font-weight: bold;
}
.ag_font--italic {
  font-style: italic;
}

//Overflow Properties
.ag_text-overflow--hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ag_text-overflow--auto {
  overflow: auto;
}
.ag_text-overflow--scroll {
  overflow: scroll;
}

// Cursor Properties
.ag_cursor--pointer {
  cursor: pointer;
}
.ag_cursor--move {
  cursor: move;
}
.ag_cursor--not-allowed {
  cursor: not-allowed;
}
.ag_pointer--none {
  pointer-events: none;
}

// Width Properties
.ag_w--15 {
  width: 15%;
}
.ag_w--20 {
  width: 20%;
}
.ag_w--25 {
  width: 25%;
}
.ag_w--33 {
  width: 33.33%;
}
.ag_w--50 {
  width: 50%;
}
.ag_w--66 {
  width: 66.66%;
}
.ag_w--75 {
  width: 75%;
}
.ag_w--100 {
  width: 100%;
}
//Margin Proerties
.ag_m--auto {
  margin: auto;
}
.ag_ml--auto {
  margin-left: auto !important;
}
.ag_mr--auto {
  margin-right: auto;
}
.ag_mt--auto {
  margin-top: auto;
}
.ag_mb--auto {
  margin-bottom: auto;
}
.ag-ml-8 {
  margin-left: 2rem;
}
.ag-ml-4 {
  margin-left: 1rem;
}

// Padding Properties
.ag-pr-8 {
  padding-right: 2rem;
}

// Form related Properties
.ag_form-control {
  padding-right: $default-control-padding;
}

.ag_form_wrapper--xs {
  width: 300px;
}
.ag_form_wrapper--sm {
  width: 550px;
}
.ag_form_wrapper--md {
  width: 800px;
}
.ag_form_wrapper--md-WSB {
  width: 740px;
}
.ag_form_wrapper--lg {
  width: 990px;
}
.ag_form-control--lg {
  @extend .ag_form-control;
  @extend .ag_w--100;
}
.ag_form-control--md {
  @extend .ag_form-control;
  @extend .ag_w--75;
}
.ag_form-control--sm {
  @extend .ag_form-control;
  @extend .ag_w--50;
}
.ag_form-control--33 {
  @extend .ag_form-control;
  @extend .ag_w--33;
}
.ag_form-control--25 {
  @extend .ag_form-control;
  @extend .ag_w--25;
}
.ag_form-control--66 {
  @extend .ag_form-control;
  @extend .ag_w--66;
}
.ag_form-control--xs {
  @extend .ag_form-control;
  @extend .ag_w--25;
}
.ag_radio--horizontal {
  @extend .ag_mb--4;
}
.ag_form--label {
  font-size: $font-size12;
  @extend .ag_display--block;
  color: $ag_black-lighter !important;
  margin-bottom: 10px;
  white-space: nowrap;
}
.ag_form--value {
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @extend .ag_display--block;
  @extend .ag_mb--5;
}
.ag_error {
  color: $ag_error-color;
}
.ag_success {
  color: $ag_success-color;
}
.ag_warning {
  color: $ag_warning-color;
}

.ag_mandatory-lbl {
  &::after {
    content: "*";
    color: $ag_error-color;
  }
}

.showApp {
  height: 100%;
  width: 100%;
}

.ag_modal--form-height {
  height: calc(100% - 4rem);
  overflow: auto;
  scroll-behavior: smooth;
}

.ag_height--100,
.h-100 {
  height: 100% !important;
}

.h-50 {
  height: 50% !important;
}

.ag_footer--actions {
  width: 100%;
  padding: 10px 20px;
  background-color: $ag_white;
  border-top: 1px solid $ag_border-lighter;
  display: flex;
  position: relative;
  z-index: 1;
  height: $dialog-footer-height;
}

// Overall Outer Padding
.ag_container--padding-lg {
  @extend .ag_p--6;
}
.ag_container--padding-md {
  @extend .ag_p--5;
}
.ag_container--padding-sm {
  @extend .ag_p--4;
}
.ag_container--padding-xs {
  @extend .ag_p--3;
}

.submodule-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-container {
  flex-grow: 1;
  padding: 0.75rem 1.25rem 1.25rem 1.25rem;
  user-select: none;
}
.ag_form_wrapper--xlg {
  width: 1024px;
}

/* POP OVER*/
@mixin fix-arrow-position-for-retina($direction) {
  .arrow {
    &:before,
    &:after {
      #{$direction}: 0px !important; // Fix visual gap on retina displays
    }
  }
}

.popover {
  .virtual-area {
    height: 11px;
    width: 100%;
    position: absolute;
  }
  &.bs-popover-top .virtual-area {
    bottom: -11px;
  }
  &.bs-popover-bottom .virtual-area {
    top: -11px;
  }
  &.bs-popover-left .virtual-area {
    right: -11px;
  }
  &.bs-popover-right .virtual-area {
    left: -11px;
  }
}

// .popover-content {
//   padding: 9px 14px;
// }

.popover.popover-content {
  background-color: $popover-bg;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.15);
  z-index: $zindex-popover;
  max-width: none;
  border-radius: $popover-border-radius;
  box-shadow: $popover-box-shadow;

  .arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: 0 !important;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      margin-left: -10px;
    }
  }

  .popover-header {
    color: $popover-header-color;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.1rem;
  }

  .popover-body {
    color: $ag_black;
    line-height: 1.3rem;
  }

  &.bs-popover {
    // These margins all define distance from the target to render
    &-left {
      margin-left: -$popover-arrow-width * 1.5;
      @include fix-arrow-position-for-retina(right);
    }
    &-right {
      margin-left: $popover-arrow-width * 1.5;
      @include fix-arrow-position-for-retina(left);
    }
    &-top,
    &-top-right,
    &-top-left {
      margin-top: -$popover-arrow-height * 1.5;
      @include fix-arrow-position-for-retina(bottom);
    }
    &-bottom,
    &-bottom-right,
    &-bottom-left {
    
      @include fix-arrow-position-for-retina(top);
    }

    &-right,
    &-left {
      .arrow {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &-top,
    &-bottom {
      .arrow {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-right {
      .arrow {
        left: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
        width: $popover-arrow-height;
        height: $popover-arrow-width;
        margin: 0 !important;
      }

      .arrow::before {
        border-width: $arrow-border-width $popover-arrow-height $arrow-border-width 0;
      }

      .arrow::after {
        border-width: $arrow-border-width $popover-arrow-height $arrow-border-width 1.5px;
      }

      .arrow::before {
        left: 0;
        border-right-color: $popover-arrow-outer-color;
      }

      .arrow::after {
        left: $popover-border-width;
        border-right-color: $popover-arrow-color;
      }
    }

    &-left {
      .arrow {
        right: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
        width: $popover-arrow-height;
        height: $popover-arrow-width;
        margin: 0 !important;
      }

      .arrow::before {
        border-width: $arrow-border-width 0 $arrow-border-width $popover-arrow-height;
      }

      .arrow::after {
        border-width: $arrow-border-width 1.5px $arrow-border-width $popover-arrow-height;
      }

      .arrow::before {
        right: 0;
        border-left-color: $popover-arrow-outer-color;
      }

      .arrow::after {
        right: $popover-border-width;
        border-left-color: $popover-arrow-color;
      }
    }

    &-top,
    &-top-right,
    &-top-left {
      .arrow {
        bottom: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
      }

      .arrow::before {
        border-width: $popover-arrow-height $arrow-border-width 0;
      }

      .arrow::after {
        border-width: $popover-arrow-height $arrow-border-width 1.5px;
      }

      .arrow::before {
        bottom: 0;
        border-top-color: $popover-arrow-outer-color;
      }

      .arrow::after {
        bottom: $popover-border-width;
        border-top-color: $popover-arrow-color;
      }
    }

    &-bottom,
    &-bottom-right,
    &-bottom-left {
      .arrow {
        top: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
        border-top-width: 0;
        border-bottom-color: $border_bottom_color_1;
        border-bottom-color: $border_bottom_color_2;
        top: -11px;
      }

      .arrow::before {
        border-width: 0 $arrow-border-width $popover-arrow-height $arrow-border-width;
      }

      .arrow::after {
        border-width: 1.5px $arrow-border-width $popover-arrow-height $arrow-border-width;
      }

      .arrow::before {
        top: 0;
        border-bottom-color: $popover-arrow-outer-color;
      }

      .arrow::after {
        top: $popover-border-width;
        border-bottom-color: $popover-arrow-color;
      }

      // This will remove the popover-header's border just below the arrow
      .popover-header::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: $popover-arrow-width;
        margin-left: calc($popover-arrow-width / -2);
        content: "";
        border-bottom: $popover-border-width solid $popover-header-bg;
      }
    }

    &-top-right,
    &-bottom-right {
      transform: translateX(-2rem);
      .arrow {
        left: 0.9rem;
      }
    }

    &-top-left,
    &-bottom-left {
      transform: translateX(2rem);
      .arrow {
        right: 0.9rem;
      }
    }
  }
}

// Pop-over Ends

h4 {
  &.ag_state--headers {
    color: $ag_grey-darkest;
    font-size: $font-size18 !important; // change this px to rem
    @extend .ag_font--normal;
    font-family: $ag_lato-medium;
    margin-bottom: 0px !important;
  }

  &.ag_group--headers {
    font-size: $font-size16 !important; // change this px to rem
    @extend .ag_mb--7;
    @extend .ag_font--bold;
    font-family: $ag_lato-medium;
  }

  &.ag_group--headers-sm {
    @extend .ag_group--headers;
    @extend .ag_mb--5;
  }

  &.ag_page--headers {
    color: $ag_grey-darkest;
    @extend .ag_mb--5;
    @extend .ag_font--bold;
    font-size: $font-size16 !important; // change this px to rem
  }

  &.ag_card--headers {
    color: $ag_black;
    font-size: $font-size18 !important; // change this px to rem
    @extend .ag_font--normal;
    font-family: $ag_lato-medium;
  }
}

.ag_link {
  color: $ag_base-color !important;
  text-decoration: none;
  white-space: nowrap;
  @extend .ag_cursor--pointer;
  @extend .ag_font--bold;
}

.ag_link--sm {
  @extend .ag_link;
  font-size: 12px;
}

.ag_link--lg {
  @extend .ag_link;
  font-size: 14px;
}

.ag_link--disabled {
  @extend .ag_pointer--none;
  @extend .ag_cursor--not-allowed;
  color: $ag_disabled-color !important;
}

.ag_group--checkbox {
  @extend .ag_mt--5;
  background-color: $ag_grey-lightest;
  border: 1px solid $ag_grey-dark;
  padding: 10px;
  border-radius: 5px;
  .mat-checkbox {
    @extend .ag_pb--3;
    .mat-checkbox-layout {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      width: 100%;
      .mat-checkbox-label {
        vertical-align: middle;
      }
    }
  }
}
.ag_checkbox {
  .mat-checkbox-layout {
    margin-bottom: 16px !important;
  }
}

.ag_multiColumn--checkbox {
  align-self: center;
  margin-top: 25px;
}

// Disables complete section
.ag_section--disable {
  @extend .ag_cursor--not-allowed;
  @extend .ag_pointer--none;
  opacity: 0.5;
}

//Checkbox with Circle Icon
.ag_checkbox--icon {
  padding: 0.9375rem 0 0 0.9375rem;
  .icon-confirmed {
    font-size: 1.25rem;
    color: $ag_grey-darker;
    &.selected-icon {
      color: $ag_base-color;
    }
  }
  .check-label {
    font-size: 14px;
    margin-left: 0.5rem;
    vertical-align: super;
  }
}

.icon-Minus,
.icon-Plus,
.icon-plus,
.icon-minus {
  font-size: 26px;
  margin-left: 10px;
  cursor: pointer;
  color: $theme-base-color;
}

.ag_dialog--xs {
  width: 400px;
  height: 300px;
}

.ag_dialog--sm {
  width: 600px;
  height: auto;
}

.ag_dialog--md {
  width: 1024px;
  height: 768px;
  max-width: 100% !important
}

.ag_dialog--lg {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
}


/** Range Slider Styles in Aside Filter only **/
.aside-filter {
  app-ag-range-slider {
    .ag-range-slider {
      justify-content: space-between;
      align-items: baseline;
      padding: 0 10px;
      mat-form-field {
        width: 50px;
        &.mat-form-field-appearance-outline {
          .mat-form-field-flex {
            padding: 0 0.25rem;
            .mat-form-field-infix {
              padding: 0.25rem 0;
              border-top: 0 !important;
              input.mat-input-element {
                font-size: 12px;
              }
            }
          }
        }
      }

      .ngx-slider {
        width: calc(100% - 110px);
        .ngx-slider-bubble {
          display: none;
        }
      }
    }
  }
}
/// style for shortcut key overlay 
.shortcut-overlay {
  .mat-dialog-container {
      background-color: #000000ab !important;
  }
}
.cdk-overlay-container:has(.shortcut-overlay) {
  z-index: 1080; 
}

/**********************************************/
input.mat-chip-input{
  width: 0 !important;
  flex: 1 0 0 !important;
}