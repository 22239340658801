@import '../helpers/variables';

/*********************************Media query breakpoint values************************************************/
// Desktop
$desktop-min-width: 1025px;
$desktop-zoom-max-width: 1535px;
$desktop-min-height: 769px;
// Landscape Tablet
$tab-landscape-width: 1024px;
$tab-landscape-height: 768px;
// Potrait Tablet
$tab-potrait-max-width: 768px;
$tab-potrait-max-height: 1280px;
$tab-potrait-min-width: 720px;
$tab-potrait-min-height: 1024px;
// Mobile and smaller devices
$mobile-max-width: 600px;
$mobile-max-height: 850px;
$mobile-min-width: 280px;
$mobile-min-height: 530px;

@mixin breakpoint($point) {
    @if $point == mobile {
      @media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) and
      (max-height: $mobile-max-height) and (min-height: $mobile-min-height) {
        @content;
      }  
    } @else if $point == tablandscape {    
      @media only screen and (width: $tab-landscape-width) and (height: $tab-landscape-height) {
        @content;
      }
    } @else if $point == tabpotrait {
      @media only screen and (min-width: $tab-potrait-min-width) and (max-width: $tab-potrait-max-width) and
      (max-height: $tab-potrait-max-height) and (min-height: $tab-potrait-min-height) {
        @content;
      }
    }  @else if $point == desktop {
       @media only screen and (min-width: $desktop-min-width) and (min-height: $desktop-min-height) {
        @content;
       }
    } @else if $point == desktopzoom {
      @media only screen and (max-width : $desktop-zoom-max-width) {
        @content;
      }
    }
  }


  /*** Browser support css property ***/
  
@mixin tranform-rotate($rotate) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  transform: rotate($rotate);
}

@mixin transform-translate($x: 0, $y: 0) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin transition($property: height, $time: 1s) {
  transition: $property $time;
  -moz-transition: $property $time; /* Firefox 4 */
  -webkit-transition: $property $time; /* Safari and Chrome */
  -o-transition: $property $time;
}

@mixin set-font($family, $size, $color){
  font-family: $family;
  font-size: #{$size};
  color: $color;
}


@function create-font-class($font-size, $colour, $family){
  @return (
    font-size: $font-size,
    color: $colour,
    font-family: $family
  )
};

@function create-font( 
  $app-header, 
  $subheading-label-regular, 
  $subheading-label-medium,
  $subheading-medium, 
  $subheading-regular, 
  $body-medium, 
  $body-regular, 
  $body-tiny-light, 
  $body-tiny-medium-upper,
  $body-tiny-medium,
  $body-tiny-regular
  ){
  $config: ( 
    app-header: $app-header,
    subheading-label-regular: $subheading-label-regular,
    subheading-label-medium: $subheading-label-medium,
    subheading-medium: $subheading-medium,
    subheading-regular: $subheading-regular,
    body-medium: $body-medium,
    body-regular: $body-regular,
    body-tiny-light: $body-tiny-light,
    body-tiny-medium-upper: $body-tiny-medium-upper,
    body-tiny-medium: $body-tiny-medium,
    body-tiny-regular: $body-tiny-regular
   );
  @return $config
};

$font-creater: create-font(
  $app-header: create-font-class($font-24, $header-color, $roboto-medium),
  $subheading-label-regular: create-font-class($font-18, $subheading-color, $roboto-regular),
  $subheading-label-medium: create-font-class($font-18, $subheading-color, $roboto-medium),
  $subheading-medium: create-font-class($font-16, $subheading-medium-color, $roboto-medium),
  $subheading-regular: create-font-class($font-16, $subheading-regular-color, $roboto-regular),
  $body-medium: create-font-class($font-14, $body-medium-color, $roboto-medium),
  $body-regular: create-font-class($font-15, $body-regular-color, $roboto-regular),
  $body-tiny-light: create-font-class($font-12, $body-tiny-light-color, $roboto-light),
  $body-tiny-medium-upper: create-font-class($font-12, $body-tiny-medium-upper-color, $roboto-medium),
  $body-tiny-medium: create-font-class($font-12, $body-tiny-medium-color, $roboto-medium),
  $body-tiny-regular: create-font-class($font-12, $body-tiny-regular-color, $roboto-regular)
);

@mixin eatec-set-font($font-creater) {
  @each $model-name, $model-details in $font-creater {
    .#{$model-name} {
      font-size: eatec-get-properity($font-creater, $model-name, font-size) !important;
      color: eatec-get-properity($font-creater, $model-name, color) !important;
      font-family: eatec-get-properity($font-creater, $model-name, font-family) !important;
    }
  }
}


@function create-product-font-class($font-family, $font-size, $font-line-height, $font-line-spacing, $font-text-transform, $product-deafult-color){
  @return (
    font-family: $font-family,
    font-size: $font-size,
    line-height: $font-line-height,
    letter-spacing: $font-line-spacing,
    text-transform: $font-text-transform,
    color: $product-deafult-color
  )
};

@function productFontcreator(
  $product-header-1,
  $product-header-1-m,
  $product-header-2, 
  $product-header-3, 
  $product-header-4,
  $product-header-4-m, 
  $product-header-5, 
  $product-header-5-m,
  $product-body-1, 
  $product-body-2,
  $product-caption,
  $product-label,
  $product-overline
  ){
  $config: ( 
    product-header-1: $product-header-1,
    product-header-1-m: $product-header-1-m,
    product-header-2: $product-header-2,
    product-header-3: $product-header-3,
    product-header-4: $product-header-4,
    product-header-4-m: $product-header-4-m,
    product-header-5: $product-header-5,
    product-header-5-m: $product-header-5-m,
    product-body-1: $product-body-1,
    product-body-2: $product-body-2,
    product-caption: $product-caption,
    product-label: $product-label,
    product-overline: $product-overline,
  );
  @return $config
};

$eatec-product-fontcreator: productFontcreator(
  $product-header-1: create-product-font-class($roboto-regular, $font-24, $font-line-height-32, $font-letter-spacing-0, $font-text-transform, $product-deafult-color-header-1),
  $product-header-1-m: create-product-font-class($roboto-medium, $font-24, $font-line-height-32, $font-letter-spacing-0, $font-text-transform, $product-deafult-color-header-1),
  $product-header-2: create-product-font-class($roboto-medium, $font-20, $font-line-height-28, $font-letter-spacing-10, $font-text-transform, $product-deafult-color-header-2),
  $product-header-3: create-product-font-class($roboto-bold, $font-18, $font-line-height-24, $font-letter-spacing-18, $font-text-transform, $product-deafult-color-header-3),
  $product-header-4: create-product-font-class($roboto-bold, $font-16, $font-line-height-24, $font-letter-spacing-36, $font-text-transform, $product-deafult-color-header-4),
  $product-header-4-m: create-product-font-class($roboto-medium, $font-16, $font-line-height-24, $font-letter-spacing-36, $font-text-transform, $product-deafult-color-header-4),
  $product-header-5: create-product-font-class($roboto-bold, $font-14, $font-line-height-20, $font-letter-spacing-36, $font-text-transform, $product-deafult-color-header-5),
  $product-header-5-m: create-product-font-class($roboto-medium, $font-14, $font-line-height-20, $font-letter-spacing-36, $font-text-transform, $product-deafult-color-header-5),
  $product-body-1: create-product-font-class($roboto-regular, $font-16, $font-line-height-24, $font-letter-spacing-28, $font-text-transform, $product-deafult-color-body-1),
  $product-body-2: create-product-font-class($roboto-regular, $font-14, $font-line-height-20, $font-letter-spacing-24, $font-text-transform, $product-deafult-color-body-2),
  $product-caption: create-product-font-class($roboto-regular, $font-12, $font-line-height-16, $font-letter-spacing-16, $font-text-transform, $product-deafult-color-caption),
  $product-label: create-product-font-class($roboto-regular, $font-10, $font-line-height-16, $font-letter-spacing-52, $font-text-transform, $product-deafult-color-label),
  $product-overline: create-product-font-class($roboto-medium, $font-10, $font-line-height-16, $font-letter-spacing-56, $font-text-transform-all-caps, $product-deafult-color-overline)
);

@mixin product-set-font($eatec-product-fontcreator) {
  @each $product-class-name, $product-font-details in $eatec-product-fontcreator {
    .#{$product-class-name} {
      color: eatec-get-properity($eatec-product-fontcreator, $product-class-name, color) !important;
      font-family: eatec-get-properity($eatec-product-fontcreator,$product-class-name, font-family) !important;
      font-size: eatec-get-properity($eatec-product-fontcreator, $product-class-name, font-size) !important;
      line-height: eatec-get-properity($eatec-product-fontcreator, $product-class-name, line-height) !important;
      letter-spacing: eatec-get-properity($eatec-product-fontcreator, $product-class-name, letter-spacing) !important;
      text-transform: eatec-get-properity($eatec-product-fontcreator, $product-class-name, text-transform) !important;
      margin: 0 !important;
      padding: 0 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// Gets the font size for a level inside a typography config.
@function eatec-get-properity($config, $level, $properity-name) {
  @return _mat-get-type-value($config, $level, $properity-name);
}

  // Utility for fetching a nested value from a typography config.
 @function _mat-get-type-value($config, $level, $name) {
  @return map-get(map-get($config, $level), $name);
}


// for mat form fields 
@mixin product-mat-form-configuration($font-size, $padding) {
  .mat-form-field-flex{
    font-size: $font-size;
    // font-family: $roboto-medium;
  }
  mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix  {
    padding: 0.5em 0px;
  }
  mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper {
    top: -1.5em; 
  }
  input.mat-input-element, .mat-select-value {
    // padding-bottom: 3px;
  }
  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(-15%) !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: .12em !important;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.09375em) scale(0.75) !important;
    width: 133.33333%;
  }
}

@mixin height-calculation($params){
  height: calc(100% - #{$params});
}
@mixin widthcalculation ($params){
    width: calc(100% - #{$params});
}

@mixin calc-width-division ($params) {
  width: calc(100% / #{$params});
}

@mixin product-circle($width, $height, $bgcolor, $color) {
  width: $width;
  height: $height;
  background-color: $bgcolor;
  display: inline-block;
  border-radius: 50%;
  line-height: $width;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  color: $color;
  font-size: 24px;
  font-family: $roboto-medium;
}

