@import "src/assets/sass/abstracts/variables";
$transition-delay: 1s;

.newVersionWrapper {
    .bodyWrap {
        background-color: $ag_white !important;
        border-radius: 6px;
        border: 1px solid $spa_primary_20 !important;
        box-shadow: 0px 1px 5px $spa_primary_20;
    }

    &.new-bg-color,
    .new-bg-color {
        background-color: $background_grey;
    }

    .router-container {
        .newVersionWrapper {
            background-color: $background_grey;
        }
    }

    .ag_footer--actions {
        border-top: 1px solid #EAE2CE !important;
    }

    app-table {
        #pageHeader {
            padding: 0px 10px 0px 10px !important;
            margin-bottom: 15px;
        }

        #fixed-table-container {
            padding: 10px !important;
        }

        .fixed-table-container {
            height: calc(100% - 79px);
        }
    }

    app-client-table,
    #custom-theme-tableId,
    app-appointment-action-table,
    app-day-end,
    app-retail-table,
    app-cdkvirtual,
    app-client-blocked-information,
    app-table,
    app-appointment-table,
    appointment-transaction-log,
    add-deposit,
    app-book-lunch,
    app-appointment-recap {
        thead th {
            background: $menu-secondary-gold !important;
        }

        .cdk-custom-wrapper .mat-header-row .mat-header-cell {
            background: $menu-secondary-gold !important;
        }
    }

    mat-horizontal-stepper {
        .mat-horizontal-stepper-header-container {
            @extend .bodyWrap;
            margin: 10px;
        }
    }

    .mat-paginator {
        background: unset !important;

        .mat-paginator-container {
            max-height: 68px !important;
            //74 to 68 changed for receivables-transaction maintenance
        }
    }

    .pop-head,
    &.client-popup .pop-head,
    &.dialog-container__header {
        background-color: $spa_primary_40;
    }

    .pop-contents {
        border: 1px solid $spa_primary_20 !important;
        box-shadow: 0px 1px 5px $spa_primary_20;

        mat-horizontal-stepper {
            height: calc(100% - 25px) !important;
        }
    }

    &.newServiceClient {
        @extend .bodyWrap;
        margin: 0 10px;
        padding: 2px;

        .details-container,
        .overflow-content {
            background-color: $ag_white !important;
            border-top: unset !important;
        }
    }

    &.assign-therapist-wrapper {
        .package-move-top-wrapper {
            @extend .bodyWrap;
            margin: 10px;
        }

        .Row1 {
            @extend .bodyWrap;
            margin: 0px 0px 10px 10px;
        }

        .tableColoumn {
            @extend .bodyWrap;
            // margin: 0px 10px 10px;
            margin-left: 10px;
            height: calc(100% - 83px) !important;
            width: calc(100% - 10px) !important;
        }

        .client-search {
            @extend .bodyWrap;
            margin: 0px 10px 10px 10px;
            height: 100% !important;
        }

        .mat-dialog-actions {
            @extend .bodyWrap;
            width: 100% !important;
            margin-top: 10px !important;
            display: block;
            padding-left: 10px !important;
            margin-left: unset !important;
        }
    }

    &.retailCustomDataTable {
        thead th {
            background: $menu-secondary-gold !important;
        }
    }

    .filter-toggler {
        .new-slider-toggler {
            display: block;
            position: absolute;
            right: 215px;
            transition: all 0s ease;
            width: 20px;

            &.summary-expand {

                // right: $shop-cart-width;
                i {
                    transform: rotateY(180deg);
                    display: inline-block;
                    color: #fff;
                }
            }

            &.summary-collapse {
                right: 15px;

                i {
                    color: #fff;
                }
            }

            button {
                width: 1rem;
                margin-right: 0 !important;
                height: 3rem !important;
                padding: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                background-color: $theme-base-color;
            }
        }

        &.action-panel-collapse {
            width: 0px;
            border: unset !important;
        }
    }

    .tab-styling-versiontwo {

        .mat-tab-header,
        .mat-tab-labels {
            .mat-tab-label {
                color: #000000 !important;
            }

            .mat-tab-label.mat-tab-disabled {
                cursor: default;
                opacity: 0.5 !important;
            }

            .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
                border-color: $spa_primary_20 !important;
            }
        }

        .mat-tab-header {
            margin: 10px 10px 0 10px;
            @extend .bodyWrap;
        }

        .mat-tab-labels {
            background-color: $ag_white !important;
        }

        .mat-tab-body-wrapper {
            .mat-tab-body {
                @extend .bodyWrap;
                margin: 10px;

                .mat-tab-body-content {
                    border: none !important;
                }
            }

            // .mat-tab-body:nth-child(3), .mat-tab-body:nth-child(4),.mat-tab-body:nth-child(5), .mat-tab-body:nth-child(6), .mat-tab-body:nth-child(7){
            //     background-color: $Pms-lightBlue !important;
            //     border-radius: 0px;
            //     border: none;
            // }
        }
    }

    .footerWrap {
        background-color: $ag_white !important;
        border: 1px solid $spa_primary_20;
        box-shadow: 0px 1px 5px $spa_primary_20;
        padding: 10px;
    }

    &#SpaappointmentSchedule {
        background-color: $background_grey;

        .gridfirstSec {
            @extend .bodyWrap;
            margin: 10px;
        }

        .newclearfix {
            @extend .bodyWrap;
        }

        #SecondRow {
            height: calc(100% - 125px) !important;
            display: flex;
            padding: 2px;
            background-color: unset !important;
            margin: 0px 10px 10px 10px;

            #fixedtabLayout {
                @extend .bodyWrap;

                .filterbuttonaction {
                    float: right;
                    left: -30px !important;
                }

                .legend-row {
                    width: calc(100% - 530px) !important;
                }

                .packageGrid {
                    margin-right: 15px !important;
                }
            }

            // #LegendSec #legend-row{
            //     width: unset !important;
            // }
        }

        .filters {
            left: 10px !important;

            &.hideMenu {
                margin-right: 13px
            }

            aside {
                @extend .bodyWrap;
            }
        }

        .overall-div {
            background-color: #fff !important;
            border-left: unset !important;
        }
    }

    &.checkin-appt-dialog {
        .checkin-appt-content {
            @extend .bodyWrap;
            margin: 10px !important;
            height: calc(100% - 90px) !important;
        }
    }

    &.spa-wizzard-component {
        .spa-wizard-header {
            background-color: $spa_primary_40;
        }

        .search-appointment-content {
            height: calc(100% - 85px) !important;
        }

        .spa-wizard {
            @extend .bodyWrap;
            margin: 10px 10px 0 10px;
        }

        .new-appointment-content {
            height: calc(100% - 60px) !important;
        }

        .spa-search {
            @extend .bodyWrap;
            margin: 10px;
        }

        .spa-display {
            height: calc(100% - 125px) !important;

            .searchSection {
                @extend .bodyWrap;
                margin: 0 10px;
                overflow: auto;

                .SearchFilter {
                    width: unset !important;
                    border-right: unset !important;
                    height: unset !important
                }
            }

            .search-results {
                @extend .bodyWrap;
                margin-right: 10px;
                height: 100% !important;

                .result-container {
                    height: calc(100% - 15px) !important;
                }
            }
        }
    }

    &.newAppointment {
        .details {
            @extend .bodyWrap;
            margin: 10px;
        }

        .appointmentSelection {
            @extend .bodyWrap;
            margin: 10px 0;
        }

        .appointmentInfo {
            @extend .bodyWrap;
            margin: 10px;
        }
    }

    &#packageServiceID {
        .RHTcnt {
            @extend .bodyWrap;
        }
    }

    &.AppointmentSearch {
        .selection-div {
            background-color: unset !important;
            padding: 0 10px 10px 10px !important;
        }

        .seach-area {
            margin: 0 10px;
            height: calc(100% - 185px);

            .sect-collapsed {
                width: calc(100% - 200px) !important;
            }

            .search-area-filter {
                transition: all 0s ease;
            }

            #fixed-table-container {
                padding: 0px !important;
            }

            .SearchData {
                padding: 0 1rem 0 0 !important;

                app-appointment-table {
                    .apptCustomDataTable.app-custom-table .fixed-table-container .grid-table {
                        border-radius: 6px;
                    }

                    #SPACustomTable {
                        height: 100% !important;
                    }

                    thead th {
                        background: $menu-secondary-gold !important;
                    }
                }
            }
        }
    }

    &.app-confirmation-container {
        padding: 10px !important;

        .filters {
            padding: 10px;
            margin-right: 10px;
        }

        .confirmations {
            .record-search {
                height: calc(100% - 20px);
            }
        }
    }

    &.waitlist,
    &.lost-denied-business-wrapper {
        padding: 10px;
        display: flex;

        app-table {
            #pageHeader {
                @extend .bodyWrap;
                margin-bottom: 10px !important;
            }
        }

        #fixed-table-container {
            @extend .bodyWrap;
            height: calc(100% - 75px) !important
        }

        .sect-collapsed {
            width: calc(100% - 215px) !important;
        }

        .filters {
            margin-left: 10px;
            transition: all 0s ease;

            .filterAccord {
                border-bottom: unset !important;
            }
        }

        .list-container {
            padding-bottom: 2px;

            .table-section {
                border-left: unset !important;
            }
        }
    }

    &#app-package-stepper {
        .client-content {
            @extend .bodyWrap;
            margin: 0 10px 10px 10px;
            height: calc(100% - 5rem) !important;
        }
    }

    &.all-clients {
        background-color: $background_grey;

        #pageHeader {
            @extend .bodyWrap;
            padding: 0 10px !important;
            margin: 10px !important
        }

        .clientWrapperNewverion {
            @extend .bodyWrap;
            margin: 10px;
            height: calc(100% - 135px);

            .clientactions {
                height: calc(100% - 20px) !important;
                margin: 10px !important;
            }
        }
    }

    &.client-popup {
        .appointmentTabLayout {
            background-color: $background_grey;
        }

        .dialog_actions_style {
            border-top: unset !important;

            .pop-button {
                border-top: unset !important;
            }
        }

        app-create-client {
            mat-tab-group {
                @extend .tab-styling-versiontwo;
            }
        }
    }

    &.overall-container {
        background-color: $background_grey;

        .service-client {
            margin-top: 10px !important
        }

        .recc-appt-content {
            @extend .bodyWrap;
            margin: 10px !important;
            height: calc(100% - 210px) !important;
        }
    }

    &.change_service {
        .mat-stepper-horizontal .mat-horizontal-content-container {
            height: calc(100% - 35px) !important;
        }

        height: calc(100% - 10px) !important;

        .details {
            height: calc(100% - 175px) !important
        }

        .newbodyWrap {
            @extend .bodyWrap;
            margin: 10px;
            height: unset !important;
        }

        .mat-dialog-actions {
            background-color: $ag_white;
        }
    }

    &.book-lunch-dialog {
        .book-lunch-content {
            display: flex;
            height: calc(100% - 8rem) !important;

            .booklunch-left-section {
                @extend .bodyWrap;
                width: calc(100% - 370px) !important;

                .table-container {
                    height: calc(100% - 60px) !important;
                }
            }

            .booklunch-right-section {
                @extend .bodyWrap;
                width: 355px !important;
                margin-left: 10px;

                .list-group {
                    height: calc(100% - 60px) !important;
                }
            }
        }
    }

    &.appointment-transaction-log {
        .appointment-action-table {
            @extend .bodyWrap;
            margin: 10px;
            ;

            #SPACustomTableActions {
                border: unset !important;
                @extend .bodyWrap;
                padding: 1rem !important;

                .table-section {
                    height: calc(100% - 20px) !important;
                }
            }
        }
    }

    #appMove {
        .appMoveForm {
            height: calc(100% - 210px) !important;

            .MoveRow1 {
                @extend .bodyWrap;
                margin: 10px 10px 0 10px;
            }

            .MoveRow2 {
                padding: 10px 10px 0 10px !important;
                height: calc(100% - 60px) !important;
                display: flex;

                .tableColoumn {
                    @extend .bodyWrap;
                    margin-right: 10px;
                }

                .MoveContentColoumn {
                    @extend .bodyWrap;
                }
            }
        }

        .mat-dialog-actions {
            width: 100%;
            background-color: $ag_white;
            padding-left: unset !important;
            height: unset !important;

            .pop-button {
                margin-left: unset !important;
                padding-left: 10px !important;
            }
        }
    }

    #appCopy {
        .copServiceClient {
            margin-top: 10px;
        }

        .appCopyForm {
            height: calc(100% - 13rem) !important;

            .CopyRow1 {
                @extend .bodyWrap;
                margin: 10px 10px 0 10px;
            }

            .CopyRow2 {
                padding: 10px 10px 0 10px !important;
                height: calc(100% - 85px) !important;
                display: flex;

                .tableColoumn {
                    @extend .bodyWrap;
                    margin-right: 10px;
                    padding: 1px;
                }

                .CopynContentColoumn {
                    @extend .bodyWrap;
                }
            }
        }

        .mat-dialog-actions {
            width: 100%;
            background-color: $ag_white;
            padding-left: unset !important;
            height: unset !important;

            .pop-button {
                margin-left: unset !important;
                padding-left: 10px !important;
            }
        }
    }

    &.newVersionWrapper {
        .therapistForm {
            height: calc(100% - 74px) !important;

            .therapist {
                @extend .bodyWrap;
                margin: 10px;
            }

            .field-holder {
                @extend .bodyWrap;
                height: calc(100% - 125px) !important;
                margin: 0 10px;
            }
        }

        .mat-dialog-actions {
            background-color: $ag_white;
        }
    }

    &.lostDeniedError-popup {
        // .pop-contents{
        //     height: calc(100% - 60px) !important;
        // }
    }

    &.notifySection {
        .actions-div {
            height: unset !important;
        }

        .mat-dialog-actions {
            width: 100%;
            background-color: $ag_white;
            padding-left: unset !important;
            height: unset !important;

            .pop-button {
                margin-left: unset !important;
                padding-left: 10px !important;
            }
        }
    }

    &#appRecap {
        .recapServiceClient {
            margin: 10px 0;
        }

        .recapForm {
            @extend .bodyWrap;
            margin: 0 10px;
            height: calc(100% - 9rem) !important;
        }
    }

    &.swap {
        .swapServiceClient {
            margin: 10px 0;
        }

        .swapAppt {
            @extend .bodyWrap;
            margin: 0 10px;
            height: calc(100% - 90px) !important;
        }

        .mat-dialog-actions {
            background-color: $ag_white;
        }
    }

    &#linkMultipack {
        .multipackServiceClient {
            margin: 10px 0;
        }

        .linkMultipackForm {
            height: calc(100% - 9rem) !important;

            .linkmultipack {
                @extend .bodyWrap;
                margin: 0 10px;
            }
        }
    }

    &.service-addon-dialog {
        .addonServiceClient {
            margin: 10px 0;
        }

        .service-addon-content {
            @extend .bodyWrap;
            margin: 0 10px !important;
            height: calc(100% - 215px) !important;
            margin-bottom: 10px !important;
        }

        .mat-dialog-actions {
            background-color: $ag_white;
        }
    }

    &.checkout-appt-dialog {
        .depositSearchWrap {
            @extend .bodyWrap;
            margin: 10px;

            .client-search-row {
                background-color: #fff !important
            }
        }

        .checkout-container {
            @extend .bodyWrap;
            margin: 0 10px;
            height: calc(100% - 188px) !important;
            margin-bottom: 10px;

            .checkout-appt-content {
                padding: 0 15px 15px 15px !important
            }
        }

        .mat-dialog-actions {
            padding-left: 10px;
        }
    }

    &.staffSchedulePopup {
        .staff-schedule-header {
            @extend .bodyWrap;
            margin: 10px;
        }

        .staff-creation-section {
            @extend .bodyWrap;
            margin: 0px 10px 10px 10px;
            height: calc(100% - 135px) !important;
        }
    }

    &.add-deposit {
        .depositSearchClient {
            @extend .bodyWrap;
            margin: 10px 10px 0 10px;

            .client-search-row {
                background-color: #fff !important;
            }
        }

        .overall-scroll-div {
            margin: 0 10px !important;
            height: calc(100% - 170px) !important;

            .client-info-row {
                @extend .bodyWrap;
                padding: 0 10px;
            }
        }
    }

    &.cancel-appt-dialog {
        .cancelServiceClient {
            margin-top: 10px;
        }

        .cancel-appt-form {
            height: calc(100% - 195px);

            .cancel-appt-content {
                padding: 0 10px !important;
                margin-top: 10px !important;

                .cancel-appt-left {
                    @extend .bodyWrap;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    padding: 15px !important;

                    .subheader {
                        padding-top: 0px !important
                    }
                }

                .cancel-appt-right {
                    @extend .bodyWrap;
                    margin-bottom: 10px;
                    padding: 15px;

                    .cancel-fee-container {
                        padding-top: 0px !important
                    }
                }
            }
        }

        .mat-dialog-actions {
            width: 100%;
            background-color: $ag_white;
            padding-left: 10px !important;
            height: unset !important;

            .pop-button {
                margin-left: unset !important;
                padding-left: 10px !important;
            }
        }
    }

    &.authorize {
        height: calc(100% - 85px) !important;
        @extend .bodyWrap;
        margin: 10px;
        padding: 10px !important;

        .tableHeight {
            background-color: #faf7f0
        }
    }

    &.schedule-email-wrapper {
        background-color: $background_grey;

        .form-wrapper {
            @extend .bodyWrap;
            height: calc(100% - 10rem) !important;
            margin: 1rem;
        }
    }

    &.Errorpop-container {
        .pop-contents {
            background-color: $background_grey;
        }
    }

    &.pop-container {
        .pop-contents {
            background-color: $background_grey;
        }
    }

    &.night-audit-wrapper {
        height: calc(100% - 4rem) !important;
        background-color: $background_grey;
        padding: 10px;

        .bg-color {
            @extend .bodyWrap;
        }

        .night-audit-container {
            @extend .bodyWrap;
            margin-top: 10px;
        }
    }

    //retail

    &.retail-items-wrapper {
        background-color: $background_grey !important;
        padding-top: 10px !important;

        .shopping-items {
            padding-left: 10px;

            .category-panel-collapse {
                border: none !important;
                box-shadow: none;
            }

            .categories {
                .retail-category-toggler {
                    &.category-expand {
                        left: 188px;
                        top: 136px;
                    }
                }

                @extend .bodyWrap;
                margin: 0 !important;
                margin-bottom: 10px !important;
                height: calc(100% - 11px) !important;

            }

            .retail-category-toggler {
                .icon-right-arrow {
                    color: $PureWhite !important;
                }

                &.category-collapse {
                    top: 136px;
                    left: 3.5px;
                }
            }

            .shopping-lists {
                padding-left: 20px;
                padding-right: 10px;

                .products {
                    padding-right: 18px;

                    .search-div {
                        @extend .bodyWrap;
                        margin-top: 0 !important;
                        padding: 20px;
                        height: fit-content;
                    }

                    .product-div {
                        @extend .bodyWrap;
                        margin-top: 10px;
                        height: calc(100% - 175px) !important;

                        .details-div {
                            @extend .bodyWrap;
                            margin: 10px;
                        }
                    }
                }

                .shopping-cart.action-bar {
                    @extend .bodyWrap;
                    margin-top: 0 !important;
                    height: calc(100% - 11px);

                    .retail-slider-toggler {
                        top: 0 !important;
                    }
                }
            }
        }

    }

    &.retail-transaction-wrapper {
        height: calc(100% - 15px) !important;

        .page-header {
            @extend .bodyWrap;
            margin: 10px;
            width: calc(100% - 20px) !important;
        }

        .advanced-search-section {
            @extend .bodyWrap;
            margin: 0 10px;
        }

        .fixed-table-container {
            @extend .bodyWrap;
            margin: 10px;
            height: calc(100% - 265px);
            overflow: auto;
            background-color: $spa_primary_10 !important;

            .fixed-table-container-inner {
                #SPACustomTableShop {
                    background-color: $ag-white !important;
                }
            }
        }

        .item-search {
            app-search-linked-retail-items {
                .mat-form-field-infix {
                    border: 1px solid #ececec;
                }

                .mat-input-element::placeholder {
                    user-select: none;
                    transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1);
                    color: #0000006b !important;
                }
            }
        }


        .result-wrapper {
            .filter {
                margin: 10px 10px 0 10px !important;
            }

            .result-grid {
                .user-search {
                    @extend .bodyWrap;
                    margin: 10px 10px 0 10px !important;
                }

                .retailCustomDataTable {
                    .page-header {
                        &.removeHeight {
                            display: none !important;
                        }
                    }

                    height: calc(100% - 100px) !important;

                    .fixed-table-container {
                        margin: 10px 10px 0 10px !important;
                        height: calc(100% + 7px) !important;
                        overflow: hidden;
                    }
                }
            }
        }

        .modify-commission {
            .result-wrapper {
                .filter {
                    @extend .bodyWrap;
                }
            }
        }
    }

    &.retail-transcations-log-wrapper,
    &.eatec-failure-processing-wrapper {
        height: calc(100% - 10px) !important;

        .header-filter-sect {
            @extend .bodyWrap;
            margin: 10px;
        }

        .advanced-search-sect {
            @extend .bodyWrap;
            margin: 0 10px;
        }

        .table-sect {
            @extend .bodyWrap;
            height: calc(100% - 275px) !important;
            margin: 0 10px;
            width: unset !important;
        }
    }

    &.retail-revenue-log-wrapper {
        height: calc(100% - 30px) !important;

        .header-filter-sect {
            @extend .bodyWrap;
            margin: 10px;
        }

        .advanced-search-sect {
            @extend .bodyWrap;
            margin: 0 10px;
        }

        .table-sect {
            @extend .bodyWrap;
            height: calc(100% - 255px) !important;
            margin: 10px 10px 0 10px;
            width: unset !important;
        }
    }

    &.orphan-payments {
        height: calc(100% - 30px) !important;

        .header-filter-sect {
            @extend .bodyWrap;
            margin: 10px;
        }

        .advanced-search-sect {
            @extend .bodyWrap;
            margin: 0 10px;
        }

        .table-sect {
            @extend .bodyWrap;
            height: calc(100% - 205px) !important;
            margin: 10px 10px 0 10px;
            width: unset !important;
        }
    }

    // &.retail-gift-card-wrapper{
    //   height: calc(100% - 10px) !important;
    //   padding-top: 10px !important;
    //   .retail-form-section{
    //     height: calc(100% - 20px) !important;
    //     .header-container{
    //       @extend .bodyWrap;
    //       height: fit-content !important;
    //       margin-top: 0 !important;
    //       padding: 10px;
    //     }
    //     .body-container{
    //       height: calc(100% - 126px);
    //     }
    //   }

    // }
    // &.retailChildWrapper{
    //   .retail-header{
    //     @extend .bodyWrap;
    //     padding: 10px;
    //   }
    //   .retail-select-container{
    //     @extend .bodyWrap;
    //   }
    //   .CustomTableGrid{
    //     .retail-CustomDataTable{
    //       .fixed-table-container{
    //         @extend .bodyWrap;
    //       }
    //     }
    //   }

    // }
    &.order-summary {
        .order-header {
            background-color: $spa_primary_40;
        }

        .row-details {
            margin: 10px 0;
            margin-top: 0;
            height: 100% !important;

            .client-inner {
                width: calc(100% - 395px) !important;
            }

            #client-details,
            #client-type {
                @extend .bodyWrap;
                margin: 0 10px 10px 10px;
                width: unset !important;
            }

            .table-wrapper {
                @extend .bodyWrap;
                height: calc(100% - 100px) !important;
                margin: 0 10px;

                #search-tab {
                    margin-top: 10px;
                }

                .label-service {
                    margin: 0px 20px 0 20px !important;
                }

                .teble-view {
                    height: calc(100% - 195px) !important;
                }
            }

            .order-present-table-view {
                height: calc(100% - 133px) !important;
            }

            .order-present-table-wrapper {
                height: calc(100% - 134px) !important;
            }

            app-payment {
                .transition-details {
                    // background-color: $ag_white !important;
                    border-radius: 6px;
                    border: 1px solid $spa_primary_20 !important;
                    box-shadow: 0px 1px 5px $spa_primary_20;
                    height: calc(100% - 10px) !important;
                    width: 395px;

                    .payment-details {
                        background: unset !important;
                    }
                }
            }
        }
    }

    &.retail-gift-card-wrapper {
        height: calc(100% - 15px) !important;
        padding-top: 10px !important;

        .retail-form-section {
            height: calc(100% - 20px) !important;

            .header-container {
                @extend .bodyWrap;
                height: fit-content !important;
                margin: 0 10px !important;
            }

            .body-container {
                height: calc(100% - 126px);
            }
        }

    }

    &.day-end {
        background-color: $background_grey;

        .horizontal-stepper-class {
            padding: 10px !important;

            .Dayend-Sectable,
            .revenueWrapper,
            .unsettledFolio {
                @extend .bodyWrap;
                padding: 10px;
                margin-bottom: 10px;
            }
        }
    }

    &.folioWrapper,
    &.over-folio-wrapper-new {
        .morebutton_wrapper {
            border-radius: 6px;

            .folio-width {
                width: 100%;
                background-color: $ag_white !important;
                border-radius: 6px !important;
            }

            .mat-tab-header,
            .mat-tab-labels {
                border: 1px solid $spa_primary_20;
                border-radius: 6px;

                mat-tab-label {
                    color: #000000 !important;
                }

                .mat-tab-label.mat-tab-disabled {
                    cursor: default;
                    color: #000000 !important;
                    opacity: 0.4 !important;
                }

                .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
                    border-color: $color-resolution-grey !important;
                }
            }

            .mat-tab-header {
                margin-bottom: 10px;
            }

            .mat-tab-body-content {
                border: unset !important;
            }

            mat-button-toggle-group {
                border: 1px solid $spa_primary_20 !important;
                margin-bottom: 15px !important;

                mat-button-toggle {
                    border-left: none !important;

                    .mat-button-toggle-label-content {
                        padding: 0 3rem !important;
                    }
                }

                .mat-button-toggle-checked {
                    background-color: #f4f0e6 !important;
                    border-bottom: 2px solid $theme-base-color !important;
                }

                .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
                    border-left: none !important;
                }
            }
        }
    }

    &.assign-room--wrapper {
        .section-split {
            background-color: $ag_white;
            border: 1px solid $color-light-gray;
            border-radius: 6px;
        }

    }

    &.payment-information-wrapper {
        .taxExemptWrapper {
            background-color: $ag_white;
            border-radius: 6px;
            padding: 15px 0 15px 20px !important;
            border: 1px solid $color-light-gray;

            .tax-exempt-tableSection {
                padding: 0px 20px 0px 0px;
            }
        }

        .paymentWrapper {
            background-color: $ag_white;
            border-radius: 6px;
            padding: 15px 0 15px 20px !important;
            border: 1px solid $color-light-gray;

            .new-section {
                margin-right: 21px;
            }
        }
    }

    &.folio-split-wrapper {
        .left-side-sect {
            border-radius: 6px;
            border: 1px solid $spa_primary_20;

            .subheaderfolio {
                background: $ag-white !important;
            }
        }

        .over-folio-split-wrap {
            padding-bottom: 15px;

            .alignitemcenter {
                background-color: $ag-white !important;
            }
        }

        .ag_group--headers {
            .rght {
                width: 0% !important;
            }
        }

        .right-side-sect {
            .border-cont {
                // background-color: $theme_box_color !important;
                position: relative;
            }

            .title-sect {
                background-color: $spa_primary_20;;
            }

            .default-sect-bootom {
                .custom-label {
                    margin-left: unset !important;
                    text-align: unset !important;
                    font-size: 13px !important;
                }
            }

            .selectedFolioSection {
                border-bottom: 2px solid $ag_base-color;
                position: relative;
            }
        }

        .icon-Default-selection {
            color: #fab803;
            position: absolute;
            top: -7px;
            right: -5px;
            z-index: 99999;
        }
    }

    .make-payment-wrapper {
        .folio-details {
            height: calc(100% - 105px) !important;
            padding: 10px;

            .loop-wrapper {
                height: calc(100% - 40px);
            }
        }

        .cont-left {
            // width: calc(100% - 390px);
            margin-right: 10px;

            .folio-details {
                // height: calc(100% - 105px) !important;
                padding: 10px;

                .loop-wrapper {
                    height: calc(100% - 35px) !important;
                }
            }
        }

        .cont-right {
            margin-top: 10px;
        }
    }

    .folioStatementSection {
        height: calc(100% - 2px);
        .custom-horizontal-scroll-statement{
            .searchbox {
                .mat-form-field-label{
                    font-size: 14px !important;
                }
            }
        }
        .bodyWrap {
            margin: -6px 0px 10px 0px !important;
            padding: 0.5rem !important;
        }
        .loop-sect-all-folio{
            border: 1px solid $spa_primary_20 !important;
            box-shadow: 0px 1px 5px $spa_primary_20;
                .folio-stat-header{
                background-color: $spa_primary_20 !important;
            }    
        }
    }

    &.data-settings--wrapper,
    .setNotificationConfiguration,
    .miscellaneous-wrapper,
    .template-email-wrapper,
    .CrudSmsSec,
    .crud-email-temp-wrapper {
        background-color: $ag_white !important;
    }

    &.transfer-balance,
    &.transfer-amount,
    &.rate-adjustment,
    &.transfer-posting,
    &.content-container {

        // background-color: $Pms-lightBlue;
        .ag_form-control--xs{
            padding-left: 0 !important;
        }
        .bodyWrap {
            margin: 10px;
            padding: 0.5rem 1rem !important;
        }

        .table-sect {
            padding: 0 10px 10px 10px !important;
        }

        .mat-horizontal-content-container {
            padding: 0px !important;
        }

        // mat-horizontal-stepper{
        //     background-color: $Pms-lightBlue;
        // }
        h4.ag_group--headers {
            margin-bottom: 0.5rem !important;
        }

        .right-section {
            margin: 10px 0px 10px 0px !important;
        }

        .table-container {
            padding: 0rem 1rem 0.5rem 1rem !important;
        }
    }

    .revenue-types-wrapper {
        padding: 10px;

        app-table-search-header {
            .header-wrapper {
                @extend .bodyWrap;
                margin-bottom: 10px;
            }
        }

        .table-container {
            padding: 0 !important;
        }
    }

    &.retail-payment-method-wrapper {
        height: calc(100% - 15px) !important;

        .header-wrapper {
            @extend .bodyWrap;
            margin: 10px;
            width: unset;
        }

        &#paymentMethod {
            .retailGrid {
                margin: 10px !important;
                height: calc(100% - 90px) !important;
                @extend .bodyWrap;
            }
        }
    }

    &.department-wrapper {
        .header-wrapper {
            @extend .bodyWrap;
            margin: 10px 10px 0 10px;
            width: unset;
        }

        .table-container {
            padding: 0 10px 10px 10px !important;
        }
    }

    &.Department-wrapper {
        .ag_modal--form-height {
            @extend .bodyWrap;
        }
    }

    &.folio-defaults--wrapper {
        padding: 10px 10px 10px 10px;
    }

    &.Department-wrapper {
        .ag_modal--form-height {
            background-color: $background_grey !important;
            padding: 10px !important;
        }
    }

    &.master-folio {
        .folio-search-wrapper {
            background-color: $background_grey !important;
            padding: 10px !important;
        }
    }

    &.unsettled-folio {
        .unsettled-wrapper {
            background-color: $background_grey !important;
            padding: 10px !important;
        }
    }

    //Accounting styles
    &.view-invoice-wrapper {
        background-color: $background_grey !important;
        overflow: auto;
        padding: unset !important;

        .search-wrap {
            @extend .bodyWrap;
            margin: 10px;
            padding: 15px;
        }

        .noDataWrap {
            height: calc(100% - 152px);
            margin: 10px;
            @extend .bodyWrap;

            .noDataDiv {
                justify-content: center;
                display: flex;
                align-items: center;
                height: 100%;
                flex-direction: column;
            }
        }

        .view-invoice-content {
            height: calc(100% - 140px) !important;

            .invoice-detail {
                margin: 10px;
                @extend .bodyWrap;
                width: unset !important;
            }

            .bottom-sect {
                height: calc(100% - 180px) !important;
                @extend .bodyWrap;
                margin: 10px;
                padding: 15px;

                .table-container {
                    height: calc(100% - 50px) !important;
                }
            }
        }
    }

    &.view-applied-history-wraper {
        .content {
            background-color: $background_grey !important;
            padding: 0px !important;
            height: calc(100% - 64px) !important;
            overflow: auto;

            .details-wrapper {
                @extend.bodyWrap;
                margin: 10px;
                width: unset !important;
                display: block !important;
            }

            .bottom-sect {
                @extend.bodyWrap;
                margin: 0px 10px 10px 10px;
                height: calc(100% - 170px) !important;
                padding: 15px;

                .table-container {
                    height: calc(100% - 55px) !important;
                }
            }
        }
    }

    &.generate-invoice-wrapper {
        background-color: $background_grey !important;
        overflow: auto;

        .top-section {
            @extend .bodyWrap;
            margin: 10px !important;
        }

        .ar-accounts-sec {
            height: calc(100% - 120px) !important;

            .filter-section {
                @extend .bodyWrap;
                margin: 0px 10px 10px 10px;
            }

            .table-wrapper {
                @extend .bodyWrap;
                margin: 0px 10px 10px 0px;
                height: unset !important;

                .table-container {
                    height: calc(100% - 120px);
                }
            }

            .print-options {
                @extend .bodyWrap;
                margin: 0px 10px 10px 0px;
            }
        }
    }

    &.reprintInvoice-wrapper {
        background-color: $background_grey !important;
        overflow: auto;
        height: calc(100% - 75px);
        width: unset !important;

        .reprintInvoice-container {
            height: calc(100% - 20px);
            @extend .bodyWrap;
            margin: 10px;
        }
    }

    &.reprintInvoiceAll-wrapper {
        background-color: $background_grey !important;
        overflow: auto;
        width: unset !important;

        .head-sect {
            @extend .bodyWrap;
            margin: 10px;
            width: unset !important;
        }

        .bottom-section {
            @extend .bodyWrap;
            margin: 10px;
            height: calc(100% - 185px);

            .table-sect {
                height: calc(100% - 35px) !important;
            }
        }
    }

    &.view-customer-transaction-wrapper {
        background-color: $background_grey !important;
        padding: 0px !important;
        overflow: hidden;

        .search-container {
            @extend .bodyWrap;
            margin: 10px !important;
            width: unset !important;
        }

        .bottom-container {
            height: calc(100% - 210px);
            @extend .bodyWrap;
            margin: 10px !important;
            padding: 10px !important;

            .table-container {
                height: calc(100% - 140px);
            }
        }
    }

    &.view-customer-maintenance-modal {
        .additional-btn {
            color: $ag_black !important;
        }

        .flex-overflow {
            margin-right: 8px !important;
        }

        .view-customer-maintenance-wrapper {
            background-color: $background_grey !important;
        }

        app-view-profile {
            .view-customer-maintenance-content {
                .banner-section {
                    // background-color: white !important;
                    margin: 10px;
                    // border-radius: 6px;
                    // border: 1px solid $border;
                    // box-shadow: 0px 1px 5px $border;
                    @extend .bodyWrap;
                }
            }
        }

        app-acc-action-bar {
            .action-bar {
                @extend .bodyWrap;
                // background-color: white !important;
                margin: 10px;
                // border-radius: 6px;
                // border: 1px solid $border;
                // box-shadow: 0px 1px 5px $border;
                padding-bottom: 10px !important;
                height: calc(100% - 25px) !important;

                .acc-summary-slider-toggler {
                    left: -20px !important;
                }
            }
        }
    }

    &.transaction-maintenance-wrapper {
        background-color: $background_grey !important;
        overflow: auto;

        app-table-search-header {
            .header-wrapper {
                @extend .bodyWrap;
                width: unset !important;
                margin: 10px !important;
            }
        }

        .advanced-search {
            @extend .bodyWrap;
            margin: 0px 10px 10px 10px;
        }

        .table-container {
            margin: 0px 10px 10px 10px;
            height: calc(100% - 105px) !important;
            padding: 10px;
            @extend .bodyWrap;
        }
    }

    &.create-transaction-maintenance {
        .create-transaction-wrapper {
            background-color: $background_grey !important;
            overflow: auto;
            padding: 0px !important;

            .create-transaction-form-wrapper {
                @extend .bodyWrap;
                margin: 10px !important;
                padding: 10px !important;
            }

            .transaction-details {
                @extend .bodyWrap;
                margin: 0px 10px 10px 10px !important;
                padding: 10px !important;
                height: calc(100% - 285px) !important;

                &.payment-transaction-details {
                    height: calc(100% - 225px) !important;
                }
            }
        }
    }

    &.ar-customer-search-wrapper {
        .mat-dialog-content {
            background-color: $background_grey !important;
            padding: 0px !important;

            .aside-wrapper {
                @extend .bodyWrap;
                padding: 15px;
                margin: 10px 10px 10px 0px;

                .ag_height--100 {
                    height: calc(100% - 50px) !important;
                }
            }

            .customer-list-search {
                height: calc(100% - 20px) !important;
                @extend .bodyWrap;
                padding: 15px;
                margin: 10px 20px 10px 10px;
            }
        }
    }

    &.ar-account-category-wrapper {
        .dialog-content {
            background-color: $background_grey !important;
            height: calc(100% - (4rem + 4rem + 2px)) !important;
            max-height: unset !important;

            .aside-filter-section {
                @extend .bodyWrap;
                margin: 10px !important;
            }

            .category-section {
                @extend .bodyWrap;
                margin: 10px 10px 10px 0px;
            }
        }

        .footer-actions {
            @extend .bodyWrap;
        }
    }

    &.ar-account-misc-wrapper {
        .dialog-content {
            background-color: $background_grey !important;
            height: calc(100% - (4rem + 4rem + 2px)) !important;
            max-height: unset !important;

            .aside-filter-section {
                @extend .bodyWrap;
                margin: 10px !important;
            }

            .category-section {
                @extend .bodyWrap;
                margin: 10px 10px 10px 0px;
            }
        }

        .footer-actions {
            @extend .bodyWrap;
        }
    }

    &.gl-account-search-wrapper {
        .dialog-content {
            background-color: $background_grey !important;
            padding: unset !important;
            height: calc(100% - (4rem + 4rem)) !important;

            .account-list-search {
                @extend .bodyWrap;
                padding: 15px !important;
                margin: 10px 20px 10px 10px !important;
                height: calc(100% - 20px) !important;
            }

            .aside-filter {
                @extend.bodyWrap;
                margin: 10px 10px 10px 0px;
                height: calc(100% - 20px) !important;

                &.hideMenu {
                    width: 286px !important;
                }
            }
        }

    }

    &.ar-invoice-search-wrapper {
        .dialog-content {
            background-color: $background_grey !important;
            padding: 0px !important;

            .ga-filter-aside {
                @extend.bodyWrap;
                margin: 10px 10px 10px 0px;
            }

            .invoice-list-search {
                height: unset !important;
                @extend.bodyWrap;
                margin: 10px 20px 10px 10px;
                padding: 15px;
            }
        }
    }

    &#apply-cc-charges {
        background-color: $background_grey !important;

        #apply-cc-searchForm {
            @extend .bodyWrap;
            margin: 10px;
        }

        .no-data-img {
            @extend .bodyWrap;
            margin: 0px 10px 10px;
            width: unset !important;
        }

        .cc-transactions {
            @extend .bodyWrap;
            margin: 0px 10px 10px;
        }

        .acc-transactions {
            @extend .bodyWrap;
            margin: 0px 10px 10px 0px;
        }

        #apply-cc-searchForm {
            @extend .bodyWrap;
            margin: 10px;
        }
    }

    &.ar-checkpayment-process {
        background-color: $background_grey !important;
        overflow: auto;

        .search--wrapper {
            @extend .bodyWrap;
            margin: 10px;
            width: unset !important;
        }

        .process-search--body {
            height: calc(100% - 70px) !important;

            .filter--wrapper {
                @extend .bodyWrap;
                margin: 0px 10px 10px;
            }
        }

        .no-data-img {
            @extend .bodyWrap;
            margin: 0px 10px 10px 0px;
        }

        .right-body {
            height: calc(100% - 75px) !important;

            .right-body-wrap {
                margin-bottom: 10px !important;

                .middle-sect {
                    @extend .bodyWrap;
                    margin: 0px 10px 10px 0px
                }

                .last-sect {
                    @extend .bodyWrap;
                    margin: 0px 10px 0px 0px
                }
            }

            .ag_footer--actions {
                @extend .bodyWrap;
                width: unset !important;
                margin: 0px 10px 0px 0px;
            }
        }
    }

    &.disputedTransaction-wrapper {
        background-color: $background_grey !important;
        overflow: auto;
        padding: 10px !important;

        #searchForm {
            height: unset !important;

            .search {
                @extend .bodyWrap;
                margin-bottom: 10px;
            }
        }

        .accountTransactionWrapper {
            @extend .bodyWrap;
            padding: 15px;
            height: calc(100% - 200px) !important;
        }
    }

    &.batch-template-wrapper {
        background-color: $background_grey !important;
        overflow: auto;

        app-table-search-header {
            .header-wrapper {
                @extend .bodyWrap;
                width: unset !important;
                margin: 10px !important;
            }
        }

        .advanced-search {
            @extend .bodyWrap;
            margin: 0px 10px 10px 10px;
        }

        .table-container {
            margin: 0px 10px 10px 10px;
            height: calc(100% - 105px) !important;
            padding: 10px;
            @extend .bodyWrap;
        }
    }

    &.create-customer-maintenace-modal {
        .create-content-sect {
            background-color: $background_grey !important;

            mat-tab-group {
                @extend .tab-styling-versiontwo;

                .mat-tab-body-wrapper {
                    .mat-tab-body {
                        margin: unset !important;
                        border: unset !important;
                        box-shadow: unset !important;
                        background: unset !important;
                        border-radius: unset !important;
                    }
                }
            }
        }

        .primary-info {
            margin: 10px !important;

            .account_details,
            .personal_details,
            .contact_details,
            .address_details,
            .account_setting,
            .golf_setting,
            .email_setting {
                @extend .bodyWrap;
                padding: 10px 15px;
                margin-bottom: 10px;
            }
        }

        #secondary-address {
            @extend .bodyWrap;
            padding: 10px 15px;
            margin: 10px;
        }

        .comments-documents {
            @extend .bodyWrap;
            height: calc(100% - 25px) !important;
            padding: 10px 15px;
            margin: 10px;
        }

        .customer-maintenance-footer {
            @extend .footerWrap;
        }
    }

    &.customer-maintenance {
        overflow: hidden;
        background-color: $background_grey !important;

        app-table-search-header {
            .header-wrapper {
                @extend .bodyWrap;
                width: unset !important;
                margin: 10px !important;
            }
        }

        app-customer-maintenance-grid {
            height: calc(100% - 105px) !important;
            @extend .bodyWrap;
            margin: 10px !important;
        }
    }

    &#credit-card-payment {
        background-color: $background_grey !important;

        .account-search {
            @extend .bodyWrap;
            margin: 10px;
        }

        .ag_container--padding-md {
            padding: 0px !important;
        }

        .profile-section {
            @extend .bodyWrap;
            margin: 0px 10px 10px;
            padding: 15px;

            .toggle-profile {
                top: 4px !important;
                right: 8px !important;
            }
        }

        .payment-details,
        .card-info {
            @extend .bodyWrap;
            margin: 0px 10px 10px;
            padding: 15px;

            &.toggle-profile {
                top: 4px !important;
                right: 8px !important;
            }
        }
    }

    &.ar-customer-search-wrapper {
        .mat-dialog-content {
            background-color: $theme_bg_color !important;
            padding: 0px !important;
            max-height: unset !important;

            .aside-filter__header {
                background-color: $theme_bg_color !important;
            }

            .aside-wrapper {
                @extend .bodyWrap;
                padding: 15px;
                margin: 10px 10px 10px 0px;

                .ag_height--100 {
                    height: calc(100% - 50px) !important;
                }
            }

            .customer-list-search {
                height: calc(100% - 20px) !important;
                @extend .bodyWrap;
                padding: 15px;
                margin: 10px;
            }
        }
    }

    &.ContactLog {
        .filters {
            @extend .bodyWrap;
            margin: 10px 10px;
            height: 94% !important;
        }

        .list-container {
            width: calc(100% - 235px) !important;

            app-common-table {
                .page-header {
                    @extend .bodyWrap;
                    margin: 10px 0px !important;
                }
            }

            .fixed-table-container {
                @extend .bodyWrap;
                margin: 10px 0px !important;
                width: unset !important;
                height: calc(100% - 130px) !important;
            }
        }
    }

    &.schedule-popup {
        .topSect {
            @extend .bodyWrap;
            margin: 10px 10px !important;
            padding: 10px;
        }
    }

    &.configure-date-time {
        background-color: $background_grey !important;

        .dateTime_Wrap {
            @extend .bodyWrap;
            margin: 0px 10px 10px 10px !important;
            padding: 1rem 1rem 0rem 1rem !important;
        }
    }

    &.correct-adjustment-posting {
        padding: 0.5rem;

        .bodyWrap {
            padding: 1rem;
            overflow: auto;
            height: 100%;
        }
    }

    &.signature-capture-wrapper {
        .bodyWrap {
            background-color: $ag_white !important;
            border-radius: 6px;
            border: 1px solid $spa_primary_20 !important;
            box-shadow: 0px 1px 5px $spa_primary_20;
        }
    }

    .newVersionWrapper-table {
        height: 100%;

        table {
            width: 100%;

            thead {
                position: sticky;
                top: 0;
                z-index: 1;
                height: 48px;

                tr {
                    background: $spa-table-bg !important;

                    th {
                        padding: 10px 15px;
                        white-space: nowrap;
                        border-bottom: 1px solid $spa-table-border !important;
                        text-align: left;
                        font-size: 14px;
                        font-weight: 700 !important;
                    }
                }
            }

            tbody {
                tr {
                    height: 38px;
                    background: white !important;
                    border-bottom: 1px solid $spa-table-border !important;

                    td {
                        margin-right: 15px;
                        padding: 10px 15px;
                        border-top: none;
                        text-align: left;
                        vertical-align: middle;
                        white-space: nowrap;
                        overflow: hidden;
                        font-size: 14px;
                    }
                }
            }

            i::before {
                color: $ag_base-color;
                font-size: 15px;
            }
        }
    }

    &.view-non-integarted-payments-wrapper {
        background-color: $background_grey;
        padding-top: 10px !important;

        .page-header {
            .float-left {
                float: unset !important
            }

            @extend .bodyWrap;
            height:fit-content !important;
            margin: 10px !important;
            margin-top: 0 !important;
            width: auto !important;
            display: block !important;
        }

        .advanced-search-section {
            @extend .bodyWrap;
            margin: 10px !important;
            width: auto !important;
        }

        .fixed-table-container {
            @extend .bodyWrap;
            margin: 10px !important;
            width: auto !important;
            height: calc(100% - 300px);
            overflow: auto;
            background-color: $theme-table-bg-color !important;

            .fixed-table-container-inner {
                #SPACustomTableShop {
                    background-color: $ag_white !important;
                }
            }
        }

        .item-search {
            app-search-linked-retail-items {
                .mat-form-field-infix {
                    border: 1px solid #ececec;
                }

                .mat-input-element::placeholder {
                    user-select: none;
                    transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1);
                    color: #0000006b !important;
                }
            }
        }


        .result-wrapper {
            .result-grid {
                .user-search {
                    @extend .bodyWrap;
                    margin: 0 10px 0 0 !important;
                    width: auto !important;
                }

                .retailCustomDataTable {
                    margin: 0 10px 0 0 !important;
                    width: auto !important;
                    height: calc(100% - 87px) !important;

                    .page-header {
                        &.removeHeight {
                            display: none !important;
                        }
                    }

                    .fixed-table-container {
                        height: calc(100% - 11px) !important;
                        margin: 0 !important;
                        margin-top: 10px !important;
                        width: auto !important;
                        overflow: hidden;
                        background-color: $theme-table-bg-color !important;

                        table.quicksale-setup-table {
                            background-color: $ag_white !important;
                        }
                    }
                }
            }
        }

        .modify-commission {
            .result-wrapper {
                .filter {
                    @extend .bodyWrap;
                    margin: 10px !important;
                    margin-top: 0 !important;
                    width: auto !important;
                }
            }
        }
    }

    &.inventory_staging--outer-wrapper {
        background-color: $background_grey;
        padding: 0px 10px 0px 0px !important;

        .inventory_staging--top-wrapper {
            @extend .bodyWrap;
            margin: 0px 0px 10px 10px !important;
        }
    }
    &.outlet-wrapper{
        background-color: $background_grey;
        padding: 0 !important;
        height: calc(100% - 40px) !important;
          .top-section{
            @extend .bodyWrap;
              margin: 0 10px 10px 10px !important;
          }
          .custom-search-align{
            @extend .bodyWrap;
            margin: 10px !important;
            width: auto !important;
            padding-top: 15px;
            padding-bottom: 7px;
          }
          .table-container{
            @extend .bodyWrap;
            margin: 0 10px !important;
            width: auto !important;
            padding: 0;
          }
      }
      &.day-pass-wrapper{
        background-color: $background_grey;
        padding: 10px;
        app-table-search-header {
            .header-wrapper {
                @extend .bodyWrap;
                margin-bottom: 10px;
            }
        }
        .table-height{
            @extend .bodyWrap;
            
        }
    }
    &.create-day-pass-management-wrapper{
        .dialog-container__content{
            background-color: $background_grey;
            .mat-stepper-horizontal{
                background-color: $background_grey;
                .mat-horizontal-stepper-header-container{
                    background-color: $ag-white !important;
                    margin: 0px !important;
                }
                .mat-horizontal-content-container {
                    @extend .bodyWrap;
                    margin-top: 10px !important;
                    height: calc(100% - 55px) !important;
                }
            }
        }
    }
    &.therapist-log-popup-wrapper{
        .contact-log-wrapper{
            .list-container .fixed-table-container{
                height: calc(100% - 107px) !important;
              }
        }
    }
    &.apply-discount-wrapper .custom-amountvalue
    {
        &.mat-form-field.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-appearance-legacy .mat-form-field-infix {
            border: 1px solid grey;
            border-radius: 4px;
            padding: 8px;
            height: 34px;
        }    
    }
}