/* You can add global styles to this file, and also import other style files */
@import '../assets/sass/main.scss';
@import '../assets/sass/base/typography';
// @import '../assets/icons/style.css';
// @import 'ag-grid-autocomplete-editor/main.css';

html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import '../../../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material';
@import "../../../../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../../../../node_modules/ag-grid-community/src/styles/ag-theme-blue/sass/ag-theme-blue.scss";

#eatec-wrapper {
    .alignRight {
        margin-left: $product-aside-width;
    }
}
@include eatec-set-font($font-creater);
@include product-set-font($eatec-product-fontcreator);

@include product-mat-form-configuration($product-mat-form-field-font-size, $product-padding-flex-config);

// .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
//     background-color: inherit !important;
// }
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
.mat-menu-panel{
    min-height: 0px !important;
    .mat-menu-content{
        padding: 0px !important;
    }
}

button:focus{
    outline: none !important;
}
.mat-tab-group {
    .mat-tab-label{
        height: $product-mat-tab-header-height ;
        opacity: 1 ;
        max-width: $product-mat-tab-header-width;
    }
    .mat-tab-body-wrapper{
        height: calc(100% - #{$product-mat-tab-header-height});
    }
}

/***Mat Menu***/

.mat-menu-content{
    .mat-menu-item{
        height: $product-mat-menu-height;
        line-height: $product-mat-menu-height;
    }
}
.mat-ink-bar{
    border-radius: 2px !important;
    // background-color: $product-mat-min-bar-color !important;
    height: 2.5px !important;
}

// Custom scrollbar 
::-webkit-scrollbar {
    width: 10px;
    background: #f1f1f1;
}

/* Track */
::-webkit-scrollbar-track {
    border: 4px solid transparent;
    background-clip: content-box;   /* THIS IS IMPORTANT */
}

/* Handle */
::-webkit-scrollbar-thumb {
    // border: 1px solid;
    background: #d8d8d8;
}

.mat-dialog-content{
    margin: 0px !important;
    max-height: 69vh !important;
}

.ellipsis-class {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// Rmoving icon from input type number
#eatec-wrapper{
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-div{
    display: table;
  }

  .input-number{
  height: 30px;
  border: 1px solid;
  border-radius: 3px;
  width: 90%;
  padding: 0px 5px;
  line-height: 14px;
  font-size: 14px;
  text-align: right;  
  /* // display: table-cell; hide from eatec touch */
  vertical-align: middle;
}

.mat-checkbox-disabled{
    opacity: 0.5;
}
// .mat-tab-label-active {
//     .mat-tab-label-content {
//         // color: $master-accordian-header-color !important;
//         font-family: $roboto-medium !important;
//     }
// }
.view-messages-section {
    .icon-px_Filter-eatec{
        font-size: $icon-pixel;
        &::before{
            color: $icon-color !important;
        }
    }
    .icon-px_Manage_Row-eatec{
        font-size: $icon-pixel;
        &::before{
            color: $icon-color !important;
        }
    }
}
.dynamic-grid{
    .grid-actions{
        .actions-section{
            .icon-px_Filter-eatec{
                font-size: $icon-pixel;
                &::before{
                    color: $icon-color !important;
                }
            }
            .icon-px_Manage_Column-eatec{
                font-size: $icon-pixel;
                &::before{
                    color: $icon-color !important;
                }
            }
            .icon-px_Manage_Row-eatec{
                font-size: $icon-pixel;
                &::before{
                    color: $icon-color !important;
                }
            }
        }
    }
}

.multi-dropdown-checkbox{
    line-height: 3em;
    height: 3em;
    label{
        margin: 0;
    }
}

.icon-px_Edit-eatec{
    font-size: $icon-pixel;
    &::before{
        color: $icon-color !important;
    }
}
.icon-px_Filter1-eatec{
    font-size: $icon-pixel;
    &::before{
        color: $icon-color !important;
    }
}

    .agilysys-lib-text-area {
        .mat-input-element {
            min-height: 45px;
        }
    }


    // .toggle-indication {
    //     i::after {
    //         font-family: $roboto-regular;
    //     }
    // }

.clear-both {
    clear: both;
}

.detail-expand-data {
    margin-top: 64px !important;
    height: calc(100% - 64px) !important;
    .mat-dialog-container {
        box-shadow: 0px 3px 12px #00000029 !important;
    }
}
.layout-container{
.main-section{
.agilysys-lib-dynamicform{
    .agilysys-form-fields{
        &.agilysys-col-size-agy-md-2{
            width: calc(100%  / 2) !important;
            max-width: $agilysys-form-field-max-width * 2 !important;
        }
        &.agilysys-col-size-agy-md-1{
            width: calc(100%  / 1) !important;
            max-width: $agilysys-form-field-max-width * 1 !important;
            .mat-form-field{
                width: calc(100%  / 1) !important; 
            }
        }
    }
}
.Rooms{
    .agilysys-lib-dynamicform{
        .Capacity1, .Capacity2, .Capacity3, .Capacity4, .Capacity5, .Capacity6, .Area, .Dimensions, .Remarks, .Price{
           visibility: hidden;
        }
    }
}
}
}
.agilysys-lib-dynamicform{
    .agilysys-form-fields{
        &.agilysys-col-size-agy-md-2{
            width: calc(100%  / 2) !important;
            max-width: $agilysys-form-field-max-width * 2 !important;
        }
        &.agilysys-col-size-agy-md-1{
            width: calc(100%  / 1) !important;
            max-width: $agilysys-form-field-max-width * 1 !important;
            .mat-form-field{
                width: calc(100%  / 1) !important; 
            }
        }
    }
}


.autocomplete-USDAItem {
    .header-option {
        .mat-option-text {
            .panel-header-conatiner {
                .panel-header-list {
                    position: relative !important;
                    top: unset;
                    left: unset;
                    width: 25%;
                    &:nth-child(1) {
                        width: 50% !important;
                    }
                }
            }
        }
    }
    .data-container {
        .data-option {
            .mat-option-text {
                .data-list {
                    position: relative !important;
                    top: unset;
                    left: unset;
                    width: 25%;                    
                    font-size: 12px;
                    opacity: 1;
                    &:nth-child(1) {
                        width: 50% !important;
                    }
                    
                }
            }
        }
    }
}
.cdk-overlay-pane{
    .product-header{
        display: none !important;
    }
}


.display-none {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.show-more-option {
    color: #2653E0 !important;
}

.width-height-0-novisible {
    width: 0px !important;
    height: 0px !important;
    visibility: hidden;
}
.bg-red {
    background-color: red;
}
.bg-green {
    background-color: green;
}
.global-error-popup{
    z-index: 9999 !important;
}
.product-popup, .attached-files-popup, .transaction-popup, .data-import-multiple, .manage-cover-popup,
.media-assignment-manual-sales, .misc-charges-popup, .export-argl-popup, .analysis-shopping-pop {
    width: calc(100% - 64px) !important;
    margin-top: $product-header-height !important;
    height: calc(100% - #{$product-header-height}) !important;
    .mat-dialog-container {
        box-shadow: 0px 3px 12px #00000029 !important;
        padding: 0px !important;
    }
  }

  .misc-charges-popup {
    mat-dialog-container {
        app-agilysys-suggestqty-popup {
            .agilysys-suggetsqty-popup{
                .misc-charges {
                    max-height: calc(100% - 100px) !important;
                }
            }
        }
    }
  }

  .rguest-attached-files-popup, .rguest-transaction-popup {
    width: 100% !important;
    margin-top: $product-header-height !important;
    height: calc(100% - #{$product-header-height}) !important;
    .mat-dialog-container {
        box-shadow: 0px 3px 12px #00000029 !important;
        padding: 0px !important;
    }
  }

  body:has(.eatec-container):has(aside):has(.expand) {
    .product-popup, .attached-files-popup, .transaction-popup, .data-import-multiple,
    .media-assignment-manual-sales, .misc-charges-popup, .export-argl-popup, .analysis-shopping-pop {
        width: calc(100% - #{$product-aside-width}) !important;
        }
        .rguest-attached-files-popup, .rguest-transaction-popup { 
            width: 100% !important;
        }
    }
  .expand-icon {
    .icon-Collapse1-eatec {
        font-weight: bold;
        font-size: 23px;
    }
  }
  @include breakpoint(desktopzoom) {
    .product-hamber {
        .icon-path-eatec {
            display: none !important;
        }        
    }
    /**
     Below code is for - Task No - 201623
    */
    .eatec-container:has(.width-height-0-novisible) { 
        #eatec-auth-aside {
            width: 0px !important;
        }
    }
    #eatec-auth-aside {
        width: 64px !important;
    }
    #layout-container {
        width: calc(100% - 64px);
    }
}

.eatec-container {
    aside {
        &:not(.expand) {
            &:hover {
                width: $product-aside-width !important;
                position: absolute;
                z-index: 99999;
                .nav-bar {
                    .menu-displayname {
                        width: 100% !important;
                    }
                }
                .eatec-version {
                    .version-icon {
                        display: none !important;
                    }
                    .product-caption {
                        display: block !important;
                    }
                }
                ~ .layout-container {
                    width: calc(100% - #{$product-aside-width-unexpand});
                    margin-left: $product-aside-width-unexpand;
                }
            }
            &:not(:hover){
                ~ .layout-container {
                    width: calc(100% - #{$product-aside-width-unexpand});
                }  
            }
        }
    }
}

lib-name-with-icon-renderer {
    .display-text {
        span {
            white-space: pre !important;
        }
    }
}

.text-DeliveryRulesLocations, .text-DeliveryRulesSuppliers, .text-DeliveryRulesSupplierSitesAccConfigs {
    .agilysys-lib-textbox-div {
        mat-form-field {
            .mat-form-field-flex {
                background-color: transparent !important;
            }
            .mat-form-field-underline {
                display: none !important;
            }
            input {
                display: none !important;
            }
            .mat-form-field-label-wrapper {
                padding-top: 18px !important;
            }
        }
    }
}
.spinner{
    position: relative;
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    &::before{
      content: "";
      box-sizing: border-box;
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid #ccc;
      border-top-color: #007bff;
      animation: spinner 1s infinite;
    }
  }

  @keyframes spinner {
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }

  .spinner-loader{
    border-radius: 50px;
    padding: 3px;
    border: 2px solid #ccc;
    border-top-color: #007bff;
    animation: spinner-loader 1s infinite;
  }
  @keyframes spinner-loader {
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
}

.no-ellipsis {
    text-overflow: unset !important;
    overflow: unset !important;
    white-space: unset !important;
}
.actions-section .no-filter-action .action-landing .action-buttons .grid-search{
    margin-top: unset !important;
}
.table-cards .cards-controls .sort-filter-section{
    align-items: center !important;
}
lib-no-items-found .no-items{
    height: 70px !important;
}
.master-setup .master-grid .inventory-details .inventory-sub-form-groups .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .grid-type .dynamic-grid .no-items .no-items-content .no-items-icon .noItemImg{
    height: 12vh !important;
}
.master-setup .master-grid .inventory-details .inventory-sub-form-groups .mat-tab-group{
    .mat-tab-header{
        background: unset !important;
    }
}
.reorder-box .mat-checkbox{
    top: 0px !important;
}
.filter-options .filter-vallues-menu .filter-options-value{
    .mat-checkbox-frame{
        border-color: #2e67b7 !important;
        border-radius: 0px !important;
    }
}
.agilysys-button-secondary{
    border: 1px solid #2a5cf7 !important;
    color: #2a5cf7 !important;
    background-color: unset !important;
}
.misc-charges-popup{
    width: calc(100% - 495px)!important;
    height: calc(100% - 267px) !important;
    margin-top: 254px !important;
}
.transaction-search-container .transaction-search-type .mat-form-field{
    margin-top: 0px !important;
}
.copynew-transaction .copynew-transaction-content{
    margin: 0px !important;
}
.copy-table-detail-card .table-detail-header .detail-estimation .menu-info{
    margin: 0.25rem !important;
}
.detail-expand-data {
    margin-top: 188px !important;
    height: calc(100% - 205px) !important;
}
.attached-files-popup{
    width: calc(100% - 250px) !important;
    margin-top: 242px !important;
    height: calc(100% - 250px) !important;
}
.transaction-container .transaction-grid{
    height: calc(100% - 197px) !important;
}
.master-setup .master-grid .inventory-details .inventory-sub-form-groups .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .grid-type .dynamic-grid .title-dropdown{
    .mat-form-field{
        margin-top: 0px !important;
    }
}
.transaction-popup{
    width: calc(100% - 250px) !important;
    margin-top: 243px !important;
    height: calc(100% - 247px) !important;
}
.golf__radio .golf--form-radio-button{
    margin-bottom: 1rem !important;
}
.agilysys-post-po-popup .post-po-popup .table .mat-checkbox{
    top: 0px !important;
    .mat-checkbox-frame{
        border-color: #000000 !important;
    }
    .mat-checkbox-checked.mat-accent .mat-checkbox-background{
        background-color: #2e67b7 !important;
    }
}
.transaction-search-container .transaction-search-input {
    .mat-checkbox-frame{
        border-color: #000000 !important;
    }
    .mat-checkbox.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background{
        background-color: #2e67b7 !important;
    }
}
.agilysys-suggetsqty-popup .suggest-qty-form-section .agilysys-lib-dynamic-form .agilysys-dynamic-form .agilysys-form-fields .Addition div mat-radio-button{
    clear: unset !important;
}
.alternativepar-dropdown{
    margin-left: 1.5rem !important;
}
.actions-section .no-filter-action .action-item-list .action-icon-buttons .dropdown-section .grid-dropdown{
    margin-top: 0px !important;
}
.agilysys-button-tretiary{
    color: #2a5cf7 !important;
}