@import '../base/_color';
@import '../helpers/variables';
@import '../abstracts/variables';

// Color themes
$themes: (
goldtheme: $gold,
bluetheme: $blue
);






// Helper theme mixin
// @param $name: name of the theme (HTML class)
// @param $color: color of the theme
@mixin theme($name, $color, $colortext, $colorbackground, $colorborder, $header-background,$menu-background) {
  .#{$name} {
    .border-color {
      border-color: $colorborder !important;
    }
    .border-left-color{
      border-left-color: $color !important;
    }
    .circle-hover{
      background-color: $lightyellow !important;  /*******NOTE: Change one theme Color base*******/
    }
    .circle-disbled{
      background-color: $lightgrey !important;  /*******NOTE: Change one theme Color base*******/
    }
    .color-onpressed{
      background-color: $darkyellowonpress !important;
    }
    .color-secondary-pressed{
      background-color: $color-secondary-button-pressed;
    }

    .switch2, .switch3 {
      .slider::before {
        background-color: $colorbackground !important;
      }
    }

    .selectedBtn {
      background-color: $colorbackground !important;
    }

    .body-bgcolor {
      background-color: $colorbackground !important;
    }
    .switch-toggle{
    .switch.checked{
      background-color: $colorbackground !important;
    }
  }
 .loader-wrapper{
  .loader-content{
  .spinner{
    circle{
        stroke: $color;
    }
}
  }
 }

    .body-color {
      background: $colorbackground !important;
    }
    .timepicker__header{
      background: $colorbackground !important;
    }
    .clock-face__number{
      span{
        &.active{
          background: $colorbackground !important;
        }
      }
    }
    .timepicker-button{
      color:$colorbackground !important;
    }
    .clock-face__clock-hand{
      background: $colorbackground !important;
      &::before{
        border: 4px solid $colorbackground !important;
      }
      &::after{
        background-color: $colorbackground !important;
      }
    }

    .body-bordercolor {
      border: 1px solid $colorbackground !important;
    }

    .header-background {
      background-color: $header-background !important;
    }

    // .mat-tab-header {
    //   background-color: $menu-background !important;
    // }

    button.hover_css.mat-button:hover {
      background-color: $colorbackground !important;
    }

    .mat-tab-group.mat-primary .mat-ink-bar {
      background-color: $colorbackground !important;
    }
    // button.mat-mini-fab.mat-accent{
    //     background-color: $colorbackground !important;
    // }
    .text-color {
      color: $colortext !important;
    }

    .active-link {
      color: $color !important;
    }

    .header-bar {
      nav {
        li {
          a {
            &:before {
              background: $color !important;
            }
          }
        }
      }
    }

    .color-numbers {
      color: $color !important;
    }
    // .mat-step-icon{
    //     background-color: $colorbackground !important;
    // }
    // .mat-step-label-selected{
    //     color: black !important;
    // }
    .mat-step-label-active {
      color: #818892 !important;

      &.mat-step-label-selected {
        color: #000000 !important;
      }
    }

    .mat-step-header {
      .mat-step-icon-state-edit.mat-step-icon {
        background: #818892;
      }
    }

    .mat-step-header {
      .mat-step-icon-state-number.mat-step-icon.mat-step-icon-selected {
        background-color: $colorbackground !important;
      }
    }

    .ngx-pagination {
      .current {
        background-color: $colorbackground !important;
      }
    }

    .mat-form-field-ripple {
      background-color: $colorbackground !important;
    }

    .mat-form-field-invalid {
      .mat-form-field-ripple {
        background-color: #f44336 !important;
      }
    }

    .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
      background-color: $menu-tertiary-bg-color !important;

      span {
        color: $menu-tertiary-color;
      }
    }

    .ngx-pagination .current, .ngx-pagination a, .ngx-pagination button {
      padding: 10px 15px;
    }
 

    .mat-button-toggle-checked {
      background: $colorbackground;
      color:#fff;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
      background-color: $colorbackground !important;
    }

    .mat-calendar-body-selected {
      background-color: $colorbackground !important;
      color:#fff;
    }
    .mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate{
      background: $colorbackground !important;
    }
    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
      color: $menu-tertiary-color !important;
    }
    lib-reorder-panel{
      .reorder-box .mat-checkbox{
              top: 0 !important;
              label{
                margin-bottom: 0px !important;
              }
            }
            .mat-checkbox.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background{
              background-color: #2e67b7 !important;
            }
            .mat-checkbox-frame{
              border-color: #2e67b7 !important;
              border-radius: 0px !important;
            }
          }
          lib-agilysys-dynamicform{
            .mat-checkbox.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
              background-color: #2e67b7 !important;
            }
            .mat-checkbox-frame{
              border-color: #21242c !important;
            }
            .mat-form-field.mat-focused.mat-primary .mat-select-arrow{
              color: #2e67b7 !important;
            }
            .mat-select-arrow {
              color: #0000008a !important;
            }
            .mat-radio-button.mat-accent .mat-radio-inner-circle{
              background-color: #2e67b7 !important;
            }
            .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
              border-color: unset !important;
            }
            label {
              margin-bottom: 0 !important;
            }
            .mat-form-field-ripple{
              background-color: unset !important;
            }
          }
  }
}
  // Including themes
  // By looping through the $themes map
  @each $key, $value in $themes {
    $color-text: null;
    $color-background: null;
    $color-border: null;
    $header-background: null;
    $menu-background: null;
    $color-theme: null;
    @if $key == "goldtheme" {
      $color-background: $gold;
      $color-text: $gold;
      $color-border: $gold;
      $header-background: $header-bg;
      $menu-background: $goldMenuColor;
      $color-theme:$gold;
    }
    @else if $key == "bluetheme" {
      $color-background: $blue;
      $color-text: $blue;
      $color-border: $blue;
      $header-background: $lightsemiBlack;
      $menu-background: $blueMenuColor;
      $color-theme: $blue;

    }
    @include theme($key, $value, $color-text, $color-background, $color-border, $header-background,$menu-background);
  }

  // @mixin themecolorsss(){
  //   @include themecolor();
  // }




  // @import '../base/_color';
  // $color-text: null;
  // $color-background: null;
  // $color-border: null;
  // $colorscheme: "blue";
  // @mixin theme($name, $color) {
  //     @if $name == "goldtheme" {
  //         $color-background: $gold;
  //         $color-text: $gold;
  //         $color-border: $gold;
  //     }
  //     @else if $name == "bluetheme" {
  //         $color-background: $blue;
  //         $color-text: $blue;
  //         $color-border: $blue;
  //     }
  // }
  // @each $key, $value in $themes {
  //     @include theme($key, $value);
  // }

    // @function themecolor() {
    //   $colorb: null;
    //   @each $key, $value in $themes {
    //     @if $key == "goldtheme" {
    //       @return $gold;
    //   }
    //   @else if $key == "bluetheme" {
    //     @return $blue;
    //   }
    // }
    // }




/*
  ****
  @Define the Media Quires for Tablet, Desktop, Mobile
  @Params Pass the Paramenter
  ****
*/

	$spa-media-queries: (
		tablet: ( breakpoint: '(min-width: 480px) and (max-width: 768px)'),
    mobile: ( breakpoint: '(max-width: 480px)'),
    midLevel: ( breakpoint: '(min-width: 1024px) and (max-width: 1200px)'),
    retailLevel: (breakpoint: '(min-width: 1024px) and (max-width: 1220px)')
  );



  @mixin spa-media-query ($size) {

		@each $item, $value in $spa-media-queries {

			@if $item == $size {

				@if map-get($value, breakpoint) != null {

					@media only screen and #{map-get($value, breakpoint)} {

						@content;

					}

				}

			}

		}

  }
// Width Properties
@for $i from 0 through 10 {
  .ag_w--#{$i * 10} {
      width: #{$i * 10} + "%";
  }
}

// Padding Properties
@for $i from 0 through 8 {
  .ag_p--#{$i} {
      padding: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pl--#{$i} {
      padding-left: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pr--#{$i} {
      padding-right: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pt--#{$i} {
      padding-top: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pb--#{$i} {
      padding-bottom: #{$i * 0.25}rem !important;
  }
}

@for $i from 0 through 8 {
  .ag_m--#{$i} {
      margin: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_ml--#{$i} {
      margin-left: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_mr--#{$i} {
      margin-right: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_mt--#{$i} {
      margin-top: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_mb--#{$i} {
      margin-bottom: #{$i * 0.25}rem !important;
  }
}







