$color-grey: grey;
$color-red: red;    
$color-green: green;
$color-AliceBlue: #f3f4f5;
$color-light-white: #FAFBFC;
$color-blue: #2653E0;
$color-white: white;
$color-white-opacity-2: rgba(256,256,256, 0.2);
$color-black-opacity-7: rgba(0,0,0,.73);
$color-black-opacity-10: rgba(0,0,0,.10);
$color-light-grey: #cccccc;
$color-semi-grey: #f8f8f8;
$color-black: #000;
$alabaster:#F7F7F7;
$ghost: #CDD1D9;
$black-pearl:#1C252E;
$slate-grey: #788090;
$white: #FFFFFF;

/*****Modernization - Color - Codes****/

$neon-blue: #2A5CF7;
$royal-blue: #2652DE;
$persian-blue: #2149C4;
$deep-sky-blue: #00BFFF;
$fire-brick: #B22222;

$black-russian: #21242C;
$black-rock: #32353F;
$steel-grey: #3E414A;
$strom-grey: #3E414A;
$strom-grey-strong: #747885;
$strong-grey-light: #21242CDE;
$light-steel-blue: #2652DE0D;



$ghost:#BCBEC2;
$solitude:#E5E6EA;
$solitude-light: #F3F4F5;
$solitude-dark: #EBEDF0;
$persian-white: #FAFAFA;
$white:#ffffff;
$light-green: #5db53c;
$light-red: #ff4d51;
$light-semi-blue: #8BB2D94D;
$lightblue: #2652de3d;
$solitude-light-grey: #F2F2F2;

/*****New Colors*****/
$ghost-grey: #B2B8C1;
$ghost-grey-one: #6670817B;
$ghost-grey-light: rgb(0,0,0,0.06);

/***Neon Blue Palatee color*****/
$A200-neon-blue: #EAECFF;
$light-orange: #FD9200;
$light-yellow: #FDB900;
$light-greens: #72C107;
$dark-orange: #F75A2B;
$light-brown: #e36963;
$green: #28a36f;
$light-pink: #f0d7d6;
$light-green-color:#cbefde;