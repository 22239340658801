// Define the All colours in this File

$IndigoColour: unquote('#264348'); //  INDIGO COLOR
$PureWhite: unquote('#ffff'); //PureWhite
$DarkOrange: unquote('#d35400'); //Dark Orange
$black: unquote('#264348');
$accent: unquote('#2ecc71');
$silver: unquote('#ecf0f1');
$trueblack: unquote('#264348');
$gold: unquote('#C58D0B');
$blue: unquote('#42DDF5');
$wetapsat: unquote('#34495e');
$lightgrey: unquote('#6f7c8a');
$grey: unquote('grey');
$lightwhite: unquote('#ecf0f1');
$darkgrey: unquote('#34495e');
$lightraygrey: unquote('#f9f7f7');
$lightsemigrey: unquote('#f6f8f9');
$lightsemiBlack: unquote('#566071');
$pureblack: unquote('#000');
$textblack: unquote('#1D1D1D');
$semigrey: unquote('#faf5f5'); //Using Setting Page
$semilightgreyWhite: unquote('#cecece'); //Using Setting page Border
$HexaGrey: unquote('#b6b6b6'); //Using Setting button
$HexaWhite: unquote('#dddddd'); //Using Button-wite
$HexaLightWhite: unquote('#ececec'); //Using Header Light Grey
$StrongGreen: unquote('#008000');
$StrongRed: unquote('#FF0000');
$Green: unquote('#7ACC33');
$Red: unquote('#CC4B33');
$TableBlack: unquote('#3a3a3a');
$Darkgold: unquote('#484848');
$goldMenuColor: unquote('#FAF5F5');
$blueMenuColor: unquote('#FAF5F5');
$secondaryButtonGrey: unquote('#9E9E9E');
$lightyellow: unquote('#D0A33B');
$lightgoldcircle: unquote('#f8f3e5');
$secondaryButtonPressed : unquote('#e7dabc');
$darkyellowonpress: unquote('#AA7A0B');
$toggleBG :  unquote('#C6C6C6');
$dullgrey: unquote('#e0e0e0');
$tertiarygold: unquote('#f8f3e4');
$tertiarygolddark: unquote('#e8dabb');
$lightblack: rgba(0,0,0,.54);

$lightGray: unquote('#D1D2D3');
$lightSilverGrey: unquote('#8E8E8E');
$darkSilver: unquote('#B7B7B7');
$brightGrey: unquote('#EFEFEF');
$lightSeaBlue: unquote('#D5ECFF');
$darkGreen: unquote('#16BA62');
$darkRed: unquote('#E30404');
$mintCream: unquote('#F7FEFF');
$paleYellow: unquote('#FFFABF');
$seaBlue: unquote('#36C9DD');
$lightOrange: unquote('#DDA336');
$lightGreen: unquote('#36DD50');
$whiteSmoke: unquote('#F6F6F6');
$formFieldGray: unquote('#434A54');
$formBackGroundGray: unquote('#E4E5E8');
$safron: unquote('#faf6ec');
$tabTextGray: unquote('#818892');
$color-theme: unquote('#fff');
$whiteOpacity: unquote('#ffffff99');
$grayLight: rgba(204, 204, 204, 0.35);
$appointmentColor: unquote('#545454');
$scheduled: unquote('#4ECDC4');
$checkedout: unquote('#F27935');
$checkedOut-bg: unquote('#f2793533');
$upcoming-bg: rgba(190, 155, 91, 0.2);
$clients: unquote('#19B5FE');
$therapist: unquote('#674172');
$tabBackGroundGray: unquote('#F5F5F5');
$noShow: unquote('#CF000F');
$darkGray: unquote('#4C4C4C');
$border-grey-fullopacity: rgba(112, 112, 112, 1);
$borderGray: rgba(112, 112, 112, 0.2);
$borderlightGray: rgba(112, 112, 112, 0.1);
$border-grey-opacity5: rgba(112, 112, 112, 0.5);
$searchbox-bg: unquote('#5a5a5a');
$grayRadio: unquote('#C9C9C9');
$inputPlaceholderGray: unquote('#DCDCDC');
$recentRowGray: unquote('#DBDBDB');
$nearlyCheckOut: unquote('#EF4917');
$appoinment-actions-gray: unquote('#8188924d');
$appointment-actions-menu: unquote('#e4e5e84d');
$shop-border-bottom: unquote('#81889280');
$white-transparent: unquote('#ffffff73');
$border-shop: unquote('#AAA8A8');
$left-arrow-gray: unquote('#9A9292');
$stepper-color: unquote('#e4e5e880');
$button-split-color: unquote('#e1cea2');
$more-legend: unquote('#6C7A89');
$success-green: unquote('#15a552');
$screen-grey: unquote('#e8e9e9');
$warning-blue: unquote('#a9f3f0');
$Beige: unquote('#F7EFDD ');
/*new style end*/

$linkWater: unquote('#CCD3D7');
/*$gainsboro: unquote('#DCDCDC');*/
$lightSeaGreen: unquote('#adad09');
$neonPink: unquote('#F46BCA');
$ceruleanBlue: unquote('#274BE3');
$floralWhite: unquote('#FFF9EB');
$aliceBlue: unquote('#F4F5F6');
$lavender: unquote('#e7e7ea');
$suvaGrey: unquote('#898989');
$tuna: unquote('#434953');
$color-light-grey: unquote('#e2e2e2');
$logo-gold: unquote('#FFB600');
$box-shadow-opacity: rgba(0,0,0,0.2);
$black-opacity: rgba(0,0,0,0.12);
$black-opacity8: rgba(0,0,0,0.8);
$accordian-opacity: rgba(0,0,0,0.14);
$thbg: rgba(221, 221, 221,0.87);
$black-opacity42: rgba(0,0,0,0.42);
$reports-bgColor:unquote('#fbfbfc');
$snow: unquote('#fcf9f9');
$shop-background: unquote('#f0f0f2');
$resolution-grey:unquote('#808080');
$resolution-black: unquote('#3D3D3D');

$grey-global: unquote('#E5E5E5');
$white-global: unquote('#f0f0f0');
$light-yellow: unquote('#F9F3E6');
$light-grey-global: unquote('#82808061');

$azure:unquote('#F6F7F7');
$very-light-grey:unquote('#c8c8c8');
$shady-grey:unquote('#9e9698');
$light-white: unquote('#fefefe');
$papayawhip: unquote('#fff7ea');
$tick-color: unquote('#aec81f');
$cross-color: unquote('#a73535');
$grey: unquote("#7E7E7E");

$custom-label-color: unquote("#0000008a");
$custom-value-color: unquote("#000000de");

$header-bg: unquote("#2d3236");
