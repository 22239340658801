$nobel: #999999;
$single_select_background: rgba(0, 0, 0, 0.15);
$Zircon: #f4f4f6;
$black-opacity-level2: rgba(0, 0, 0, 0.2);
$black-opacity-level25: rgba(0, 0, 0, 0.25);
$black-opacity-level1: rgba(0, 0, 0, 0.12);
$black-opacity-level3: rgba(0, 0, 0, 0.14);
$formBackGroundGray: unquote("#E4E5E8");
$lightGrey: unquote("#e3e4e8");
$darkGrey: unquote("#818792");
$lightGrey2: unquote("#efefef");
$placeholderGrey: unquote("#cccccc");
$ag_grey: #c6c6c6;
$suvaGrey: #8f8f8f;
$snow: #fbfbfb;
$orange: unquote('#FF6600');

// Colors used in Golf

// Default colors
$black: unquote("#000000");
$white: unquote("#ffffff");
$red: unquote("#ff0000");
$green: unquote("#43A047");
// Theme green color
$theme-green: unquote("#43A047");
$theme-green-light: unquote("#77BB77");
$theme-green-lighter: unquote("#c1ebc1");
$theme-green-lightest: unquote("#DDEEDD");
$theme-green-dark: unquote("#238823");
$brown-light:#e7d2bf;
$brown-dark: #96744D;

// Secondary color
$gray: unquote("#AAAAAA");
$gray-darker: unquote("#444444");
$gray-dark: unquote("#888888");
$gray-light: unquote("#DDDDDD");
$gray-lighter: unquote("#EFEFEF");
$gray-lightest: unquote("#f9f9f9");
$gray-dim: unquote("#E4E5E8");

// Tee time color
$orange-grayish: unquote("#F7E5CC");
$red-grayish: unquote("#E1C6C6");
$red-soft: unquote("#E39595");
$cyan: unquote("#ff0000");
$cyan-light: unquote("#CCEFF7");

$yellow: unquote("#FFFF8D"); // special notes

$black-light: rgba(0, 0, 0, 0.5);
$black-lighter: rgba(0, 0, 0, 0.3);
$black-lightest: rgba(0, 0, 0, 0.15);
$black-very-lightest: rgba(0, 0, 0, 0.06);
$black-dark: rgba(0, 0, 0, 0.75);

$blue-bayoux: unquote("#60777D");



//spa colors
// Define the All colours in this File

$IndigoColour: unquote('#264348'); //  INDIGO COLOR
$PureWhite: unquote('#ffff'); //PureWhite
$DarkOrange: unquote('#d35400'); //Dark Orange
$black: unquote('#000000');
$accent: unquote('#2ecc71');
$silver: unquote('#ecf0f1');
$trueblack: unquote('#000000');
$gold: unquote('#C58D0B');
$blue: unquote('#42DDF5');
$wetapsat: unquote('#34495e');
$lightgrey: unquote('#6f7c8a');
$grey: unquote('grey');
$lightwhite: unquote('#ecf0f1');
$darkgrey: unquote('#34495e');
$lightraygrey: unquote('#f9f7f7');
$lightsemigrey: unquote('#f6f8f9');
$lightsemiBlack: unquote('#566071');
$pureblack: unquote('#000');
$textblack: unquote('#1D1D1D');
$semigrey: unquote('#faf5f5'); //Using Setting Page
$semilightgreyWhite: unquote('#cecece'); //Using Setting page Border
$HexaGrey: unquote('#b6b6b6'); //Using Setting button
$HexaWhite: unquote('#dddddd'); //Using Button-wite
$HexaLightWhite: unquote('#ececec'); //Using Header Light Grey
$StrongGreen: unquote('#008000');
$StrongRed: unquote('#FF0000');
$Green: unquote('#7ACC33');
$Red: unquote('#CC4B33');
$TableBlack: unquote('#3a3a3a');
$Darkgold: unquote('#484848');
$goldMenuColor: unquote('#FAF5F5');
$blueMenuColor: unquote('#FAF5F5');
$secondaryButtonGrey: unquote('#9E9E9E');
$lightyellow: unquote('#D0A33B');
$lightgoldcircle: unquote('#f8f3e5');
$secondaryButtonPressed : unquote('#e7dabc');
$darkyellowonpress: unquote('#AA7A0B');
$toggleBG :  unquote('#C6C6C6');
$dullgrey: unquote('#e0e0e0');
$tertiarygold: unquote('#f8f3e4');
$tertiarygolddark: unquote('#e8dabb');
$lightblack: rgba(0,0,0,.54);

$lightGray: unquote('#D1D2D3');
$lightSilverGrey: unquote('#8E8E8E');
$darkSilver: unquote('#B7B7B7');
$brightGrey: unquote('#EFEFEF');
$lightSeaBlue: unquote('#D5ECFF');
$darkGreen: unquote('#16BA62');
$darkRed: unquote('#E30404');
$mintCream: unquote('#F7FEFF');
$paleYellow: unquote('#FFFABF');
$seaBlue: unquote('#36C9DD');
$lightOrange: unquote('#DDA336');
$lightGreen: unquote('#36DD50');
$whiteSmoke: unquote('#F6F6F6');
$formFieldGray: unquote('#434A54');
$formBackGroundGray: unquote('#E4E5E8');
$tabTextGray: unquote('#818892');
$color-theme: unquote('#fff');
$whiteOpacity: unquote('#ffffff99');
$grayLight: rgba(204, 204, 204, 0.35);
$appointmentColor: unquote('#545454');
$scheduled: unquote('#4ECDC4');
$checkedout: unquote('#F27935');
$checkedOut-bg: unquote('#f2793533');
$upcoming-bg: rgba(190, 155, 91, 0.2);
$clients: unquote('#19B5FE');
$therapist: unquote('#674172');
$tabBackGroundGray: unquote('#F5F5F5');
$noShow: unquote('#CF000F');
$darkGray: unquote('#4C4C4C');
$border-grey-fullopacity: rgba(112, 112, 112, 1);
$borderGray: rgba(112, 112, 112, 0.2);
$borderlightGray: rgba(112, 112, 112, 0.1);
$border-grey-opacity5: rgba(112, 112, 112, 0.5);
$searchbox-bg: unquote('#5a5a5a');
$grayRadio: unquote('#C9C9C9');
$inputPlaceholderGray: unquote('#DCDCDC');
$recentRowGray: unquote('#DBDBDB');
$nearlyCheckOut: unquote('#EF4917');
$appoinment-actions-gray: unquote('#8188924d');
$appointment-actions-menu: unquote('#e4e5e84d');
$shop-border-bottom: unquote('#81889280');
$white-transparent: unquote('#ffffff73');
$border-shop: unquote('#AAA8A8');
$left-arrow-gray: unquote('#9A9292');
$stepper-color: unquote('#e4e5e880');
$button-split-color: unquote('#e1cea2');
$more-legend: unquote('#6C7A89');
$success-green: unquote('#15a552');
$screen-grey: unquote('#e8e9e9');
$warning-blue: unquote('#a9f3f0');
$Beige: unquote('#F7EFDD ');
/*new style end*/

$linkWater: unquote('#CCD3D7');
/*$gainsboro: unquote('#DCDCDC');*/
$lightSeaGreen: unquote('#adad09');
$neonPink: unquote('#F46BCA');
$ceruleanBlue: unquote('#274BE3');
$floralWhite: unquote('#FFF9EB');
$aliceBlue: unquote('#F4F5F6');
$lavender: unquote('#e7e7ea');
$suvaGrey: unquote('#898989');
$tuna: unquote('#434953');
$color-light-grey: unquote('#e2e2e2');
$logo-gold: unquote('#FFB600');
$box-shadow-opacity: rgba(0,0,0,0.2);
$black-opacity: rgba(0,0,0,0.12);
$black-opacity8: rgba(0,0,0,0.8);
$accordian-opacity: rgba(0,0,0,0.14);
$thbg: rgba(221, 221, 221,0.87);
$black-opacity42: rgba(0,0,0,0.42);
$reports-bgColor:unquote('#fbfbfc');
$snow: unquote('#fcf9f9');
$shop-background: unquote('#f0f0f2');
$resolution-grey:unquote('#808080');
$resolution-black: unquote('#3D3D3D');

$grey-global: unquote('#E5E5E5');
$white-global: unquote('#f0f0f0');
$light-yellow: unquote('#F9F3E6');
$light-grey-global: unquote('#82808061');

$azure:unquote('#F6F7F7');
$very-light-grey:unquote('#c8c8c8');
$shady-grey:unquote('#9e9698');
$light-white: unquote('#fefefe');
$papayawhip: unquote('#fff7ea');
$tick-color: unquote('#aec81f');
$cross-color: unquote('#a73535');
$grey: unquote("#7E7E7E");

$custom-label-color: unquote("#0000008a");
$custom-value-color: unquote("#000000de");

// Text Color
$text-color-dark: unquote("#2D3236");

// Report
$reports-bgColor:unquote('#fbfbfc');
$button-split-color: unquote('#e1cea2');
$PureWhite: unquote('#ffff'); //PureWhite


//spa colors
// Define the All colours in this File

$IndigoColour: unquote('#264348'); //  INDIGO COLOR
$PureWhite: unquote('#ffff'); //PureWhite
$DarkOrange: unquote('#d35400'); //Dark Orange
$black: unquote('#000000');
$accent: unquote('#2ecc71');
$silver: unquote('#ecf0f1');
$trueblack: unquote('#000000');
$gold: unquote('#C58D0B');
$blue: unquote('#42DDF5');
$wetapsat: unquote('#34495e');
$lightgrey: unquote('#6f7c8a');
$grey: unquote('grey');
$lightwhite: unquote('#ecf0f1');
$darkgrey: unquote('#34495e');
$lightraygrey: unquote('#f9f7f7');
$lightsemigrey: unquote('#f6f8f9');
$lightsemiBlack: unquote('#566071');
$pureblack: unquote('#000');
$textblack: unquote('#1D1D1D');
$semigrey: unquote('#faf5f5'); //Using Setting Page
$semilightgreyWhite: unquote('#cecece'); //Using Setting page Border
$HexaGrey: unquote('#b6b6b6'); //Using Setting button
$HexaWhite: unquote('#dddddd'); //Using Button-wite
$HexaLightWhite: unquote('#ececec'); //Using Header Light Grey
$StrongGreen: unquote('#008000');
$StrongRed: unquote('#FF0000');
$Green: unquote('#7ACC33');
$Red: unquote('#CC4B33');
$TableBlack: unquote('#3a3a3a');
$Darkgold: unquote('#484848');
$goldMenuColor: unquote('#FAF5F5');
$blueMenuColor: unquote('#FAF5F5');
$secondaryButtonGrey: unquote('#9E9E9E');
$lightyellow: unquote('#D0A33B');
$lightgoldcircle: unquote('#f8f3e5');
$secondaryButtonPressed : unquote('#e7dabc');
$darkyellowonpress: unquote('#AA7A0B');
$toggleBG :  unquote('#C6C6C6');
$dullgrey: unquote('#e0e0e0');
$tertiarygold: unquote('#f8f3e4');
$tertiarygolddark: unquote('#e8dabb');
$lightblack: rgba(0,0,0,.54);

$lightGray: unquote('#D1D2D3');
$lightSilverGrey: unquote('#8E8E8E');
$darkSilver: unquote('#B7B7B7');
$brightGrey: unquote('#EFEFEF');
$lightSeaBlue: unquote('#D5ECFF');
$darkGreen: unquote('#16BA62');
$darkRed: unquote('#E30404');
$mintCream: unquote('#F7FEFF');
$paleYellow: unquote('#FFFABF');
$seaBlue: unquote('#36C9DD');
$lightOrange: unquote('#DDA336');
$lightGreen: unquote('#36DD50');
$whiteSmoke: unquote('#F6F6F6');
$formFieldGray: unquote('#434A54');
$formBackGroundGray: unquote('#E4E5E8');
$tabTextGray: unquote('#818892');
$color-theme: unquote('#fff');
$whiteOpacity: unquote('#ffffff99');
$grayLight: rgba(204, 204, 204, 0.35);
$appointmentColor: unquote('#545454');
$scheduled: unquote('#4ECDC4');
$checkedout: unquote('#F27935');
$checkedOut-bg: unquote('#f2793533');
$upcoming-bg: rgba(190, 155, 91, 0.2);
$clients: unquote('#19B5FE');
$therapist: unquote('#674172');
$tabBackGroundGray: unquote('#F5F5F5');
$noShow: unquote('#CF000F');
$darkGray: unquote('#4C4C4C');
$border-grey-fullopacity: rgba(112, 112, 112, 1);
$borderGray: rgba(112, 112, 112, 0.2);
$borderlightGray: rgba(112, 112, 112, 0.1);
$border-grey-opacity5: rgba(112, 112, 112, 0.5);
$searchbox-bg: unquote('#5a5a5a');
$grayRadio: unquote('#C9C9C9');
$inputPlaceholderGray: unquote('#DCDCDC');
$recentRowGray: unquote('#DBDBDB');
$nearlyCheckOut: unquote('#EF4917');
$appoinment-actions-gray: unquote('#8188924d');
$appointment-actions-menu: unquote('#e4e5e84d');
$shop-border-bottom: unquote('#81889280');
$white-transparent: unquote('#ffffff73');
$border-shop: unquote('#AAA8A8');
$left-arrow-gray: unquote('#9A9292');
$stepper-color: unquote('#e4e5e880');
$button-split-color: unquote('#e1cea2');
$more-legend: unquote('#6C7A89');
$success-green: unquote('#15a552');
$screen-grey: unquote('#e8e9e9');
$warning-blue: unquote('#a9f3f0');
$Beige: unquote('#F7EFDD ');
$light-red: unquote('#ff5b5b');
/*new style end*/

$linkWater: unquote('#CCD3D7');
/*$gainsboro: unquote('#DCDCDC');*/
$lightSeaGreen: unquote('#adad09');
$neonPink: unquote('#F46BCA');
$ceruleanBlue: unquote('#274BE3');
$floralWhite: unquote('#FFF9EB');
$aliceBlue: unquote('#F4F5F6');
$lavender: unquote('#e7e7ea');
$suvaGrey: unquote('#898989');
$tuna: unquote('#434953');
$color-light-grey: unquote('#e2e2e2');
$logo-gold: unquote('#FFB600');
$box-shadow-opacity: rgba(0,0,0,0.2);
$black-opacity: rgba(0,0,0,0.12);
$black-opacity8: rgba(0,0,0,0.8);
$accordian-opacity: rgba(0,0,0,0.14);
$thbg: rgba(221, 221, 221,0.87);
$black-opacity42: rgba(0,0,0,0.42);
$reports-bgColor:unquote('#fbfbfc');
$snow: unquote('#fcf9f9');
$shop-background: unquote('#f0f0f2');
$resolution-grey:unquote('#808080');
$resolution-black: unquote('#3D3D3D');

$grey-global: unquote('#E5E5E5');
$white-global: unquote('#f0f0f0');
$light-yellow: unquote('#F9F3E6');
$light-grey-global: unquote('#82808061');

$azure:unquote('#F6F7F7');
$very-light-grey:unquote('#c8c8c8');
$shady-grey:unquote('#9e9698');
$light-white: unquote('#fefefe');
$papayawhip: unquote('#fff7ea');
$tick-color: unquote('#aec81f');
$cross-color: unquote('#a73535');
$grey: unquote("#7E7E7E");

$custom-label-color: unquote("#0000008a");
$custom-value-color: unquote("#000000de");


/** Dashboard */

$Turquoise:#26ebc0;
$Porcelain:#e0dedc;
$ClearDay:#dafcf4;
$Zorba:#9f9b97;
$Heather: #b4bdc3;

/* Graphical */
$mildGreenDarkRow: unquote('#F1F6F1');
$mildGreenLightRow: unquote('#F5FAF5');


$theme-light-bg: unquote("#F7EFE3");
$theme-dark-bg: unquote("#FFDEA8");
$slategray: unquote("#708090");
$header-bg: unquote("#2d3236");
$theme-highlight-bg: unquote("#ffe5bc");
$theme-grey-bg: unquote("#aaa");
$black-opacity38: rgba(0, 0, 0, 0.38);


// Common Color Variables

$gray-bg: unquote("#b5b5b7");

$gray-darker: unquote("#444444");

$gray-light: unquote("#DDDDDD");

$gray-lighter: unquote("#EFEFEF");

$orange: rgb(222, 137, 20);

$gray-preview-bg: unquote("#f4f4f6");

//Grey

$grey-darkest: rgb(67, 74, 84);

$grey-darker: rgb(129, 136, 146);

$grey-dark: rgb(149, 152, 154);

$grey-medium1: rgb(161, 166, 173);

$grey-medium2: rgb(171, 173, 174);

$grey-light: rgb(195, 195, 195);

$grey-lighter: rgb(228, 229, 232);

$grey-lightest: rgb(244, 244, 245);

$darkGrey: unquote("#818792");

$tabTextGray: unquote("#818892");

$lightest-red: unquote("#fc6c79");

$navy-blue-darkest: rgb(29, 63, 170);

$navy-blue-darker: rgb(77, 123, 189);

$navy-blue: rgb(96, 142, 209);

$navy-blue-light: rgb(186, 219, 231);

$navy-blue-lighter: rgb(212, 224, 242);

$navy-blue-lightest: rgb(232, 239, 248);

$navy-blue-very-darkest: unquote("#0b2a56");

$navy-blue-dark: unquote("#C0C7DD");

$aqua-green: rgba(0, 122, 128, 0.82);

$menu-hover-color: rgba(255, 255, 255, 0.12);

$blue-shade4: #F0F4FA;

$blue-shade3: #E4E9F5;
$header-font-color: rgba(255, 255, 255, 0.6);
$brown-light:#e7d2bf;
$brown-dark: #96744D;

$orange-lightest: rgb(255, 240, 220);
$red-lightest: rgb(255, 225, 219);
$green-lightest: rgb(203, 255, 204);


//menu new style color variables

$menu-black: rgba(46, 46, 46, 1);
$menu-bg-black: rgba(28, 27, 27, 1);
$menu-header-bg-black: rgba(0, 0, 0, 1);
$menu-light-black: rgb(0, 0, 0);
$menu-primary-gold: rgba(242, 173, 0, 1);
$menu-primary-light-gold:  rgba(197, 141, 11, 0.12);
$menu-primary-light-yellow: rgba(221, 189, 117, 1);
$menu-secondary-gold: rgba(241, 228, 199, 1);
$menu-dark-gold: rgba(207, 138, 0, 1);
$menu-light-blue: rgba(241, 242, 243, 1);
$menu-ligter-gold: rgba(248, 241, 227, 0.5);
$menu-tertiary-bg-color: rgba(248, 241, 227, 0.5);
$menu-tertiary-color: #CF8A00;
$red: unquote("#E41500");
$theme-blue-lighttest:unquote("#d4e0f2");
$red-lightest: rgb(255, 225, 219);
$violet-lightest:#f2f4fa;
$dark_shade14 : #E9EDF1;
$grey-light-bg: #e5e9ef;
$venice-lighter: #f6f9ff;
$blue-shade5:  #F9FAFD;
$grey-shade5: #dbe1e8;
$orange-lighter: rgb(221, 136, 0);
$green: unquote("#43A047");
$table-bg-color: #ffffff;
$table-border-color: #000;

//version 2.0

$new_background_grey : #F4F5F6;
$new_border_grey: #AEAEAE;
$new_spa_primary_20 : #EAE2CE;
$new_spa_primary_40 : #D6C59D;
$new_spa_primary_100: #9A6F0B;
$green: unquote("#43A047");
$red: unquote("#ff0000");
$green-shade1: #04ad30;
$new_spa_primary_10 : #F4F0E6;
$discountRate-bg-color : #F7EFE3;

/*Distribution List Variable and colors*/
$new_spa-box-shadow: #D5D8E0CC;
$theme-border: $new_spa_primary_40;
$theme-box-shadow: $new_spa-box-shadow;
$theme-mat-ink-bar: $new_spa_primary_100;
$theme-mat-label-active:  $new_spa_primary_20;
$theme-selected-toggle: $new_spa_primary_10;
$theme-background-color: $new_background_grey;
$theme-modal-header-color: $new_spa_primary_20;

$Pms-lightBlue:#f0f4f6;
$new-theme-secondlevel-menu-active-bg : #9EB5FF;
$grey-shade3 : unquote('#D5D8DE');
$blue-shade15 : rgb(218,234,252);
$new_spa_table-border: #f1e4c7;
$new_spa_table-bg: rgb(241,228,199);







