@import "../helpers/variables";

.product-icon-container {
    height: $product-icon-container-height;
    width: $product-icon-container-width;
    text-align: center;
}

.product-search-icon-container {
    height: $product-icon-search-container;
    width: $product-icon-search-container;
    text-align: center;
}

.product-mat-table {
    .mat-tab-header{
        padding: 0 5px;
    }
    .mat-tab-label {
        // font-family: $roboto-medium !important;
        font-size: $font-16 !important;
        color: $product-mat-label !important;
         min-width: $product-mat-tab-header-width !important;
        // max-width: $product-mat-tab-header-max-width !important;
        max-width: 100% !important;
        padding: 0 15px;
    }
}
.product-custom-mat-form-field {
    .mat-form-field {
        .mat-form-field-wrapper {
            background-color: $product-mat-form-bg-color;
            padding-bottom: 0px !important;
            &:hover {
                border: 1px solid $product-mat-form-search-border-color !important;
                border-radius: 4px;
            }
            &:active {
                border: 1px solid $product-mat-form-search-border-active-color !important;
                border-radius: 4px;
            }
            &:focus {
                border: 2px solid $product-mat-form-search-border-active-color !important;
                border-radius: 4px;
            }
            .mat-form-field-flex {
                padding: 0 0.78em 0 0.78em;
            }
        }
        .mat-form-field-infix {
            padding: 4px 0px 4px 0px !important;
            display: flex !important;
            width: 100%;
            .icon-search-eatec {
                color: $product-mat-form-icon-search-color !important;
                &:active {
                    color: $product-mat-form-icon-search-active-color !important;
                }
            }
        }
        .mat-form-field-outline {
            color: $product-mat-form-outline-color;
            &:hover {
                border: 1px solid $product-mat-form-search-border-color !important;
            }
            .mat-form-field-outline-start {
                border: inherit;
            }
            .mat-form-field-outline-gap {
                border: inherit;
            }
            .mat-form-field-outline-end {
                border: inherit;
            }
        }
        .mat-input-element {
            &.mat-form-field-placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                font-size: $font-14 !important;
                color: $product-mat-form-font-color !important;
                // font-family: $roboto-regular !important;
            }
        }
    }
}

.product-popup-container {
    .product-popup-header {
        height: $product-popup-header-height;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        border-bottom: 1px solid #EBEDF0;
        .product-popup-icon{
            font-size: 10px;
            color: #788090;
            cursor: pointer;
        }
    }
    .product-popup-content {
        padding: 10PX 10px;
        height: calc(100% - #{$product-popup-header-height + $product-popup-footer-height});
        word-break: break-all;
    }
    .product-popup-footer {
        padding: 0 10px;
        height: $product-popup-footer-height;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #EBEDF0;
    }
}

.product-base-link{
    color: $product-link-color !important;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
        color: $product-link-hover-color !important;
    }
    &:active{
        text-decoration: underline;
        color: $product-link-active-color !important;
    }
    &:focus{
        text-decoration: underline;
        color: $product-link-focus-color !important; 
    }
}

.product-accordian{
    .mat-accordion{
       .mat-expansion-panel{
           .mat-expansion-panel-header{
               height: 56px;
               background-color: #F3F4F5;
               .mat-expansion-indicator{
                   &::after{
                       padding: 5px!important;
                       &:hover{
                           color: #2A5CF7 !important;
                       }
                   }
                   &:hover{
                    color: #2A5CF7 !important;
                }
               }
           }
       }
    }
}


.product-custom-dropdown{
    .mat-form-field-wrapper{
        padding-bottom: 0px !important;
        .mat-form-field-flex{
            background:none !important;
            padding-left: 0px !important;
            .mat-form-field-infix{
                border-top: none !important;
            }
            .mat-select-arrow-wrapper{
                transform: translateY(-5%) !important;
                .mat-select-arrow{
                    color: #2A5CF7 !important;
                }
            }
            .mat-select-value{
                width: unset !important;
                max-width: 100% !important;
            }
            .mat-select-value-text{
                color: #2A5CF7 !important;
            }
        }
        .mat-form-field-underline{
            display: none !important;
        }
    }
}

.product-tile-Approved, .product-tile-TransfersIn, .product-tile-TotalSpoilage, .product-tile-ApprovedSpoilage, .product-tile-Completed,
.product-tile-Definite {
    background-color: rgb(170, 217, 105);
}
.product-tile-PendingSubmission, .product-tile-Pending, .product-tile-Tentative{
    background-color: rgb(253, 185, 0);
}
.product-tile-Partial, .product-tile-TransferOut{
    background-color: rgb(253, 146, 0);
}
.product-tile-Cancelled,.product-tile-CancelledSpoilage{
    background-color: rgb(111, 30, 81);
}
.product-tile-Finalized{
    background-color: rgb(158, 117, 251);
}
.product-tile-PendingApproval, .product-tile-TotalTransfers{
    background-color: rgb(253, 185, 0);
}
.product-tile-OnHold,.product-tile-OnHoldSpoilage {
    background-color: rgb(253, 146, 0);
}
.product-tile-Denied,.product-tile-DeniedSpoilage {
    background-color: rgb(247, 90, 43);
}
.product-tile-Depleted {
    background-color: rgb(247, 90, 43);
}
.product-tile-Invoiced {
    background-color: rgb(43, 148, 247);
}
.product-negative-value{
    color: $product-nomath-subtract !important;
}
.product-positive-value{
    color: $product-transaction-form-esttotal-color !important;
}


