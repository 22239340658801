@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?sffuwj');
  src:  url('fonts/icomoon.eot?sffuwj#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?sffuwj') format('truetype'),
    url('fonts/icomoon.woff?sffuwj') format('woff'),
    url('fonts/icomoon.svg?sffuwj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-other-gender:before {
  content: "\ebff";
  color: #9a9a9a;
}
.icon-subtract:before {
  content: "\eb98";
}
.icon-multiple:before {
  content: "\ebcd";
}
.icon-divide:before {
  content: "\ebd6";
}
.icon-add-border:before {
  content: "\ebfe";
}
.icon-Group-140603:before {
  content: "\eba7";
}
.icon-new-legend:before {
  content: "\eb56";
}
.icon-Condo-Info:before {
  content: "\eb5d";
  color: #c0ad7c;
}
.icon-unlink_16px:before {
  content: "\eb5e";
}
.icon-staff-log:before {
  content: "\eb3b";
}
.icon-edit-pencil:before {
  content: "\eb2c";
}
.icon-open-cash-drawer:before {
  content: "\eb2b";
}
.icon-remove-surcharge:before {
  content: "\eb22";
}
.icon-clock:before {
  content: "\eb18";
}
.icon-appointments:before {
  content: "\eb09";
}
.icon-calendar:before {
  content: "\eae9";
}
.icon-drag-drop:before {
  content: "\ead1";
}
.icon-delete:before {
  content: "\eaaf";
}
.icon-edit1:before {
  content: "\eb1b";
}
.icon-comments:before {
  content: "\eda0";
}
.icon-configure:before {
  content: "\ed9e";
}
.icon-reset_new:before {
  content: "\ed9f";
}
.icon-filled-more:before {
  content: "\ed9d";
}
.icon-Collapse-All-1:before {
  content: "\ed9b";
}
.icon-Expand-All-1:before {
  content: "\ed9c";
}
.icon-check-availability:before {
  content: "\ed9a";
}
.icon-check-in:before {
  content: "\ed98";
}
.icon-check-out:before {
  content: "\ed99";
}
.icon-increment_yielding:before {
  content: "\ed96";
}
.icon-decrement_yielding:before {
  content: "\ed97";
}
.icon-Class:before {
  content: "\ed94";
}
.icon-waive_off:before {
  content: "\ed95";
}
.icon-lockers:before {
  content: "\ed93";
}
.icon-Days-in-Advance_DYP:before {
  content: "\ed8b";
}
.icon-Override_DYP:before {
  content: "\ed8c";
}
.icon-Add-inside-circle:before {
  content: "\ed8e";
}
.icon-Minus-inside-circle:before {
  content: "\ed8f";
}
.icon-Stop_availability:before {
  content: "\ed90";
}
.icon-Search-Reports:before {
  content: "\ed91";
}
.icon-all-menu:before {
  content: "\ed92";
}
.icon-Continue_booking_24px:before {
  content: "\ed81";
}
.icon-deposits_24px:before {
  content: "\ed82";
}
.icon-email_24px1:before {
  content: "\ed83";
}
.icon-guest_24px:before {
  content: "\ed84";
}
.icon-Halt-Booking_24px:before {
  content: "\ed85";
}
.icon-instructor_24px:before {
  content: "\ed86";
}
.icon-messages_24px:before {
  content: "\ed87";
}
.icon-day-pass:before {
  content: "\ed88";
}
.icon-paid1_24px:before {
  content: "\ed89";
}
.icon-summary_open_24px:before {
  content: "\ed7d";
}
.icon-summary_close_24px:before {
  content: "\ed7e";
}
.icon-phone_24px:before {
  content: "\ed7f";
}
.icon-email_24px:before {
  content: "\ed80";
}
.icon-inventory_item_eatec:before {
  content: "\ed7c";
}
.icon-retail_item_attached_new:before {
  content: "\ed7a";
}
.icon-linked_appointments_new:before {
  content: "\ed7b";
}
.icon-history:before {
  content: "\ed79";
}
.icon-link_appointments_16px:before {
  content: "\ed77";
}
.icon-retail_item_attached:before {
  content: "\ed78";
}
.icon-bottom_16px:before {
  content: "\ed75";
}
.icon-top_16px:before {
  content: "\ed76";
}
.icon-Member-new:before {
  content: "\ed74";
}
.icon-discount:before {
  content: "\ed73";
}
.icon-Group-123142:before {
  content: "\ed6d";
  color: #318735;
}
.icon-Decrement:before {
  content: "\ed6e";
}
.icon-Increment:before {
  content: "\ed6f";
}
.icon-Flat-Rate:before {
  content: "\ed70";
}
.icon-Tile-View:before {
  content: "\ed71";
}
.icon-List-View:before {
  content: "\ed72";
}
.icon-edit-schedule:before {
  content: "\ed6b";
}
.icon-Group:before {
  content: "\ed6a";
}
.icon-Collapse-All:before {
  content: "\ed61";
}
.icon-Confirm:before {
  content: "\ed62";
}
.icon-Email-New:before {
  content: "\ed63";
}
.icon-Expand-All:before {
  content: "\ed64";
}
.icon-Mobile:before {
  content: "\ed65";
}
.icon-Notes:before {
  content: "\ed66";
}
.icon-Sort-Ascending:before {
  content: "\ed67";
}
.icon-Sort-Descending:before {
  content: "\ed68";
}
.icon-Telephone-Home:before {
  content: "\ed69";
}
.icon-Customer-Activity-eatec:before {
  content: "\eab7";
  color: #788090;
}
.icon-Finalised-eatec:before {
  content: "\eab4";
}
.icon-Deplete-eatec:before {
  content: "\eab2";
  color: #788090;
}
.icon-printer-eatec:before {
  content: "\eaae";
  color: #788090;
}
.icon-Composition-eatec:before {
  content: "\eaaa";
  color: #2652de;
}
.icon-New_Shopping_Cart-eatec:before {
  content: "\eaab";
  color: #788090;
}
.icon-Saved_Shopping_Cart-eatec:before {
  content: "\eaac";
  color: #788090;
}
.icon-SubIngredients-eatec:before {
  content: "\eaad";
  color: #707070;
}
.icon-Alert-eatec:before {
  content: "\eaa9";
  color: #f06600;
}
.icon-Delivery-Rules-eatec:before {
  content: "\eaa8";
  color: #747885;
}
.icon-Depleted_KPI-01-eatec:before {
  content: "\eaa6";
}
.icon-Invoiced_KPI-01-eatec:before {
  content: "\eaa7";
}
.icon-Events_Icon-01-1-eatec:before {
  content: "\eaa5";
}
.icon-Quick-Order-eatec:before {
  content: "\eaa4";
}
.icon-No-Math-Icon-eatec:before {
  content: "\eaa3";
  color: #2652de;
}
.icon-Vendor_latest-eatec:before {
  content: "\eaa1";
  color: #1150e7;
}
.icon-Banner-notification-eatec:before {
  content: "\eaa0";
}
.icon-Expand-stations-eatec:before {
  content: "\ea64";
  color: #788090;
}
.icon-List-Expand-Collapse-eatec:before {
  content: "\ea66";
  color: #788090;
}
.icon-CyclePlan-eatec:before {
  content: "\ea67";
}
.icon-CyclePlan_Calender-eatec:before {
  content: "\ea68";
  color: #788090;
}
.icon-Calender-1-eatec:before {
  content: "\ea6d";
  color: #788090;
}
.icon-Deactivate-CTA-eatec:before {
  content: "\ea81";
  color: #788090;
}
.icon-message-2-icon-eatec:before {
  content: "\ea9e";
}
.icon-read-receipts-icon-eatec:before {
  content: "\ea9f";
  color: #2652de;
}
.icon-Attach-Icon-eatec:before {
  content: "\ea8d";
  color: #2a5cf7;
}
.icon-Compact-View-Icon-eatec:before {
  content: "\ea8e";
  color: #788090;
}
.icon-Copy-Icon-eatec:before {
  content: "\ea8f";
  color: #2652de;
}
.icon-Delete-Icon-eatec:before {
  content: "\ea90";
  color: #2a5cf7;
}
.icon-Edit-Icon-eatec:before {
  content: "\ea91";
  color: #2652de;
}
.icon-Expand-Icon1-eatec:before {
  content: "\ea92";
  color: #2653e0;
}
.icon-Filter-Icon-eatec:before {
  content: "\ea93";
  color: #788090;
}
.icon-High-Importance-Icon-eatec:before {
  content: "\ea94";
  color: #f00;
}
.icon-Message-Notification-Icon-eatec .path1:before {
  content: "\ea95";
  color: rgb(255, 255, 255);
}
.icon-Message-Notification-Icon-eatec .path2:before {
  content: "\ea96";
  margin-left: -0.9599609375em;
  color: rgb(252, 0, 0);
}
.icon-Preference-Icon-eatec:before {
  content: "\ea97";
  color: #4d4d4d;
}
.icon-Read-Icon-eatec:before {
  content: "\ea98";
  color: #2652de;
}
.icon-Read-Receipts-Icon-eatec .path1:before {
  content: "\ea99";
  color: rgb(38, 82, 222);
}
.icon-Read-Receipts-Icon-eatec .path2:before {
  content: "\ea9a";
  margin-left: -1.220703125em;
  color: rgb(38, 82, 222);
}
.icon-Read-Receipts-Icon-eatec .path3:before {
  content: "\ea9b";
  margin-left: -1.220703125em;
  color: rgb(38, 82, 222);
}
.icon-Read-Receipts-Icon-eatec .path4:before {
  content: "\ea9c";
  margin-left: -1.220703125em;
  color: rgb(38, 132, 100);
}
.icon-Unread-Icon-eatec:before {
  content: "\ea9d";
  color: #2652de;
}
.icon-Validation_Sum-eatecmary:before {
  content: "\ea8c";
}
.icon-Action-eatec:before {
  content: "\ea60";
  color: #2653e0;
}
.icon-Activate-eatec .path1:before {
  content: "\ea61";
  color: rgb(114, 193, 7);
}
.icon-Activate-eatec .path2:before {
  content: "\ea62";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Add-Product-eatec:before {
  content: "\ea63";
  color: #2652de;
}
.icon-Analysis-eatec:before {
  content: "\ea65";
  color: #788090;
}
.icon-Collapse-eatec:before {
  content: "\ea69";
  color: #2653e0;
}
.icon-Column-config-eatec:before {
  content: "\ea6a";
  color: #788090;
}
.icon-Column-configuration-eatec:before {
  content: "\ea6b";
  color: #788090;
}
.icon-Copy-as-new-eatec:before {
  content: "\ea6c";
  color: #788090;
}
.icon-Deactivate-eatec .path1:before {
  content: "\ea6e";
  color: rgb(247, 90, 43);
}
.icon-Deactivate-eatec .path2:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Delete-eatec:before {
  content: "\ea70";
  color: #2652de;
}
.icon-edit-eatec:before {
  content: "\ea71";
  color: #788090;
}
.icon-Eye-eatec:before {
  content: "\ea72";
  color: #788090;
}
.icon-Filter-eatec:before {
  content: "\ea73";
  color: #788090;
}
.icon-Grid-View-eatec .path1:before {
  content: "\ea74";
  color: rgb(242, 242, 242);
}
.icon-Grid-View-eatec .path2:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(120, 128, 144);
}
.icon-Grid-View-eatec .path3:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(120, 128, 144);
}
.icon-Grid-View-eatec .path4:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(120, 128, 144);
}
.icon-Grid-View-eatec .path5:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(120, 128, 144);
}
.icon-Grid-View-eatec .path6:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(120, 128, 144);
}
.icon-Grid-View-eatec .path7:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(120, 128, 144);
}
.icon-Grid-View-eatec .path8:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(120, 128, 144);
}
.icon-Grid-View-eatec .path9:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(120, 128, 144);
}
.icon-Grid-View-eatec .path10:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(120, 128, 144);
}
.icon-Hide-eatec:before {
  content: "\ea7e";
  color: #6e6e6e;
}
.icon-HIstory-eatec:before {
  content: "\ea7f";
  color: #788090;
}
.icon-Items-eatec:before {
  content: "\ea80";
  color: #788090;
}
.icon-List-view-eatec:before {
  content: "\ea82";
  color: #788090;
}
.icon-Meal-Settings-eatec:before {
  content: "\ea83";
  color: #788090;
}
.icon-Print-worksheet-eatec:before {
  content: "\ea84";
  color: #788090;
}
.icon-printer1-eatec:before {
  content: "\ea85";
  color: #788090;
}
.icon-Product-Search-eatec:before {
  content: "\ea86";
  color: #788090;
}
.icon-Reset-eatec:before {
  content: "\ea87";
  color: #788090;
}
.icon-Scale-eatec:before {
  content: "\ea88";
  color: #788090;
}
.icon-Summary-eatec:before {
  content: "\ea89";
}
.icon-Total-Value-eatec .path1:before {
  content: "\ea8a";
  color: rgb(38, 82, 222);
  opacity: 0.12;
}
.icon-Total-Value-eatec .path2:before {
  content: "\ea8b";
  margin-left: -1em;
  color: rgb(38, 82, 222);
}
.icon-Suggest-Qty-eatec:before {
  content: "\ea5f";
  color: #788090;
}
.icon-Refresh-eatec:before {
  content: "\ea59";
  color: #2652de;
}
.icon-Import-eatec:before {
  content: "\ea5a";
}
.icon-File-eatec:before {
  content: "\ea5b";
  color: #818181;
}
.icon-Delete1-eatec:before {
  content: "\ea5c";
  color: #2652de;
}
.icon-data-Import-eatec:before {
  content: "\ea5d";
  color: #b14544;
}
.icon-Batch-Import-eatec:before {
  content: "\ea5e";
}
.icon-Collapse1-Icon-eatec:before {
  content: "\ea58";
  color: #788090;
}
.icon-Collapse1-eatec:before {
  content: "\ea57";
}
.icon-Card-View-eatec:before {
  content: "\ea51";
  color: #788090;
}
.icon-Hint-eatec:before {
  content: "\ea52";
  color: #788090;
}
.icon-List-View-eatec:before {
  content: "\ea53";
  color: #788090;
}
.icon-Pricing-eatec:before {
  content: "\ea54";
  color: #788090;
}
.icon-Print-Worksheet-eatec:before {
  content: "\ea55";
  color: #788090;
}
.icon-Scaling-eatec:before {
  content: "\ea56";
  color: #788090;
}
.icon-ItemPar_Undo-eatec:before {
  content: "\ea50";
  color: #788090;
}
.icon-Item-Par-New-eatec:before {
  content: "\ea4d";
}
.icon-ItemPar_Edit-eatec:before {
  content: "\ea4e";
  color: #788090;
}
.icon-Reset_Icon-eatec:before {
  content: "\ea4f";
  color: #788090;
}
.icon-Item_Par-eatec:before {
  content: "\ea48";
}
.icon-Manual-sales-post-eatec:before {
  content: "\ea49";
  color: #788090;
}
.icon-Sales-Forecasting-eatec:before {
  content: "\ea4a";
  color: #788090;
}
.icon-Sales-eatec:before {
  content: "\ea4b";
  color: #b14544;
}
.icon-utilities-eatec:before {
  content: "\ea4c";
  color: #a2845e;
}
.icon-Alert_Icon-eatec:before {
  content: "\ea3f";
  color: #d84a4a;
}
.icon-Approve_Filled-eatec:before {
  content: "\ea40";
  color: #72c107;
}
.icon-Approve_Icon-eatec:before {
  content: "\ea41";
  color: #2652de;
}
.icon-Deny_Filled-eatec:before {
  content: "\ea42";
  color: #f75a2b;
}
.icon-Deny_Icon-eatec:before {
  content: "\ea43";
  color: #2652de;
}
.icon-Hold_Filled-eatec:before {
  content: "\ea44";
  color: #fd9200;
}
.icon-Hold_Icon-eatec:before {
  content: "\ea45";
  color: #2550da;
}
.icon-Import_Icon-eatec:before {
  content: "\ea46";
  color: #95989a;
}
.icon-Remarks_Icon-eatec:before {
  content: "\ea47";
  color: #788090;
}
.icon-Expand1-eatec:before {
  content: "\ea3e";
  color: #788090;
}
.icon-Food-and-beverage-eatec:before {
  content: "\ea39";
  color: #e27626;
}
.icon-Product-eatec:before {
  content: "\ea3a";
  color: #3166f5;
}
.icon-Recipe-eatec:before {
  content: "\ea3b";
  color: #1baf5a;
}
.icon-Retail-eatec:before {
  content: "\ea3c";
  color: #edbd1b;
}
.icon-weight_machine-eatec:before {
  content: "\ea3d";
  color: #788090;
}
.icon-Signout-eatec:before {
  content: "\ea37";
  color: #788090;
}
.icon-help-eatec:before {
  content: "\ea38";
  color: #788090;
}
.icon-Collapse2-eatec:before {
  content: "\ea32";
}
.icon-Dog-eatec:before {
  content: "\ea33";
  color: #788090;
}
.icon-Plowhorse-eatec:before {
  content: "\ea34";
  color: #788090;
}
.icon-Puzzle-eatec:before {
  content: "\ea35";
  color: #788090;
}
.icon-Star-eatec:before {
  content: "\ea36";
  color: #788090;
}
.icon-Expand-eatec:before {
  content: "\ea30";
  color: #788090;
}
.icon-Sort-eatec:before {
  content: "\ea31";
  color: #788090;
}
.icon-Analysis-Icon-eatec:before {
  content: "\ea2b";
  color: #636d7e;
}
.icon-Define-Finished-Good-eatec:before {
  content: "\ea2c";
  color: #788090;
}
.icon-Define-Product-Icon-eatec:before {
  content: "\ea2d";
  color: #788090;
}
.icon-Inventory-Items-Icon-eatec:before {
  content: "\ea2e";
  color: #636d7e;
}
.icon-Manage-Package-Icon-eatec:before {
  content: "\ea2f";
  color: #788090;
}
.icon-px_Account_Activities-eatec:before {
  content: "\ea09";
}
.icon-px_Accounts-eatec:before {
  content: "\ea0a";
}
.icon-px_AP_Credits-eatec:before {
  content: "\ea0b";
}
.icon-px_AP_Vouchers-eatec:before {
  content: "\ea0c";
}
.icon-px_Blanket_Purchase_Order-eatec:before {
  content: "\ea0d";
}
.icon-px_Capital_Expenditure-eatec:before {
  content: "\ea0e";
}
.icon-px_Customers-eatec:before {
  content: "\ea0f";
}
.icon-px_Customers_AR-eatec:before {
  content: "\ea10";
  color: #812312;
}
.icon-px_Inventory-eatec:before {
  content: "\ea11";
  color: #701779;
}
.icon-px_Inventory_List-eatec:before {
  content: "\ea12";
}
.icon-px_My_Data-eatec:before {
  content: "\ea13";
}
.icon-px_Packages-eatec:before {
  content: "\ea14";
}
.icon-px_Purchase_Order-eatec:before {
  content: "\ea15";
}
.icon-px_Purchase_Request-eatec:before {
  content: "\ea16";
}
.icon-px_Receiving-eatec:before {
  content: "\ea17";
}
.icon-px_Reports1-eatec:before {
  content: "\ea18";
}
.icon-px_Requisition-eatec:before {
  content: "\ea19";
}
.icon-px_Retail-eatec:before {
  content: "\ea1a";
  color: #cc9009;
}
.icon-px_SpoilageLoss-eatec:before {
  content: "\ea1b";
}
.icon-px_Standsheets-eatec:before {
  content: "\ea1c";
}
.icon-px_Suppliers-eatec:before {
  content: "\ea1d";
}
.icon-px_Suppliers_AP-eatec:before {
  content: "\ea1e";
  color: #085180;
}
.icon-px_Templates-eatec:before {
  content: "\ea1f";
}
.icon-px_Transfers-eatec:before {
  content: "\ea20";
}
.icon-x_Physical_Inventory-eatec:before {
  content: "\ea21";
}
.icon-px-Product1-eatec:before {
  content: "\ea22";
}
.icon-px_Account_Management-eatec:before {
  content: "\ea23";
  color: #405b71;
}
.icon-px_Approvals-eatec:before {
  content: "\ea24";
  color: #519b51;
}
.icon-px_Inventory_Management-eatec:before {
  content: "\ea25";
  color: #009ae7;
}
.icon-px_Master-eatec:before {
  content: "\ea26";
  color: #5a0d93;
}
.icon-px_Procurement-eatec:before {
  content: "\ea27";
  color: #009c82;
}
.icon-px_Reports-eatec:before {
  content: "\ea28";
  color: #a162a8;
}
.icon-px_Sales--Catering-eatec:before {
  content: "\ea29";
  color: #cc538b;
}
.icon-px_Setup-eatec:before {
  content: "\ea2a";
}
.icon-px_Inventory_Reset-eatec:before {
  content: "\e9ff";
}
.icon-px_Manage_Package-eatec:before {
  content: "\ea00";
}
.icon-px_Manage_Product-eatec:before {
  content: "\ea01";
}
.icon-px_Misc_Invoices-A_P-eatec:before {
  content: "\ea02";
}
.icon-px_Misc_Invoices_A_R-eatec:before {
  content: "\ea03";
}
.icon-px-Product-eatec:before {
  content: "\ea04";
}
.icon-Docx_Download-eatec:before {
  content: "\ea05";
  color: #2d5caa;
}
.icon-Html_Download-eatec:before {
  content: "\ea06";
}
.icon-Pdf_Download-eatec:before {
  content: "\ea07";
  color: #d82e2b;
}
.icon-Xls_Download-eatec:before {
  content: "\ea08";
  color: #007848;
}
.icon-px_Dropship-eatec:before {
  content: "\e9fa";
}
.icon-px-Cancelled-eatec:before {
  content: "\e9fb";
}
.icon-px-Finalised-eatec:before {
  content: "\e9fc";
}
.icon-px-Finalised-01-eatec:before {
  content: "\e9fd";
}
.icon-px-Partial-eatec:before {
  content: "\e9fe";
}
.icon-Back_Order_PO-eatec:before {
  content: "\e9e6";
}
.icon-Back_Order_REQ-eatec:before {
  content: "\e9e7";
}
.icon-Calendar-eatec:before {
  content: "\e9e8";
}
.icon-Cost-eatec:before {
  content: "\e9e9";
}
.icon-Emergency_REQ-eatec:before {
  content: "\e9ea";
}
.icon-Location-eatec:before {
  content: "\e9eb";
}
.icon-Margin-eatec:before {
  content: "\e9ec";
}
.icon-Open_REQ-eatec:before {
  content: "\e9ed";
}
.icon-Pending-PO-eatec:before {
  content: "\e9ee";
}
.icon-PR_Approval-eatec:before {
  content: "\e9ef";
}
.icon-PR_Pending_Approval-eatec:before {
  content: "\e9f1";
}
.icon-Receipts-MA-eatec:before {
  content: "\e9f3";
}
.icon-Total_Revenue-eatec:before {
  content: "\e9f5";
}
.icon-Total_Usage_Cost-eatec:before {
  content: "\e9f7";
}
.icon-px_Logout-eatec:before {
  content: "\e9e5";
  color: #e35c44;
}
.icon-px-Account-Activities-eatec:before {
  content: "\e9c6";
}
.icon-px-AP-Credits-eatec:before {
  content: "\e9c7";
}
.icon-px-AP-Vouchers-eatec:before {
  content: "\e9c8";
}
.icon-px-Blanket-Purchase-Order-eatec:before {
  content: "\e9c9";
}
.icon-px-Capital-Expenditure-eatec:before {
  content: "\e9ca";
}
.icon-px-Customers-eatec:before {
  content: "\e9cb";
}
.icon-px-Inventory-List-eatec:before {
  content: "\e9cc";
}
.icon-px-MyData-eatec:before {
  content: "\e9cd";
}
.icon-px-Packages-eatec:before {
  content: "\e9ce";
}
.icon-px-Purchase-Order-eatec:before {
  content: "\e9cf";
}
.icon-px-Purchase-Request-eatec:before {
  content: "\e9d0";
}
.icon-px-Receiving-eatec:before {
  content: "\e9d1";
}
.icon-px-Reports-eatec:before {
  content: "\e9d2";
}
.icon-px-Requisition-eatec:before {
  content: "\e9d3";
}
.icon-px-Spoilage-Loss-eatec:before {
  content: "\e9d4";
}
.icon-px-Standsheets-eatec:before {
  content: "\e9d5";
}
.icon-px-Suppliers-eatec:before {
  content: "\e9d6";
}
.icon-px-Templates-eatec:before {
  content: "\e9d7";
}
.icon-px-Transfers-eatec:before {
  content: "\e9d8";
}
.icon-px1-eatec:before {
  content: "\e9d9";
}
.icon-px_Arrow-eatec:before {
  content: "\e9da";
}
.icon-px_close-eatec:before {
  content: "\e9db";
}
.icon-px_Copy-eatec:before {
  content: "\e9dc";
}
.icon-px_Delete1-eatec:before {
  content: "\e9dd";
}
.icon-px_Edit2-eatec:before {
  content: "\e9de";
}
.icon-px_Filter2-eatec:before {
  content: "\e9df";
}
.icon-px_info-eatec:before {
  content: "\e9e0";
}
.icon-px_Manage_Column2-eatec:before {
  content: "\e9e1";
}
.icon-px_Manage_Rows1-eatec:before {
  content: "\e9e2";
}
.icon-px_Print1-eatec:before {
  content: "\e9e3";
}
.icon-x-Physical-Inventory-eatec:before {
  content: "\e9e4";
}
.icon-6px_close-eatec:before {
  content: "\e9bd";
}
.icon-px-eatec:before {
  content: "\e9be";
}
.icon-px_Delete-eatec:before {
  content: "\e9bf";
}
.icon-px_Edit1-eatec:before {
  content: "\e9c0";
}
.icon-px_Filter1-eatec:before {
  content: "\e9c1";
}
.icon-px_Manage_Column1-eatec:before {
  content: "\e9c3";
}
.icon-px_Manage_Rows-eatec:before {
  content: "\e9c4";
}
.icon-px_Print-eatec:before {
  content: "\e9c5";
}
.icon-px_arrow-eatec:before {
  content: "\e9bb";
}
.icon-px_Edit-eatec:before {
  content: "\e9bc";
}
.icon-px_Filter-eatec:before {
  content: "\e9b8";
  color: #788090;
}
.icon-px_Manage_Column-eatec:before {
  content: "\e9b9";
  color: #788090;
}
.icon-px_Manage_Row-eatec:before {
  content: "\e9ba";
  color: #788090;
}
.icon-ReportAccounts20x-eatec:before {
  content: "\e9b3";
}
.icon-ReportCustomers_A_R20x-eatec:before {
  content: "\e9b4";
  color: #812312;
}
.icon-ReportInventory20x-eatec:before {
  content: "\e9b5";
  color: #701779;
}
.icon-ReportRetail20x-eatec:before {
  content: "\e9b6";
  color: #cc9009;
}
.icon-ReportSuppliers_A_P20x-eatec:before {
  content: "\e9b7";
  color: #085180;
}
.icon-Setup24x-eatec:before {
  content: "\e9b2";
  color: #ec4500;
}
.icon-PhysicalInventory-eatec:before {
  content: "\e9aa";
}
.icon-Requisition-eatec:before {
  content: "\e9ab";
}
.icon-SpoilageandLoss-eatec:before {
  content: "\e9b0";
}
.icon-Transfer-eatec:before {
  content: "\e9b1";
}
.icon-Group-15971-eatec:before {
  content: "\e9a6";
}
.icon-loss-eatec:before {
  content: "\e9a7";
}
.icon-loss-1-eatec:before {
  content: "\e9a8";
}
.icon-loss-2-eatec:before {
  content: "\e9a9";
}
.icon-Path-3960-eatec:before {
  content: "\e9ac";
}
.icon-Path-3961-eatec:before {
  content: "\e9ad";
}
.icon-Path-3982-eatec:before {
  content: "\e9ae";
}
.icon-Path-3987-eatec:before {
  content: "\e9af";
}
.icon-Reports-eatec:before {
  content: "\e99a";
  color: #a162a8;
}
.icon-ProductManagement-eatec:before {
  content: "\e99b";
  color: #2652de;
}
.icon-AccountManagement-eatec:before {
  content: "\e99c";
  color: #5c5c5c;
}
.icon-InventoryManagement-eatec:before {
  content: "\e99d";
  color: #009ae7;
}
.icon-ProcurementManagement-eatec:before {
  content: "\e99e";
  color: #009c82;
}
.icon-EventManagement-eatec:before {
  content: "\e99f";
  color: #2b4e91;
}
.icon-ExpenseManagement-eatec:before {
  content: "\e9a0";
  color: #3f654f;
}
.icon-ApprovalsIcons-eatec:before {
  content: "\e9a1";
  color: #519b51;
}
.icon-SetUpManagement-eatec .path1:before {
  content: "\e9a2";
  color: rgb(236, 69, 0);
}
.icon-SetUpManagement-eatec .path2:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(208, 63, 9);
}
.icon-Master-eatec:before {
  content: "\e9a4";
  color: #5a0d93;
}
.icon-SalesCateringIcon-eatec:before {
  content: "\e9a5";
  color: #cc538b;
}
.icon-Copy_icon-eatec:before {
  content: "\e991";
  color: #788090;
}
.icon-Inventory_Management_Icon-2-eatec:before {
  content: "\e98f";
}
.icon-Event_Management-eatec:before {
  content: "\e92a";
}
.icon-Account-Management-eatec:before {
  content: "\e932";
}
.icon-Production-Management-eatec:before {
  content: "\e982";
}
.icon-Approvals-eatec:before {
  content: "\e983";
}
.icon-Report-eatec:before {
  content: "\e988";
}
.icon-Expense-Management-eatec:before {
  content: "\e989";
}
.icon-SalesCatering-eatec:before {
  content: "\e98a";
}
.icon-Procurement-eatec:before {
  content: "\e98d";
}
.icon-Setup-eatec:before {
  content: "\e98e";
}
.icon-compact-eatec:before {
  content: "\e985";
  color: #667081;
}
.icon-comfortable-eatec:before {
  content: "\e986";
  color: #667081;
}
.icon-default-eatec:before {
  content: "\e987";
  color: #667081;
}
.icon-down-eatec:before {
  content: "\e92b";
  color: #5663ff;
}
.icon-up-eatec:before {
  content: "\e984";
  color: #5663ff;
}
.icon-bar-code-icon-eatec:before {
  content: "\e97f";
}
.icon-logout-eatec:before {
  content: "\e97d";
}
.icon-offline-eatec:before {
  content: "\e97e";
}
.icon-status-eatec:before {
  content: "\e97c";
}
.icon-requisition-pending-eatec:before {
  content: "\e915";
}
.icon-plus-eatec:before {
  content: "\e978";
}
.icon-add-qty-eatec .path1:before {
  content: "\e979";
  color: rgb(108, 99, 255);
}
.icon-add-qty-eatec .path2:before {
  content: "\e97a";
  margin-left: -1.1923828125em;
  color: rgb(108, 99, 255);
  opacity: 0.0600;
}
.icon-details-eatec:before {
  content: "\e97b";
}
.icon-2-priority-eatec:before {
  content: "\e977";
  color: #ea633a;
}
.icon-sync-all-eatec:before {
  content: "\e975";
  color: #667081;
}
.icon-sync-eatec:before {
  content: "\e976";
  color: #667081;
}
.icon-reveal-password-eatec:before {
  content: "\e973";
}
.icon-hide-password-eatec:before {
  content: "\e974";
}
.icon-Emergency-priority-1-eatec:before {
  content: "\e971";
  color: #ea633a;
}
.icon-Low-priority-eatec:before {
  content: "\e972";
  color: #ffa529;
}
.icon-costunit-icon-eatec:before {
  content: "\e96d";
  color: #5158af;
}
.icon-dropship1-eatec:before {
  content: "\e96e";
  color: #5158af;
}
.icon-flag-eatec:before {
  content: "\e96f";
}
.icon-remarks-eatec:before {
  content: "\e970";
}
.icon-to-location-tilt-eatec:before {
  content: "\e96b";
  color: #ea4d31;
}
.icon-from-location-tilt-eatec:before {
  content: "\e96c";
  color: #27c369;
}
.icon-total-transfer-eatec:before {
  content: "\e96a";
}
.icon-dropship-eatec:before {
  content: "\e969";
  color: #667081;
}
.icon-EYE-eatec:before {
  content: "\e95b";
  color: #707070;
}
.icon-Fav_Eatec-eatec .path1:before {
  content: "\e95c";
  color: rgb(86, 99, 255);
}
.icon-Fav_Eatec-eatec .path2:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fav_Eatec-eatec .path3:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fav_Eatec-eatec .path4:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fav_Eatec-eatec .path5:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fav_Eatec-eatec .path6:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fav_Eatec-eatec .path7:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fav_Eatec-eatec .path8:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fav_Eatec-eatec .path9:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fav_Eatec2-eatec .path1:before {
  content: "\e965";
  color: rgb(86, 99, 255);
}
.icon-Fav_Eatec2-eatec .path2:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fav_Eatec2-eatec .path3:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fav_Eatec2-eatec .path4:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-suggest-quantities-eatec:before {
  content: "\e959";
}
.icon-delete-eatec:before {
  content: "\e95a";
}
.icon-suggest-quantities-1-eatec:before {
  content: "\e958";
}
.icon-barcode-eatec:before {
  content: "\e954";
}
.icon-batch-cancel-eatec:before {
  content: "\e955";
}
.icon-coins-on-hand-eatec:before {
  content: "\e956";
}
.icon-Doc-approval1-eatec:before {
  content: "\e957";
}
.icon-barcode-square-varian-eatect:before {
  content: "\e952";
}
.icon-frown-face-eatec:before {
  content: "\e953";
}
.icon-Forma-1-eatec:before {
  content: "\e94f";
}
.icon-Forma--1-eatec:before {
  content: "\e950";
}
.icon-About-eatec:before {
  content: "\e942";
}
.icon-Doc-approval-eatec:before {
  content: "\e943";
}
.icon-inventory-eatec:before {
  content: "\e944";
}
.icon-load1-eatec:before {
  content: "\e945";
}
.icon-physical-inventory-eatec:before {
  content: "\e946";
}
.icon-PO1-eatec:before {
  content: "\e947";
}
.icon-requisition-finalised-eatec:before {
  content: "\e948";
}
.icon-requisition-eatec:before {
  content: "\e949";
}
.icon-settings-eatec:before {
  content: "\e94a";
}
.icon-Spoilage-eatec:before {
  content: "\e94b";
}
.icon-transfer-in-eatec:before {
  content: "\e94c";
}
.icon-transfer-out-eatec:before {
  content: "\e94d";
}
.icon-transfer-eatec:before {
  content: "\e94e";
}
.icon-database-eatec:before {
  content: "\e93e";
  color: #5158af;
}
.icon-key-eatec:before {
  content: "\e93f";
  color: #5158af;
}
.icon-placeholder-eatec:before {
  content: "\e940";
  color: #5158af;
}
.icon-user-eatec:before {
  content: "\e941";
  color: #5158af;
}
.icon-cancel-eatec:before {
  content: "\e939";
  color: #667081;
}
.icon-edit1-eatec:before {
  content: "\e93a";
  color: #667081;
}
.icon-lock-requisition-eatec:before {
  content: "\e93b";
  color: #667081;
}
.icon-qoh-eatec:before {
  content: "\e93c";
  color: #848c9a;
}
.icon-requested-eatec:before {
  content: "\e93d";
  color: #848c9a;
}
.icon-no-of-items-eatec:before {
  content: "\e933";
  color: #667081;
}
.icon-requisition-approved-eatec:before {
  content: "\e934";
  color: #667081;
}
.icon-sort-eatec:before {
  content: "\e935";
  color: #667081;
}
.icon-transfers-pending-eatec:before {
  content: "\e936";
  color: #667081;
}
.icon-value-eatec:before {
  content: "\e937";
  color: #848c9a;
}
.icon-more-eatec:before {
  content: "\e938";
  color: #667081;
}
.icon-Dashboard-eatec:before {
  content: "\e92e";
}
.icon-Inventory-eatec:before {
  content: "\e92f";
}
.icon-Kitchen-Management-eatec:before {
  content: "\e930";
}
.icon-back-eatec:before {
  content: "\e920";
}
.icon-Eatec-Logo .path1:before {
  content: "\e921";
  color: rgb(71, 144, 205);
}
.icon-Eatec-Logo .path2:before {
  content: "\e922";
  margin-left: -2.1240234375em;
  color: rgb(71, 144, 205);
}
.icon-Eatec-Logo .path3:before {
  content: "\e923";
  margin-left: -2.1240234375em;
  color: rgb(88, 89, 91);
}
.icon-Eatec-Logo .path4:before {
  content: "\e924";
  margin-left: -2.1240234375em;
  color: rgb(88, 89, 91);
}
.icon-Eatec-Logo .path5:before {
  content: "\e925";
  margin-left: -2.1240234375em;
  color: rgb(88, 89, 91);
}
.icon-Eatec-Logo .path6:before {
  content: "\e926";
  margin-left: -2.1240234375em;
  color: rgb(88, 89, 91);
}
.icon-Eatec-Logo .path7:before {
  content: "\e927";
  margin-left: -2.1240234375em;
  color: rgb(88, 89, 91);
}
.icon-Eatec-Logo .path8:before {
  content: "\e928";
  margin-left: -2.1240234375em;
  color: rgb(88, 89, 91);
}
.icon-exclamation-eatec:before {
  content: "\e929";
}
.icon-search-eatec:before {
  content: "\e92c";
}
.icon-shopping-cart-eatec:before {
  content: "\e92d";
}
.icon-add-eatec:before {
  content: "\e900";
}
.icon-apple-black-with-leaf-eatec:before {
  content: "\e901";
}
.icon-bar-code-eatec:before {
  content: "\e902";
}
.icon-cancel1-eatec:before {
  content: "\e903";
}
.icon-database1-eatec:before {
  content: "\e904";
}
.icon-edit11-eatec:before {
  content: "\e905";
}
.icon-filter-eatec:before {
  content: "\e906";
}
.icon-flags-crossed-eatec:before {
  content: "\e907";
}
.icon-from-location-eatec:before {
  content: "\e908";
}
.icon-information-eatec:before {
  content: "\e909";
}
.icon-link-products-eatec:before {
  content: "\e90a";
}
.icon-load-eatec:before {
  content: "\e90b";
}
.icon-locations-eatec:before {
  content: "\e90c";
}
.icon-mail-eatec:before {
  content: "\e90d";
}
.icon-notification-eatec:before {
  content: "\e90e";
}
.icon-password-eatec:before {
  content: "\e90f";
}
.icon-PO-eatec:before {
  content: "\e910";
}
.icon-remove-eatec:before {
  content: "\e911";
}
.icon-requisition-denied-eatec:before {
  content: "\e912";
}
.icon-requisition-finalized-eatec:before {
  content: "\e913";
}
.icon-requisition-on-hold-eatec:before {
  content: "\e914";
}
.icon-send-location-eatec:before {
  content: "\e916";
}
.icon-sort-by-eatec:before {
  content: "\e917";
}
.icon-spoilage--loss-eatec:before {
  content: "\e918";
}
.icon-suggest-quantities1-eatec:before {
  content: "\e919";
}
.icon-tick-eatec:before {
  content: "\e91a";
}
.icon-to-location-eatec:before {
  content: "\e91b";
}
.icon-total-inventory-eatec:before {
  content: "\e91c";
}
.icon-total-stock-value-eatec:before {
  content: "\e91d";
}
.icon-tracking-eatec:before {
  content: "\e91e";
}
.icon-user1-eatec:before {
  content: "\e91f";
}
.icon-sortArrow-eatec:before {
  content: "\e980";
  color: #6b6b6b;
}
.icon-path-eatec:before {
  content: "\e931";
}
.icon-not-active-eatec:before {
  content: "\e951";
}
.icon-password-hide-eatec:before {
  content: "\e981";
}
.icon-password-show-eatec:before {
  content: "\e9c2";
}
.icon-Copy-eatec:before {
  content: "\e98b";
  color: #36475a;
}
.icon-Print-eatec:before {
  content: "\e98c";
  color: #36475a;
}
.icon-Copy1-eatec:before {
  content: "\e990";
  color: #36475a;
}
.icon-NoMathIcon1-eatec:before {
  content: "\e992";
  color: #5663ff;
}
.icon-Requisition-Partial-Icon1-eatec:before {
  content: "\e999";
  color: #fff;
}
.icon-Requisiton-Approved1-eatec:before {
  content: "\e993";
  color: #72c107;
}
.icon-Requisiton-Canceled1-eatec:before {
  content: "\e994";
  color: #6f1e51;
}
.icon-Requisiton-Denied1-eatec:before {
  content: "\e995";
  color: #f75a2b;
}
.icon-Requisiton-Finalized1-eatec:before {
  content: "\e996";
  color: #9e75fb;
}
.icon-Requisiton-Onhold1-eatec:before {
  content: "\e997";
  color: #fd9200;
}
.icon-Requisiton-Pending1-eatec:before {
  content: "\e998";
  color: #fdb900;
}
.icon-x_Integration_Management-eatec:before {
  content: "\e9f0";
}
.icon-Collapse-Icon-eatec:before {
  content: "\e9f2";
}
.icon-Cron_Exp_Icon-eatec:before {
  content: "\e9f4";
}
.icon-Expand-Icon-eatec:before {
  content: "\e9f6";
}
.icon-Flow_Chart_Icon-eatec:before {
  content: "\e9f8";
}
.icon-Start_Run-Icon-eatec:before {
  content: "\e9f9";
}
.icon-Butchery-eatec:before {
  content: "\eaa2";
  color: #621d09;
}
.icon-Add-retail-item:before {
  content: "\eb9e";
}
.icon-location-view:before {
  content: "\eb9c";
}
.icon-current-day-view:before {
  content: "\eb9d";
}
.icon-therapist-view:before {
  content: "\eb9a";
}
.icon-available-therapist-view:before {
  content: "\eb9b";
}
.icon-everi-wallet:before {
  content: "\eb95";
}
.icon-staff-schedule-add:before {
  content: "\eb99";
}
.icon-spa-package:before {
  content: "\eb94";
}
.icon-Ticket-Confirmation:before {
  content: "\eb96";
}
.icon-Jaspersoft:before {
  content: "\eb97";
}
.icon-down-arrow:before {
  content: "\eb91";
}
.icon-Combine-Records:before {
  content: "\eb92";
  color: #f5a31f;
}
.icon-Export-Import:before {
  content: "\eb93";
}
.icon-View-Transactions:before {
  content: "\eb90";
}
.icon-Last:before {
  content: "\eb8e";
}
.icon-Previous:before {
  content: "\eb8f";
}
.icon-disputed_icon:before {
  content: "\eb88";
  color: #f5a31f;
}
.icon-ViewInvoice_Download:before {
  content: "\eb89";
  color: #f5a31f;
}
.icon-import:before {
  content: "\eb8a";
}
.icon-Undo-Archive:before {
  content: "\eb8b";
}
.icon-Archive:before {
  content: "\eb8c";
}
.icon-confirmation:before {
  content: "\eb8d";
}
.icon-retention:before {
  content: "\eb87";
  color: #1d3faa;
}
.icon-folio:before {
  content: "\eb4c";
}
.icon-Accounting:before {
  content: "\eb4d";
}
.icon-appdetails:before {
  content: "\eb4e";
  color: #8e7a1e;
}
.icon-Apply-Credits-Charges:before {
  content: "\eb4f";
  color: #9a6f0b;
}
.icon-apply_amount_icon:before {
  content: "\eb50";
  color: #204672;
}
.icon-AR-Manual-Check:before {
  content: "\eb51";
  color: #9a6f0b;
}
.icon-AR-Trace-Maintenance:before {
  content: "\eb52";
  color: #9a6f0b;
}
.icon-authorize:before {
  content: "\eb53";
}
.icon-auto_apply_balance_icon:before {
  content: "\eb54";
  color: #204672;
}
.icon-breakdowntime:before {
  content: "\eb55";
  color: #555;
}
.icon-clientinformation:before {
  content: "\eb57";
  color: #555;
}
.icon-comments1:before {
  content: "\eb58";
  color: #4e6262;
}
.icon-DefaultFolio:before {
  content: "\eb59";
}
.icon-Disputed-Transactions:before {
  content: "\eb5a";
  color: #9a6f0b;
}
.icon-Down-Arrow:before {
  content: "\eb5b";
}
.icon-Download-Proforma:before {
  content: "\eb5c";
}
.icon-Email:before {
  content: "\eb5f";
}
.icon-Enter-Credit-Card-Payment:before {
  content: "\eb60";
  color: #9a6f0b;
}
.icon-Existing-AR-Account:before {
  content: "\eb61";
  color: #9a6f0b;
}
.icon-feather-filter:before {
  content: "\eb62";
  color: #9a6f0b;
}
.icon-feather-mail:before {
  content: "\eb63";
  color: #555;
}
.icon-feather-phone:before {
  content: "\eb64";
}
.icon-Finance-Charges:before {
  content: "\eb65";
  color: #9a6f0b;
}
.icon-down-arrow1:before {
  content: "\eb66";
}
.icon-up-arrow1:before {
  content: "\eb67";
}
.icon-minus:before {
  content: "\eb68";
}
.icon-link1:before {
  content: "\eb69";
  color: #555;
}
.icon-location1:before {
  content: "\eb6a";
  color: #555;
}
.icon-location2:before {
  content: "\eb6b";
  color: #555;
}
.icon-lock-appointment:before {
  content: "\eb6c";
  color: #555;
}
.icon-Payment-Maintenance:before {
  content: "\eb6d";
  color: #9a6f0b;
}
.icon-plus:before {
  content: "\eb6e";
}
.icon-Post-Batch:before {
  content: "\eb6f";
}
.icon-post-to-folio:before {
  content: "\eb70";
}
.icon-post-charges:before {
  content: "\eb71";
}
.icon-price-tag:before {
  content: "\eb72";
  color: #218296;
}
.icon-Print-Batch:before {
  content: "\eb73";
  color: #204672;
}
.icon-Print-EMV:before {
  content: "\eb74";
  color: #204672;
}
.icon-Print-Invoice:before {
  content: "\eb75";
}
.icon-question:before {
  content: "\eb76";
}
.icon-Receivables:before {
  content: "\eb77";
}
.icon-retention1:before {
  content: "\eb78";
  color: #9a6f0b;
}
.icon-Retrieve-Account-From-PMS:before {
  content: "\eb79";
  color: #9a6f0b;
}
.icon-Reverse-Batch:before {
  content: "\eb7a";
  color: #9a6f0b;
}
.icon-search:before {
  content: "\eb7b";
}
.icon-Service-Charges:before {
  content: "\eb7c";
  color: #9a6f0b;
}
.icon-settings-outline:before {
  content: "\eb7d";
  color: #9a6f0b;
}
.icon-setuptime:before {
  content: "\eb7e";
  color: #555;
}
.icon-hotel-reservation:before {
  content: "\eb7f";
  color: #c48b0d;
  font-size: 15px;
}
.icon-tax-desc:before {
  content: "\eb80";
}
.icon-therapistearnings:before {
  content: "\eb81";
  color: #555;
}
.icon-Transfer-Transactions:before {
  content: "\eb82";
  color: #9a6f0b;
}
.icon-upload:before {
  content: "\eb83";
}
.icon-View-Customer-Transactions:before {
  content: "\eb84";
  color: #9a6f0b;
}
.icon-eye:before {
  content: "\eb85";
}
.icon-View-Invoices:before {
  content: "\eb86";
}
.icon-device:before {
  content: "\eb4b";
}
.icon-Notes_Staff_Reader:before {
  content: "\eb49";
}
.icon-seen:before {
  content: "\eb4a";
  color: #00cd89;
}
.icon-Advanced-Search:before {
  content: "\eb48";
}
.icon-Check-Out:before {
  content: "\eb46";
}
.icon-Checkin_Checkout:before {
  content: "\eb47";
}
.icon-Undo-Check-Out:before {
  content: "\eb42";
}
.icon-Undo-Check-In:before {
  content: "\eb43";
}
.icon-Settle:before {
  content: "\eb44";
}
.icon-Reopen:before {
  content: "\eb45";
}
.icon-assign-therapist:before {
  content: "\eb40";
}
.icon-filter-outline:before {
  content: "\eb41";
}
.icon-dashboard-tick:before {
  content: "\eb3e";
}
.icon-Location-Block:before {
  content: "\eb3f";
}
.icon-export:before {
  content: "\eb34";
}
.icon-policy:before {
  content: "\eb36";
  color: #1d3faa;
}
.icon-retention:before {
  content: "\eb3a";
  color: #1d3faa;
}
.icon-arrow-pointing-to-right:before {
  content: "\eb32";
}
.icon-shop-commission:before {
  content: "\eb2f";
  color: #435444;
}
.icon-shop-servicecharge:before {
  content: "\eb30";
  color: #435444;
}
.icon-shop-gratuity:before {
  content: "\eb31";
  color: #435444;
}
.icon-Download---Grid:before {
  content: "\eb2e";
}
.icon-more:before {
  content: "\eb2d";
}
.icon-E-Form:before {
  content: "\eb2a";
}
.icon-View-E-Form-Fill:before {
  content: "\eb28";
}
.icon-Generate-E-Form-Fill:before {
  content: "\eb29";
}
.icon-Datamagine:before {
  content: "\eb27";
}
.icon-GenerateEform:before {
  content: "\eb25";
}
.icon-ViewEForm:before {
  content: "\eb26";
}
.icon-LostBusiness:before {
  content: "\eb1a";
}
.icon-Female-Sign:before {
  content: "\eb15";
  color: #d92e86;
}
.icon-Male-Sign:before {
  content: "\eb16";
  color: #2daada;
}
.icon-OtherGenders:before {
  content: "\eb24";
  color: #9a9a9a;
}

.icon-Other-Sign:before {
  content: "\ebff";
  color: #9a9a9a;
}

.icon-Member_new:before {
  content: "\eb23";
}
.icon-add-without-commission:before {
  content: "\eb1e";
}
.icon-shop-cart:before {
  content: "\eb1d";
}
.icon-Transaction-Log:before {
  content: "\eb1c";
}
.icon-female-staff:before {
  content: "\eb1f";
}
.icon-male-staff:before {
  content: "\eb20";
}
.icon-enforce-gender:before {
  content: "\eb21";
}
.icon-Female1:before {
  content: "\eb19";
}
.icon-Male1:before {
  content: "\eb17";
}
.icon-sort-arrow:before {
  content: "\eb14";
  color: #6b6b6b;
}
.icon-filled-up-arrow1:before {
  content: "\eb13";
}
.icon-filled-down-arrow:before {
  content: "\eb12";
}
.icon-sphere:before {
  content: "\eb11";
}
.icon-Speciality:before {
  content: "\eb10";
}
.icon-block-user:before {
  content: "\eb0f";
}
.icon-Blocked-Client:before {
  content: "\eb0c";
}
.icon-comp-slip-redemption:before {
  content: "\eb0b";
}
.icon-Preview:before {
  content: "\eb0a";
}
.icon-sync:before {
  content: "\eb08";
  color: #525962;
}
.icon-menu-search:before {
  content: "\eb07";
}
.icon-mail_integration .path1:before {
  content: "\eb01";
  color: rgb(177, 179, 181);
}
.icon-mail_integration .path2:before {
  content: "\eb02";
  margin-left: -1.3671875em;
  color: rgb(177, 179, 181);
}
.icon-mail_integration .path3:before {
  content: "\eb03";
  margin-left: -1.3671875em;
  color: rgb(177, 179, 181);
}
.icon-mail_integration .path4:before {
  content: "\eb04";
  margin-left: -1.3671875em;
  color: rgb(177, 179, 181);
}
.icon-mail_integration .path5:before {
  content: "\eb05";
  margin-left: -1.3671875em;
  color: rgb(45, 204, 112);
}
.icon-mail_integration .path6:before {
  content: "\eb06";
  margin-left: -1.3671875em;
  color: rgb(255, 255, 255);
}
.icon-Intake_Form_legend:before {
  content: "\eaff";
}
.icon-Intake_Form_Action:before {
  content: "\eb00";
}
.icon-active-user:before {
  content: "\eafe";
}
.icon-Menu_search_icon:before {
  content: "\eafd";
}
.icon-external-gift-card:before {
  content: "\eafb";
  /* color: #aaa; */
}
.icon-v1-gift-card:before {
  content: "\eafc";
  /* color: #aaa; */
}
.icon-help_question:before {
  content: "\eafa";
  color: #b1b3b5;
}
.icon-Player-Worth .path1:before {
  content: "\eaf6";
  color: rgb(0, 0, 0);
}
.icon-Player-Worth .path2:before {
  content: "\eaf7";
  margin-left: -0.7998046875em;
  color: rgb(255, 255, 255);
}
.icon-Player-Worth .path3:before {
  content: "\eaf8";
  margin-left: -0.7998046875em;
  color: rgb(255, 255, 255);
}
.icon-Player-Worth .path4:before {
  content: "\eaf9";
  margin-left: -0.7998046875em;
  color: rgb(255, 255, 255);
}
.icon-patron:before {
  content: "\eaf5";
}
.icon-Audit:before {
  content: "\eb39";
}
.icon-settings:before {
  content: "\eb38";
}
.icon-reports:before {
  content: "\eb37";
}
.icon-guest:before {
  content: "\eb35";
}
.icon-home:before {
  content: "\eb33";
}
.icon-Filter_open .path1:before {
  content: "\eaee";
  color: rgb(197, 141, 11);
}
.icon-Filter_open .path2:before {
  content: "\eaef";
  margin-left: -0.7001953125em;
  color: none;
}
.icon-Filter_open .path3:before {
  content: "\eaf0";
  margin-left: -0.7001953125em;
  color: rgb(255, 255, 255);
}
.icon-Filter_Close .path1:before {
  content: "\eaf1";
  color: rgb(197, 141, 11);
}
.icon-Filter_Close .path2:before {
  content: "\eaf2";
  margin-left: -0.7001953125em;
  color: none;
}
.icon-Filter_Close .path3:before {
  content: "\eaf3";
  margin-left: -0.7001953125em;
  color: rgb(255, 255, 255);
}
.icon-Filter_Close .path4:before {
  content: "\eaf4";
  margin-left: -0.7001953125em;
  color: rgb(255, 255, 255);
}
.icon-pay-card:before {
  content: "\ecac";
}
.icon-pay-cash:before {
  content: "\ecad";
}
.icon-pay-coupons:before {
  content: "\ecae";
}
.icon-pay-gift-card:before {
  content: "\ecaf";
}
.icon-pay-others:before {
  content: "\ecb0";
}
.icon-pay-pending-settlement:before {
  content: "\ecb1";
}
.icon-pay-points:before {
  content: "\ecb2";
}
.icon-pay-room-charge:before {
  content: "\ecb3";
}
.icon-JCB .path1:before {
  content: "\eab0";
  color: rgb(15, 84, 157);
}
.icon-JCB .path2:before {
  content: "\ec10";
  margin-left: -1.453125em;
  color: rgb(180, 31, 54);
}
.icon-JCB .path3:before {
  content: "\ec11";
  margin-left: -1.453125em;
  color: rgb(50, 153, 71);
}
.icon-JCB .path4:before {
  content: "\ec12";
  margin-left: -1.453125em;
  color: rgb(243, 242, 242);
}
.icon-JCB .path5:before {
  content: "\ec13";
  margin-left: -1.453125em;
  color: rgb(243, 242, 242);
}
.icon-JCB .path6:before {
  content: "\ec14";
  margin-left: -1.453125em;
  color: rgb(243, 242, 242);
}
.icon-AMEX .path1:before {
  content: "\eab1";
  color: rgb(48, 111, 197);
}
.icon-AMEX .path2:before {
  content: "\ec15";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path3:before {
  content: "\ec16";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path4:before {
  content: "\ec17";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path5:before {
  content: "\ec18";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path6:before {
  content: "\ec19";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path7:before {
  content: "\ec1a";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path8:before {
  content: "\ec1b";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path9:before {
  content: "\ec1c";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path10:before {
  content: "\ec1d";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-Unkown-Card .path1:before {
  content: "\eab3";
  color: rgb(129, 136, 146);
}
.icon-Unkown-Card .path2:before {
  content: "\ec1e";
  margin-left: -1.49609375em;
  color: rgb(116, 109, 109);
}
.icon-Maestro .path1:before {
  content: "\eab5";
  color: rgb(108, 107, 189);
}
.icon-Maestro .path2:before {
  content: "\ec1f";
  margin-left: -1.2880859375em;
  color: rgb(211, 32, 17);
}
.icon-Maestro .path3:before {
  content: "\ec20";
  margin-left: -1.2880859375em;
  color: rgb(0, 153, 223);
}
.icon-Maestro .path4:before {
  content: "\ec21";
  margin-left: -1.2880859375em;
  color: rgb(17, 15, 13);
}
.icon-Interac .path1:before {
  content: "\eab6";
  color: rgb(255, 202, 5);
}
.icon-Interac .path2:before {
  content: "\ec22";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path3:before {
  content: "\ec23";
  margin-left: -0.994140625em;
  color: rgb(255, 202, 5);
}
.icon-Interac .path4:before {
  content: "\ec24";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path5:before {
  content: "\ec25";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path6:before {
  content: "\ec26";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path7:before {
  content: "\ec27";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path8:before {
  content: "\ec28";
  margin-left: -0.994140625em;
  color: rgb(255, 255, 255);
}
.icon-Interac .path9:before {
  content: "\ec29";
  margin-left: -0.994140625em;
  color: rgb(255, 255, 255);
}
.icon-Interac .path10:before {
  content: "\ec2a";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path11:before {
  content: "\ec2b";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path12:before {
  content: "\ec2c";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path13:before {
  content: "\ec2d";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path14:before {
  content: "\ec2e";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path15:before {
  content: "\ec2f";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path16:before {
  content: "\ec30";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path1:before {
  content: "\eab8";
  color: rgb(255, 255, 255);
}
.icon-Discover .path2:before {
  content: "\ec31";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path3:before {
  content: "\ec32";
  margin-left: -1.5634765625em;
  color: rgb(245, 130, 32);
}
.icon-Discover .path4:before {
  content: "\ec33";
  margin-left: -1.5634765625em;
  color: rgb(0, 0, 0);
}
.icon-Discover .path5:before {
  content: "\ec34";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path6:before {
  content: "\ec35";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path7:before {
  content: "\ec36";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path8:before {
  content: "\ec37";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path9:before {
  content: "\ec38";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path10:before {
  content: "\ec39";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path11:before {
  content: "\ec3a";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path12:before {
  content: "\ec3b";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path13:before {
  content: "\ec3c";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path14:before {
  content: "\ec3d";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path15:before {
  content: "\ec3e";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path16:before {
  content: "\ec3f";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path17:before {
  content: "\ec40";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path18:before {
  content: "\ec41";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path19:before {
  content: "\ec42";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path20:before {
  content: "\ec43";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path21:before {
  content: "\ec44";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path22:before {
  content: "\ec45";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path23:before {
  content: "\ec46";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path24:before {
  content: "\ec47";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path25:before {
  content: "\ec48";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path26:before {
  content: "\ec49";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path27:before {
  content: "\ec4a";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path28:before {
  content: "\ec4b";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path29:before {
  content: "\ec4c";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path1:before {
  content: "\eab9";
  color: rgb(0, 74, 152);
}
.icon-Diners-Club .path2:before {
  content: "\ec4d";
  margin-left: -1.44921875em;
  color: rgb(255, 255, 255);
}
.icon-Diners-Club .path3:before {
  content: "\ec4e";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path4:before {
  content: "\ec4f";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path5:before {
  content: "\ec50";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path6:before {
  content: "\ec51";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path7:before {
  content: "\ec52";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path8:before {
  content: "\ec53";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path9:before {
  content: "\ec54";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path10:before {
  content: "\ec55";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path11:before {
  content: "\ec56";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path12:before {
  content: "\ec57";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path13:before {
  content: "\ec58";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path14:before {
  content: "\ec59";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path15:before {
  content: "\ec5a";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path16:before {
  content: "\ec5b";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path17:before {
  content: "\ec5c";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path18:before {
  content: "\ec5d";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path19:before {
  content: "\ec5e";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path20:before {
  content: "\ec5f";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path21:before {
  content: "\ec60";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path22:before {
  content: "\ec61";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path23:before {
  content: "\ec62";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path24:before {
  content: "\ec63";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path25:before {
  content: "\ec64";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path26:before {
  content: "\ec65";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path27:before {
  content: "\ec66";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path28:before {
  content: "\ec67";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path29:before {
  content: "\ec68";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path30:before {
  content: "\ec69";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path31:before {
  content: "\ec6a";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path32:before {
  content: "\ec6b";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path33:before {
  content: "\ec6c";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path34:before {
  content: "\ec6d";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path35:before {
  content: "\ec6e";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path36:before {
  content: "\ec6f";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path37:before {
  content: "\ec70";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path38:before {
  content: "\ec71";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path39:before {
  content: "\ec72";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-China-UnionPay .path1:before {
  content: "\ead4";
  color: rgb(221, 2, 40);
}
.icon-China-UnionPay .path2:before {
  content: "\ead5";
  margin-left: -1.5595703125em;
  color: rgb(2, 67, 129);
}
.icon-China-UnionPay .path3:before {
  content: "\ead6";
  margin-left: -1.5595703125em;
  color: rgb(1, 121, 138);
}
.icon-China-UnionPay .path4:before {
  content: "\ead7";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path5:before {
  content: "\ead8";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path6:before {
  content: "\ead9";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path7:before {
  content: "\eada";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path8:before {
  content: "\eadb";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path9:before {
  content: "\eadc";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path10:before {
  content: "\eadd";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path11:before {
  content: "\eade";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path12:before {
  content: "\eadf";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path13:before {
  content: "\eae0";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path14:before {
  content: "\eae1";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path15:before {
  content: "\eae2";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path16:before {
  content: "\eae3";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path17:before {
  content: "\eae4";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path18:before {
  content: "\eae5";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path19:before {
  content: "\eae6";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-hsbc .path1:before {
  content: "\eaba";
  color: rgb(0, 0, 0);
}
.icon-hsbc .path2:before {
  content: "\ec73";
  margin-left: -5.662109375em;
  color: rgb(0, 0, 0);
}
.icon-hsbc .path3:before {
  content: "\ec74";
  margin-left: -5.662109375em;
  color: rgb(0, 0, 0);
}
.icon-hsbc .path4:before {
  content: "\ec75";
  margin-left: -5.662109375em;
  color: rgb(0, 0, 0);
}
.icon-hsbc .path5:before {
  content: "\ec76";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-hsbc .path6:before {
  content: "\ec77";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-hsbc .path7:before {
  content: "\ec78";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-hsbc .path8:before {
  content: "\ec79";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-visa .path1:before {
  content: "\eabb";
  color: rgb(0, 88, 160);
}
.icon-visa .path2:before {
  content: "\ec7a";
  margin-left: -1.5009765625em;
  color: rgb(255, 255, 255);
}
.icon-visa .path3:before {
  content: "\ec7b";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa .path4:before {
  content: "\ec7c";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa .path5:before {
  content: "\ec7d";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa .path6:before {
  content: "\ec7e";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa .path7:before {
  content: "\ec7f";
  margin-left: -1.5009765625em;
  color: rgb(250, 166, 26);
}
.icon-master-card .path1:before {
  content: "\eabc";
  color: rgb(252, 177, 49);
}
.icon-master-card .path2:before {
  content: "\ec80";
  margin-left: -1.6611328125em;
  color: rgb(252, 177, 49);
}
.icon-master-card .path3:before {
  content: "\ec81";
  margin-left: -1.6611328125em;
  color: rgb(237, 27, 46);
}
.icon-master-card .path4:before {
  content: "\ec82";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path5:before {
  content: "\ec83";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path6:before {
  content: "\ec84";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path7:before {
  content: "\ec85";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path8:before {
  content: "\ec86";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path9:before {
  content: "\ec87";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path10:before {
  content: "\ec88";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path11:before {
  content: "\ec89";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path12:before {
  content: "\ec8a";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path13:before {
  content: "\ec8b";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path14:before {
  content: "\ec8c";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path15:before {
  content: "\ec8d";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path16:before {
  content: "\ec8e";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path17:before {
  content: "\ec8f";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path18:before {
  content: "\ec90";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path19:before {
  content: "\ec91";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path20:before {
  content: "\ec92";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path21:before {
  content: "\ec93";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path22:before {
  content: "\ec94";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-advance-search:before {
  content: "\eabd";
}
.icon-advanced-search:before {
  content: "\eabe";
}
.icon-dashboard-left-arrow:before {
  content: "\eabf";
}
.icon-dashboard-right-arrow:before {
  content: "\eac0";
}
.icon-Device:before {
  content: "\eac1";
}
.icon-cardType:before {
  content: "\eac2";
}
.icon-left-arrow1:before {
  content: "\eac3";
}
.icon-right-arrow1:before {
  content: "\eac4";
}
.icon-Plus:before {
  content: "\eac5";
}
.icon-Minus:before {
  content: "\eac6";
}
.icon-percent:before {
  content: "\eac7";
}
.icon-emptyshop .path1:before {
  content: "\eac8";
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path2:before {
  content: "\ec95";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path3:before {
  content: "\ec96";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path4:before {
  content: "\ec97";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path5:before {
  content: "\ec98";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path6:before {
  content: "\ec99";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path7:before {
  content: "\ec9a";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path8:before {
  content: "\ec9b";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path9:before {
  content: "\ec9c";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path10:before {
  content: "\ec9d";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path11:before {
  content: "\ec9e";
  margin-left: -1.494140625em;
  color: rgb(255, 255, 255);
}
.icon-emptyshop .path12:before {
  content: "\ec9f";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path13:before {
  content: "\eca0";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path14:before {
  content: "\eca1";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path1:before {
  content: "\eac9";
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path2:before {
  content: "\eca2";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path3:before {
  content: "\eca3";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path4:before {
  content: "\eca4";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path5:before {
  content: "\eca5";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path6:before {
  content: "\eca6";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path7:before {
  content: "\eca7";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path8:before {
  content: "\eca8";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path9:before {
  content: "\eca9";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path10:before {
  content: "\ecaa";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path11:before {
  content: "\ecab";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Appointment:before {
  content: "\eaca";
}
.icon-audit:before {
  content: "\eacb";
}
.icon-shop:before {
  content: "\eacc";
}
.icon-location-login:before {
  content: "\eacd";
}
.icon-Search:before {
  content: "\eace";
}
.icon-Phone:before {
  content: "\eacf";
}
.icon-Scheduled:before {
  content: "\ead0";
}
.icon-Others:before {
  content: "\ead2";
}
.icon-mail:before {
  content: "\ead3";
}
.icon-Cash:before {
  content: "\eae7";
}
.icon-Card:before {
  content: "\eae8";
}
.icon-Cheque:before {
  content: "\eaea";
}
.icon-GiftCard:before {
  content: "\eaeb";
}
.icon-Bell:before {
  content: "\eaec";
}
.icon-Cancel:before {
  content: "\eaed";
}
.icon-Coins:before {
  content: "\eb0d";
}
.icon-SuccessDark:before {
  content: "\eb0e";
}
.icon-confirmed:before {
  content: "\eb3c";
}
.icon-Down-Arrow:before {
  content: "\eb3d";
}
.icon-Up-Arrow:before {
  content: "\eb9f";
}
.icon-up-arrow:before {
  content: "\eba0";
}
.icon-More:before {
  content: "\eba1";
}
.icon-mug:before {
  content: "\eba2";
}
.icon-redo:before {
  content: "\eba3";
}
.icon-Group-197:before {
  content: "\eba4";
}
.icon-Group-198:before {
  content: "\eba5";
}
.icon-Group-199:before {
  content: "\eba6";
}
.icon-revenue:before {
  content: "\eba8";
}
.icon-items:before {
  content: "\eba9";
}
.icon-lunch:before {
  content: "\ebaa";
}
.icon-add:before {
  content: "\ebab";
}
.icon-print:before {
  content: "\ebac";
}
.icon-recurring:before {
  content: "\ebad";
}
.icon-undo:before {
  content: "\ebae";
}
.icon-link:before {
  content: "\ebaf";
}
.icon-recap:before {
  content: "\ebb0";
}
.icon-multi-appointments:before {
  content: "\ebb1";
}
.icon-donotmove:before {
  content: "\ebb2";
}
.icon-Success:before {
  content: "\ebb3";
}
.icon-paid:before {
  content: "\ebb4";
}
.icon-repeat-client:before {
  content: "\ebb5";
}
.icon-request-staff:before {
  content: "\ebb6";
}
.icon-addons:before {
  content: "\ebb7";
}
.icon-comments:before {
  content: "\ebb8";
}
.icon-adddeposit:before {
  content: "\ebb9";
}
.icon-hotel-guest:before {
  content: "\ebba";
}
.icon-member:before {
  content: "\ebbb";
}
.icon-vip:before {
  content: "\ebbc";
}
.icon-male-staff1:before {
  content: "\ebbd";
}
.icon-female-staff1:before {
  content: "\ebbe";
}
.icon-transaction:before {
  content: "\ebbf";
}
.icon-cancel:before {
  content: "\ebc0";
}
.icon-enforce-gender1:before {
  content: "\ebc1";
}
.icon-overbook:before {
  content: "\ebc2";
}
.icon-no-staff:before {
  content: "\ebc3";
}
.icon-move:before {
  content: "\ebc4";
}
.icon-copy:before {
  content: "\ebc5";
}
.icon-changeservice:before {
  content: "\ebc6";
}
.icon-zoom-in:before {
  content: "\ebc7";
}
.icon-legends:before {
  content: "\ebc8";
}
.icon-Revenue:before {
  content: "\ebc9";
}
.icon-Item:before {
  content: "\ebca";
}
.icon-zoom-out:before {
  content: "\ebcb";
}
.icon-user:before {
  content: "\ebcc";
}
.icon-left-arrow:before {
  content: "\ebce";
}
.icon-right-arrow:before {
  content: "\ebcf";
}
.icon-sendmail:before {
  content: "\ebd0";
}
.icon-hair:before {
  content: "\ebd1";
}
.icon-wax:before {
  content: "\ebd2";
}
.icon-face:before {
  content: "\ebd3";
}
.icon-body:before {
  content: "\ebd4";
}
.icon-filter_filled:before {
  content: "\ebd5";
}
.icon-information:before {
  content: "\ebd7";
}
.icon-deposit:before {
  content: "\ebd8";
}
.icon-note:before {
  content: "\ebd9";
}
.icon-close:before {
  content: "\ebda";
}
.icon-done:before {
  content: "\ebdb";
}
.icon-Call:before {
  content: "\ebdc";
}
.icon-checkout:before {
  content: "\ebdd";
}
.icon-checkin:before {
  content: "\ebde";
}
.icon-disabled-user:before {
  content: "\ebdf";
  color: #a73535;
}
.icon-enabled-user:before {
  content: "\ebe0";
  color: #56a948;
}
.icon-password-hide:before {
  content: "\ebe1";
}
.icon-profile:before {
  content: "\ebe2";
}
.icon-swap:before {
  content: "\ebe3";
}
.icon-serviceaddon:before {
  content: "\ebe4";
}
.icon-therapists:before {
  content: "\ebe5";
}
.icon-woman:before {
  content: "\ebe6";
}
.icon-Location:before {
  content: "\ebe7";
}
.icon-repeatclients:before {
  content: "\ebe8";
}
.icon-newclients:before {
  content: "\ebe9";
}
.icon-noshows:before {
  content: "\ebea";
}
.icon-alert:before {
  content: "\ebeb";
}
.icon-checkedout:before {
  content: "\ebec";
}
.icon-checkedin:before {
  content: "\ebed";
}
.icon-scheduled:before {
  content: "\ebee";
}
.icon-commission:before {
  content: "\ebef";
}
.icon-client:before {
  content: "\ebf0";
}
.icon-servicecharge:before {
  content: "\ebf1";
}
.icon-gratuity:before {
  content: "\ebf2";
}
.icon-hash:before {
  content: "\ebf3";
}
.icon-warning-info-icon:before {
  content: "\ebf4";
}
.icon-success-icon:before {
  content: "\ebf5";
}
.icon-warning-icon:before {
  content: "\ebf6";
}
.icon-error-icon:before {
  content: "\ebf7";
}
.icon-info-icon:before {
  content: "\ebf8";
}
.icon-filter:before {
  content: "\ebf9";
}
.icon-password-show:before {
  content: "\ebfa";
}
.icon-location:before {
  content: "\ebfb";
}
.icon-password:before {
  content: "\ebfc";
}
.icon-userid:before {
  content: "\ebfd";
}
.icon-No_records_display .path1:before {
  content: "\ec00";
  color: rgb(197, 141, 11);
  opacity: 0.2040;
}
.icon-No_records_display .path2:before {
  content: "\ecb4";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_display .path3:before {
  content: "\ecb7";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_display .path4:before {
  content: "\ecb8";
  margin-left: -1.4921875em;
  color: rgb(250, 250, 250);
}
.icon-No_records_display .path5:before {
  content: "\ecb9";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_display .path6:before {
  content: "\ecba";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_display .path7:before {
  content: "\ecbb";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
}
.icon-No_records_display .path8:before {
  content: "\ecbc";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
}
.icon-No_records_display .path9:before {
  content: "\ecbd";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_display .path10:before {
  content: "\ecbe";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_display .path11:before {
  content: "\ecc5";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_display .path12:before {
  content: "\ecc6";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
}
.icon-No_records_display .path13:before {
  content: "\ecc7";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
}
.icon-No_records_display .path14:before {
  content: "\ecc8";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-No_records_display .path15:before {
  content: "\ecc9";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-No_records_display .path16:before {
  content: "\ecca";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-No_records_display .path17:before {
  content: "\eccb";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-No_records_display .path18:before {
  content: "\eccc";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
  opacity: 0.5;
}
.icon-No_records_display .path19:before {
  content: "\eccd";
  margin-left: -1.4921875em;
  color: rgb(253, 216, 53);
  opacity: 0.5;
}
.icon-No_records_display .path20:before {
  content: "\ecce";
  margin-left: -1.4921875em;
  color: rgb(253, 216, 53);
  opacity: 0.5;
}
.icon-No_records_display .path21:before {
  content: "\eccf";
  margin-left: -1.4921875em;
  color: rgb(245, 95, 68);
  opacity: 0.5;
}
.icon-No_records_display .path22:before {
  content: "\ecd0";
  margin-left: -1.4921875em;
  color: rgb(245, 95, 68);
  opacity: 0.5;
}
.icon-No_records_display .path23:before {
  content: "\ecd1";
  margin-left: -1.4921875em;
  color: rgb(245, 95, 68);
  opacity: 0.5;
}
.icon-No_records_display .path24:before {
  content: "\ecd2";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
  opacity: 0.5;
}
.icon-No_records_display .path25:before {
  content: "\ecd3";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-No_records_display .path26:before {
  content: "\ecd4";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_display .path27:before {
  content: "\ecd5";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_display .path28:before {
  content: "\ecd6";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.347;
}
.icon-No_records_display .path29:before {
  content: "\ecd7";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.347;
}
.icon-No_records_display .path30:before {
  content: "\ecd8";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.347;
}
.icon-No_records_display .path31:before {
  content: "\ecd9";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.347;
}
.icon-No_records_display .path32:before {
  content: "\ecda";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.347;
}
.icon-No_records_display .path33:before {
  content: "\ecdb";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.347;
}
.icon-No_records_display .path34:before {
  content: "\ecdc";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.347;
}
.icon-No_records_display .path35:before {
  content: "\ecdd";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.347;
}
.icon-No_records_display .path36:before {
  content: "\ecde";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.347;
}
.icon-No_records_found_aftersearch .path1:before {
  content: "\ec01";
  color: rgb(197, 141, 11);
  opacity: 0.2040;
}
.icon-No_records_found_aftersearch .path2:before {
  content: "\ecdf";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path3:before {
  content: "\ece0";
  margin-left: -1.4921875em;
  color: rgb(108, 99, 255);
  opacity: 0.7;
}
.icon-No_records_found_aftersearch .path4:before {
  content: "\ece1";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path5:before {
  content: "\ece2";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path6:before {
  content: "\ece3";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path7:before {
  content: "\ece4";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path8:before {
  content: "\ece5";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path9:before {
  content: "\ece6";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path10:before {
  content: "\ece7";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path11:before {
  content: "\ece8";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path12:before {
  content: "\ece9";
  margin-left: -1.4921875em;
  color: rgb(245, 245, 245);
}
.icon-No_records_found_aftersearch .path13:before {
  content: "\ecea";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path14:before {
  content: "\eceb";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path15:before {
  content: "\ecec";
  margin-left: -1.4921875em;
  color: rgb(108, 99, 255);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch .path16:before {
  content: "\eced";
  margin-left: -1.4921875em;
  color: rgb(108, 99, 255);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch .path17:before {
  content: "\ecee";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path18:before {
  content: "\ecef";
  margin-left: -1.4921875em;
  color: rgb(245, 245, 245);
}
.icon-No_records_found_aftersearch .path19:before {
  content: "\ecf0";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path20:before {
  content: "\ecf1";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path21:before {
  content: "\ecf2";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch .path22:before {
  content: "\ecf3";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch .path23:before {
  content: "\ecf4";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path24:before {
  content: "\ecf5";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path25:before {
  content: "\ecf6";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path26:before {
  content: "\ecf7";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path27:before {
  content: "\ecf8";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path28:before {
  content: "\ecf9";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path29:before {
  content: "\ecfa";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path30:before {
  content: "\ecfb";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
}
.icon-No_records_found_aftersearch .path31:before {
  content: "\ecfc";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path32:before {
  content: "\ecfd";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path33:before {
  content: "\ecfe";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path34:before {
  content: "\ecff";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path35:before {
  content: "\ed00";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.9;
}
.icon-No_records_found_aftersearch .path36:before {
  content: "\ed01";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path37:before {
  content: "\ed02";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path38:before {
  content: "\ed03";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
}
.icon-No_records_found_aftersearch .path39:before {
  content: "\ed04";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path40:before {
  content: "\ed05";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path41:before {
  content: "\ed06";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path42:before {
  content: "\ed07";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path43:before {
  content: "\ed08";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.9;
}
.icon-No_records_found_aftersearch .path44:before {
  content: "\ed09";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path45:before {
  content: "\ed0a";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch .path46:before {
  content: "\ed0b";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
}
.icon-No_records_found_aftersearch .path47:before {
  content: "\ed0c";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path48:before {
  content: "\ed0d";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path49:before {
  content: "\ed0e";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path50:before {
  content: "\ed0f";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch .path51:before {
  content: "\ed10";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.9;
}
.icon-No_records_found_aftersearch .path52:before {
  content: "\ed11";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path53:before {
  content: "\ed12";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch .path54:before {
  content: "\ed13";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
}
.icon-No_records_found_aftersearch .path55:before {
  content: "\ed14";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
}
.icon-No_records_found_aftersearch .path56:before {
  content: "\ed15";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
}
.icon-No_records_found_aftersearch .path57:before {
  content: "\ed16";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
}
.icon-No_records_found_aftersearch .path58:before {
  content: "\ed17";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
}
.icon-No_records_found_aftersearch .path59:before {
  content: "\ed18";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
}
.icon-No_records_found_aftersearch .path60:before {
  content: "\ed19";
  margin-left: -1.4921875em;
  color: rgb(253, 216, 53);
}
.icon-No_records_found_aftersearch .path61:before {
  content: "\ed1a";
  margin-left: -1.4921875em;
  color: rgb(253, 216, 53);
}
.icon-No_records_found_aftersearch .path62:before {
  content: "\ed1b";
  margin-left: -1.4921875em;
  color: rgb(245, 95, 68);
}
.icon-No_records_found_aftersearch .path63:before {
  content: "\ed1c";
  margin-left: -1.4921875em;
  color: rgb(245, 95, 68);
}
.icon-No_records_found_aftersearch .path64:before {
  content: "\ed1d";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
}
.icon-No_records_found_aftersearch .path65:before {
  content: "\ed1e";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch .path66:before {
  content: "\ed1f";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
}
.icon-no-appointment-booked .path1:before {
  content: "\ec02";
  color: rgb(255, 255, 255);
}
.icon-no-appointment-booked .path2:before {
  content: "\ed20";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-no-appointment-booked .path3:before {
  content: "\ed21";
  margin-left: -1.494140625em;
  color: rgb(108, 99, 255);
}
.icon-no-appointment-booked .path4:before {
  content: "\ed22";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path5:before {
  content: "\ed23";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path6:before {
  content: "\ed24";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path7:before {
  content: "\ed25";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path8:before {
  content: "\ed26";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path9:before {
  content: "\ed27";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path10:before {
  content: "\ed28";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path11:before {
  content: "\ed29";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path12:before {
  content: "\ed2a";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path13:before {
  content: "\ed2b";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path14:before {
  content: "\ed2c";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path15:before {
  content: "\ed2d";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path16:before {
  content: "\ed2e";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path17:before {
  content: "\ed2f";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path18:before {
  content: "\ed30";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path19:before {
  content: "\ed31";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path20:before {
  content: "\ed32";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path21:before {
  content: "\ed33";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path22:before {
  content: "\ed34";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path23:before {
  content: "\ed35";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path24:before {
  content: "\ed36";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path25:before {
  content: "\ed37";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path26:before {
  content: "\ed38";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path27:before {
  content: "\ed39";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path28:before {
  content: "\ed3a";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path29:before {
  content: "\ed3b";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path30:before {
  content: "\ed3c";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path31:before {
  content: "\ed3d";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path32:before {
  content: "\ed3e";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path33:before {
  content: "\ed3f";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path34:before {
  content: "\ed40";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path35:before {
  content: "\ed41";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path36:before {
  content: "\ed42";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}
.icon-no-appointment-booked .path37:before {
  content: "\ed43";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}
.icon-no-appointment-booked .path38:before {
  content: "\ed44";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}
.icon-no-appointment-booked .path39:before {
  content: "\ed45";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-no-appointment-booked .path40:before {
  content: "\ed46";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path41:before {
  content: "\ed47";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path42:before {
  content: "\ed48";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path43:before {
  content: "\ed49";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path44:before {
  content: "\ed4a";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path45:before {
  content: "\ed4b";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path46:before {
  content: "\ed4c";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path47:before {
  content: "\ed4d";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-no-appointment-booked .path48:before {
  content: "\ed4e";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-no-appointment-booked .path49:before {
  content: "\ed4f";
  margin-left: -1.494140625em;
  color: rgb(255, 255, 255);
}
.icon-no-appointment-booked .path50:before {
  content: "\ed50";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path51:before {
  content: "\ed51";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path52:before {
  content: "\ed52";
  margin-left: -1.494140625em;
  color: rgb(236, 235, 237);
  opacity: 0.343;
}
.icon-no-appointment-booked .path53:before {
  content: "\ed53";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path54:before {
  content: "\ed54";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path55:before {
  content: "\ed55";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path56:before {
  content: "\ed56";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-no-appointment-booked .path57:before {
  content: "\ed57";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path58:before {
  content: "\ed58";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path59:before {
  content: "\ed59";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path60:before {
  content: "\ed5a";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-no-appointment-booked .path61:before {
  content: "\ed5b";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path62:before {
  content: "\ed5c";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path63:before {
  content: "\ed5d";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-no-appointment-booked .path64:before {
  content: "\ed5e";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-no-appointment-booked .path65:before {
  content: "\ed5f";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-no-appointment-booked .path66:before {
  content: "\ed60";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-left-arrow-dashboard:before {
  content: "\ec03";
}
.icon-right-arrow-dashboard:before {
  content: "\ec04";
}
.icon-sortArrow:before {
  content: "\ec05";
  color: #6b6b6b;
}
.icon-filled_up_arrow:before {
  content: "\ec06";
  color: #6b6b6b;
}
.icon-zoom-out1:before {
  content: "\ec07";
}
.icon-zoom-in1:before {
  content: "\ec08";
}
.icon-Credit_book:before {
  content: "\ecb5";
}
.icon-AR_Post:before {
  content: "\ecb6";
}
.icon-offer-redemption:before {
  content: "\ecbf";
}
.icon-Player-worth_disabled .path1:before {
  content: "\ecc0";
  color: rgb(98, 98, 98);
  opacity: 0.34;
}
.icon-Player-worth_disabled .path2:before {
  content: "\ecc1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.34;
}
.icon-Player-worth_enabled .path1:before {
  content: "\ecc2";
  color: rgb(194, 166, 83);
}
.icon-Player-worth_enabled .path2:before {
  content: "\ecc3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Comp-Redemption:before {
  content: "\ecc4";
}
.icon-Circle:before {
  content: "\ec09";
  color: #fff;
}
.icon-Searchbar:before {
  content: "\ec0a";
}
.icon-notification:before {
  content: "\ec0b";
}
.icon-reset:before {
  content: "\ec0c";
}
.icon-Settings:before {
  content: "\ec0d";
}
.icon-Drag:before {
  content: "\ec0e";
}
.icon-member1:before {
  content: "\ec0f";
}
.icon-add_retail_item_24px:before {
  content: "\eef3";
}
.icon-pro_forma_invoice_24px:before {
  content: "\eef9";
}