//====================================================
//  Typography
//====================================================

@import '../helpers/_variables';
@import '_color';

// @font-face {
//   font-family: 'Office Code Pro';
//   src: url('../../../fonts/Lato/fonts/Lato-Light.woff');
//   src: url('../../../fonts/Lato/fonts/Lato-Light.eot');
//   src: url('../../../fonts/Lato/fonts/Lato-Light.ttf');
// }




/* Webfont: Lato-Regular */
@font-face {
  font-family: 'LatoWeb';
  src: url('^assets/fonts/Lato/fonts/Lato-Regular.eot');
  /* IE9 Compat Modes */
  src: url('^assets/fonts/Lato/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('^assets/fonts/Lato/fonts/Lato-Regular.woff2') format('woff2'),
    /* Modern Browsers */
    url('^assets/fonts/Lato/fonts/Lato-Regular.woff') format('woff'),
    /* Modern Browsers */
    url('^assets/fonts/Lato/fonts/Lato-Regular.ttf') format('truetype'),
    url('^assets/fonts/Lato/fonts/Lato-Regular.svg') format('svg');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'LatoWebMedium';
  src: url('^assets/fonts/Lato/fonts/Lato-Medium.eot');
  /* IE9 Compat Modes */
  src: url('^assets/fonts/Lato/fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('^assets/fonts/Lato/fonts/Lato-Medium.woff2') format('woff2'),
    /* Modern Browsers */
    url('^assets/fonts/Lato/fonts/Lato-Medium.woff') format('woff'),
    /* Modern Browsers */
    url('^assets/fonts/Lato/fonts/Lato-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'LatoWebBold';
  src: url('^assets/fonts/Lato/fonts/Lato-Bold.eot');
  /* IE9 Compat Modes */
  src: url('^assets/fonts/Lato/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('^assets/fonts/Lato/fonts/Lato-Bold.woff2') format('woff2'),
    /* Modern Browsers */
    url('^assets/fonts/Lato/fonts/Lato-Bold.woff') format('woff'),
    /* Modern Browsers */
    url('^assets/fonts/Lato/fonts/Lato-Bold.svg') format("svg"),
    /* Legacy iOS */
    url('^assets/fonts/Lato/fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

// New Font Updates

@font-face {
  font-family: $ag_sourcesans-bold;
  src: url('^assets/fonts/SourceSansProFull/SourceSansPro-Bold.eot');
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-Bold.woff2') format('woff2'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-Bold.woff') format('woff'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: $ag_sourcesans-regular;
  src: url('^assets/fonts/SourceSansProFull/SourceSansPro-Regular.eot');
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-Regular.woff2') format('woff2'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-Regular.woff') format('woff'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $ag_sourcesans-semibold;
  src: url('^assets/fonts/SourceSansProFull/SourceSansPro-SemiBold.eot');
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-SemiBold.woff2') format('woff2'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-SemiBold.woff') format('woff'),
      url('^assets/fonts/SourceSansProFull/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $ag_poppins-medium;
  src: url('^assets/fonts/PoppinsFull/Poppins-Medium.eot');
  src: local('Poppins Medium'), local('Poppins-Medium'),
      url('^assets/fonts/PoppinsFull/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
      url('^assets/fonts/PoppinsFull/Poppins-Medium.woff2') format('woff2'),
      url('^assets/fonts/PoppinsFull/Poppins-Medium.woff') format('woff'),
      url('^assets/fonts/PoppinsFull/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $ag_poppins-regular;
  src: url('^assets/fonts/PoppinsFull/Poppins-Regular.eot');
  src: local('Poppins Regular'), local('Poppins-Regular'),
      url('^assets/fonts/PoppinsFull/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('^assets/fonts/PoppinsFull/Poppins-Regular.woff2') format('woff2'),
      url('^assets/fonts/PoppinsFull/Poppins-Regular.woff') format('woff'),
      url('^assets/fonts/PoppinsFull/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $ag_poppins-semibold;
  src: url('^assets/fonts/PoppinsFull/Poppins-SemiBold.eot');
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
      url('^assets/fonts/PoppinsFull/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('^assets/fonts/PoppinsFull/Poppins-SemiBold.woff2') format('woff2'),
      url('^assets/fonts/PoppinsFull/Poppins-SemiBold.woff') format('woff'),
      url('^assets/fonts/PoppinsFull/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: $ag_poppins-bold;
  src: url('^assets/fonts/PoppinsFull/Poppins-ExtraBoldItalic.eot');
  src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'),
      url('^assets/fonts/PoppinsFull/Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('^assets/fonts/PoppinsFull/Poppins-ExtraBoldItalic.woff2') format('woff2'),
      url('^assets/fonts/PoppinsFull/Poppins-ExtraBoldItalic.woff') format('woff'),
      url('^assets/fonts/PoppinsFull/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}


$base-font: $ag_sourcesans-regular !default;

$base-font-size: 1rem !default;
$base-line-height: 1 !default;

$h1: $base-font-size * 2.25 !default;
$h2: $base-font-size * 1.75 !default;
$h3: $base-font-size * 1.5 !default;
$h4: $base-font-size * 1.25 !default;
$h5: $base-font-size * 1.125 !default;
$h6: $base-font-size !default;

// ::selection {
//   background: $SelectionBgColor;
//   color: $SelectionColor;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-bottom: 1rem;
}


h1,
.h1 {
  font-size: $h1;
  line-height: 1.2;
}

h2,
.h2 {
  font-size: $h2;
  line-height: 1.3;
}

h3,
.h3 {
  font-size: $h3;
  line-height: 1.4;
}

h4,
.h4 {
  font-size: $h4;
  line-height: 1.5;
}

h5,
.h5 {
  font-size: $h5;
}

h6,
.h6 {
  font-size: $h6;
}

body {
  font-size: $base-font-size;
  // background-color: $BodyBgColour;
  font-family: $base-font;
  line-height: $base-line-height;
}

a,
.link {
  //color: $label;
  text-decoration: none;
  transition: color 200ms;
  cursor: pointer;
}

// a:hover {
//   color: $label;
// }

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a {
  color: inherit;
  text-decoration: underline;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

//====================================================
//  Lists
//====================================================

li ul,
li ol {
  margin: 0 1rem;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

ol ol {
  list-style: lower-alpha;
}

ol ol ol {
  list-style: lower-roman;
}

ol ol ol ol {
  list-style: lower-alpha;
}

//====================================================
//  Code
//====================================================







.bg-silver code,
.bg-grey code {
  background-color: $black;
  color: $PureWhite;
}

// hr {
//   border: 0;
//   border-bottom: 1px solid fade-out($trueblack, 0.9);
// }

//====================================================
//  Text Length & Alignment
//====================================================

.optimal-length {
  max-width: 70ch;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

//====================================================
//  Styles & Transforms
//====================================================

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.tracked {
  letter-spacing: 4px;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.list-style-none {
  list-style-type: none;
}

.list-inline li {
  display: inline;
}

.thin {
  font-weight: 200;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

//====================================================
//  Styles & Transforms
//====================================================

// @include large {
//   .two-columns   { column-count: 2; }
//   .three-columns { column-count: 3; }
//   .four-columns  { column-count: 4; }
//   .five-columns  { column-count: 5; }
// }

//====================================================
//  Letter Spacing
//====================================================

.ls0 {
  letter-spacing: 0px !important;
}

.ls1 {
  letter-spacing: 1px;
}

.ls2 {
  letter-spacing: 2px;
}

.ls3 {
  letter-spacing: 3px;
}

.ls4 {
  letter-spacing: 4px;
}

.ls5 {
  letter-spacing: 5px;
}

//====================================================
//  Line-Height
//====================================================

.lh0 {
  line-height: 0;
}

.lh1 {
  line-height: 1;
}

.lh2 {
  line-height: 1.2;
}

.lh3 {
  line-height: 1.3;
}

.lh4 {
  line-height: 1.4;
}

.lh5 {
  line-height: 1.5;
}

.lh6 {
  line-height: 1.6;
}

.lh7 {
  line-height: 1.7;
}

.lh8 {
  line-height: 1.8;
}

.lh9 {
  line-height: 1.9;
}

//====================================================
//  Font Sizing
//====================================================

.fs-base {
  font-size: $base-font-size;
}

.fs-xsmall {
  font-size: 50%;
}

.fs-small {
  font-size: 75%;
}

.fs-large {
  font-size: 125%;
}

.fs-xlarge {
  font-size: 150%;
}

.fs-xxlarge {
  font-size: 175%;
}

.fs-double {
  font-size: 200%;
}


