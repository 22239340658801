@import '../helpers/variables';

@font-face {
  font-family: $roboto-regular;
  src: url('../../../assets/fonts/Roboto/Roboto-Regular/Roboto-Regular.eot');
  src: url('../../../assets/fonts/Roboto/Roboto-Regular/Roboto-Regular.woff') format('woff'),
  url('../../../assets/fonts/Roboto/Roboto-Regular/Roboto-Regular.ttf') format('truetype'),
  url('../../../assets/fonts/Roboto/Roboto-Regular/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $roboto-bold;
  src: url('../../../assets/fonts/Roboto/Roboto-Bold/Roboto-Bold.eot');
  src: url('../../../assets/fonts/Roboto/Roboto-Bold/Roboto-Bold.woff') format('woff'),
  url('../../../assets/fonts/Roboto/Roboto-Bold/Roboto-Bold.ttf') format('truetype'),
  url('../../../assets/fonts/Roboto/Roboto-Bold/Roboto-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: $roboto-medium;
  src: url('../../../assets/fonts/Roboto/Roboto-Medium/Roboto-Medium.eot');
  src: url('../../../assets/fonts/Roboto/Roboto-Medium/Roboto-Medium.woff') format('woff'),
  url('../../../assets/fonts/Roboto/Roboto-Medium/Roboto-Medium.ttf') format('truetype'),
  url('../../../assets/fonts/Roboto/Roboto-Medium/Roboto-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $roboto-light;
  src: url('../../../assets/fonts/Roboto/Roboto-Light/Roboto-Light.eot');
  src: url('../../../assets/fonts/Roboto/Roboto-Light/Roboto-Light.woff') format('woff'),
  url('../../../assets/fonts/Roboto/Roboto-Light/Roboto-Light.ttf') format('truetype'),
  url('../../../assets/fonts/Roboto/Roboto-Light/Roboto-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
