@import '../abstracts/variables';

body.new-mat-view {
    .mat-form-field.mat-form-field-can-float.mat-form-field-should-float,.mat-form-field.never-label {
        &.mat-form-field {
            margin-top: 25px;
        }
        // Label Styling
        .mat-form-field-label,
        .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
            // transform: translateY(-1.28125em) perspective(100px) translateZ(0.001px);
            font-size: 16px;
        }
        .mat-form-field-label-wrapper {
            top: -1.5em;
        }
        &.mat-form-field-appearance-legacy {
            .mat-form-field-infix {
                border: 1px solid grey;
                border-radius: 4px;
                padding: 10px 8px;
                height: 40px;
                font-size: 16px;
            }
        }
        // &.mat-form-field-appearance-legacy {
        //     .txt-area-height
        //     .mat-form-field-infix {
        //         height: 60px;
        //     }
        // }
        &.mat-form-field-disabled {
            .mat-form-field-infix {
                opacity: 0.6;
            }
        }
        .mat-form-field-underline {
            display: none;
        }
        &.mat-form-field-invalid {
            .mat-form-field-infix {
                border: 1px solid red;
            }
        }
    }
    .mat-form-field.never-label {
        .mat-form-field-label-wrapper {
            top: 11px;
            left: 10px;
        }
        .mat-form-field-suffix {
            position: absolute;
            top: 7px;
            right: 4px;
        }
    }
    //Text Area
    .txt-area-height {
        border: 1px solid grey;
        border-radius: 4px;
        .mat-form-field-wrapper{
            padding-bottom: 6px !important;
            .mat-form-field-flex{
                .mat-form-field-infix {
                    border: none !important;                
                }
            }
        }
    }

    //Datepicker

    .mat-form-field.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-appearance-legacy.date-picker-width {
        .mat-form-field-infix {
            padding: 9px 25px 0.6em 6px;
        }
    }
    .mat-form-field.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-appearance-legacy.date-picker-width {
        .mat-form-field-suffix{
        position: absolute;
        top: 12px;
        right: 8px;
        }
    }
    
    app-ag-date-picker {
        .mat-form-field.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-appearance-legacy {
            .mat-form-field-infix {
                padding: 0.6em 25px 0.6em 4px;
            }
        }
        .mat-form-field-suffix {
            position: absolute !important;
            top: 9px !important;
            right: 5px !important;
            .icon-calendar{
                font-size: 18px !important;
            }
        }
    }

    //search box
    app-simple-search{
        .searchbox {
            .icon-search {
                color: rgba(0, 0, 0, 0.8) !important;
                bottom: 6px !important;
              }
              .icon-Cancel {
                color: rgba(0, 0, 0, 0.8) !important;
                bottom: 7px !important;
              }
        }
    }
    //Time Picker Alignemnt
    .time-picker-dial {
        top: 6px;
    }
    //PlusMinus Alignemnt
    .ag-address {
        .icon-minus,
        .icon-plus {
            margin-top: 25px !important;
        }
    }
    .ph-fixed-width--wrapper {
        .icon-Minus,
        .icon-Plus,
        .icon-minus,
        .icon-plus {
            margin-top: 25px !important;
        }
    }
    .mail-wrapper {
        .fixed-width--wrapper {
            .icon-minus,
            .icon-plus,
            .icon-Minus,
            .icon-Plus {
                margin-top: 25px !important;
                //font-size: 2rem;
            }
        }
        .mat-form-field.ag_form-control--md {
            padding-right: 0;
        }
    }

    //Layout Changes
    @for $i from 1 through 4 {
        .ag_form-control--#{$i} {
            width: #{$i * 25} + "%";
            mat-form-field {
                width: 100%;
            }
        }
    }

    // cdk table datepicker change
    .ag_table .datepicker{
        .mat-form-field.mat-form-field-can-float.mat-form-field-should-float {
          &.mat-form-field {
              margin-top: 8px !important;
          }
          &.mat-form-field-appearance-legacy {
            .mat-form-field-infix {
                height: 32px !important;
                padding: 0px !important;
            }
            .mat-form-field-suffix{
              .mat-icon-button{
                padding-left: 15px !important
              }
            }
        }
      }
        .table-input{
          border: none !important;   
        }
      }

    //    --label color change--

.ag_form--label {
    color: $black !important;
  }
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $black!important;
    font-size: 18px!important;
  }
  .spa-searchbox .mat-form-field-label
  {
    color: $ag_black-lighter !important;
    font-size: 14px!important;
  }
  .radiosearchsection .radiosearchbox .mat-form-field-label
  {
    color: $ag_black-lighter !important;
  }
  .form-label-normal{
    color: $black !important;
    font-size: 14px!important;
  }
  label{
    &.LW12{
        color: $black !important;
        font-size: 14px!important;
    }
  }
  .searchbox .mat-form-field-label {
    color: rgba(0, 0, 0, 0.3) !important;
    font-size: 15px!important;
    top: 1.18125em;
  }
  .mat-form-field.mat-focused .mat-form-field-label{
    color: #c58d0b!important;
  }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label{
    color: #f44336!important;
  }
  .pop-contents{
    line-height: 20px;
  }
  .text-label-new{
    transform: translateY(-0.1em) perspective(100px) translateZ(.001px);
    font-size: 14px;
    color: #000000de;
 }
 .mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
    border: solid 1px $theme-base-color;
  }

//    --label color change--
    // .phone-wrapper {
    //     width: 100%;
    //     .ph-fixed-width--wrapper{
    //         width: 100%;
    //         .mat-form-field{
    //             width: 25% !important;
    //             padding-right: 3rem !important;
    //         }
    //     }
    //     .ag-phone-switch{
    //         padding-left: 60px !important;
    //     }
    // }
    // .mail-wrapper {
    //     width: 100%;
    //     .fixed-width--wrapper{
    //         width: 100%;
    //         .mat-form-field{
    //             width: 25% !important;
    //             padding-right: 3rem !important;
    //         }
    //     }
    //     .ag-mail-switch{
    //         padding-left: 60px !important;
    //     }
    // }
}

@media only screen and (min-width: 1024px) {
    body.new-mat-view {
        //Layout Changes
        @for $i from 1 through 4 {
            .ag_form-control--#{$i} {
                width: #{$i * 25} + "%";
                mat-form-field {
                    width: 100%;
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    body.new-mat-view {
        //Layout Changes
        @for $i from 1 through 4 {
            .ag_form-control--#{$i} {
                width: 50%;
                mat-form-field {
                    width: 100%;
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    body.new-mat-view {
        //Layout Changes
        @for $i from 1 through 4 {
            .ag_form-control--#{$i} {
                width: 100%;
                mat-form-field {
                    width: 100%;
                    padding-right: 0 !important;
                }
            }
        }
    }
}
body.new-mat-view {
    .menu-wrapper-container {
      .mat-form-field.mat-form-field-can-float.mat-form-field-should-float.menu-field {
        &.mat-form-field {
            margin-top: 15px;
        }
        // Label Styling
        .mat-form-field-label,
        .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
            // transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
            font-size: unset;
        }
        .mat-form-field-label-wrapper {
            top: -0.84375em;
        }
        &.mat-form-field-appearance-legacy {
            .mat-form-field-infix {
                border: unset;
                border-radius: unset;
                padding: 0.4375em 0;
                height: unset;
            }
        }
        &.mat-form-field-disabled {
            .mat-form-field-infix {
                opacity: 0.6;
            }
        }
        .mat-form-field-underline {
            display: none;
        }
        &.mat-form-field-invalid {
            .mat-form-field-infix {
                border: unset;
            }
        }
      }
    }
  }
// app-menu{
//     .subnav-wrapper {
//         .mat-form-field.mat-form-field-can-float.mat-form-field-should-float {
//         &.mat-form-field {
//             margin-top: 15px;
//         }
//     }
//     }
// }
