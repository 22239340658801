
@import './ag-variable';
/*
  ****
  @Define the Media Queries for Tablet, Desktop
  @Params Pass the Paramenter
  ****
*/

$ag_media-queries: (
  "small": (
    breakpoint: "(max-width: #{$small-max-width})",
  ),
  "medium": (
    breakpoint: "(min-width: #{$medium-min-width}) and (max-width: #{$medium-max-width})",
  ),
  "large": (
    breakpoint: "(min-width: #{$large-min-width}) and (max-width: #{$large-max-width})",
  ),
);

@mixin ag_media-query($size) {
  @each $item, $value in $ag_media-queries {
    @if $item == $size {
      @if map-get($value, breakpoint) != null {
        @media only screen and #{map-get($value, breakpoint)} {
          @content;
        }
      }
    }
  }
}

@mixin sticky-header($top, $bgColor, $zindex) {
  position: sticky;
  top: $top;
  background-color: $bgColor;
  z-index: $zindex;
}

@mixin set-browser-prefix($property, $val) {
  -webkit-#{$property}: $val;
  -moz-#{$property}: $val;
  -ms-#{$property}: $val;
  -o-#{$property}: $val;
  #{$property}: $val;
}
// Call the above method like this ---> .box { @include set-browser-prefix('transform', rotate(30deg)); }

@mixin media-query($min-width: null, $max-width: null) {
  @if ($min-width and $max-width) {
    @media screen and (max-width: $max-width) and (min-width: $min-width) {
      @content;
    }
  } @else if($max-width) {
    @media screen and (max-width: $max-width) {
      @content;
    }
  } @else if($min-width) {
    @media screen and (min-width: $min-width) {
      @content;
    }
  }
}

// Width Properties
@for $i from 0 through 10 {
  .ag_w--#{$i * 10} {
    width: #{$i * 10} + "%";
  }
}

// Padding Properties
@for $i from 0 through 8 {
  .ag_p--#{$i} {
    padding: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pl--#{$i} {
    padding-left: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pr--#{$i} {
    padding-right: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pt--#{$i} {
    padding-top: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pb--#{$i} {
    padding-bottom: #{$i * 0.25}rem !important;
  }
}

@for $i from 0 through 8 {
  .ag_m--#{$i} {
    margin: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_ml--#{$i} {
    margin-left: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_mr--#{$i} {
    margin-right: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_mt--#{$i} {
    margin-top: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_mb--#{$i} {
    margin-bottom: #{$i * 0.25}rem !important;
  }
}

// form control Width Properties
@for $i from 1 through 4 {
  .ag_form-control--#{$i} {
    width: #{$i * 240} + "px";
    padding-right: $default-control-padding;
    mat-form-field{
      width: 100%;
    }
  }
}